import React, { Fragment, useContext, useRef, useState } from 'react';
import { useOnClickOutside } from '../../../hooks/use-onclick-outside';
import { AuthContext } from '../../../providers/AuthProvider';
import NavAvatar from '../NavAvatar';

import './NavUser.scss';
import { INavUserProps } from './types';

const NavUser = (props: INavUserProps) => {
  const { onClick } = props;
  const [showMenu, setShowMenu] = useState(false);
  const authContext = useContext(AuthContext);
  const ref = useRef();

  useOnClickOutside(ref, () => setShowMenu(false));

  return (
    <Fragment>
      <div
        className="vx-nav-user"
        onClick={() => {
          setShowMenu(!showMenu);
          onClick();
        }}
      >
        {authContext.isLoggedIn() && (
          <NavAvatar
            imgSrc={
              authContext.user.profileImage
                ? authContext.user.profileImage.url
                : ''
            }
          />
        )}
      </div>
    </Fragment>
  );
};

export default NavUser;
