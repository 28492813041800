import { MAPBOX_TOKEN } from '../../../constants';
import { IStory } from './types';
import kyrkja from '../img/kyrkja.jpg';
import leirvassbuPhoto from '../img/leirvassbu.jpeg';
import mh from '../img/mh.jpg';
import semelholstiden from '../img/semelholstiden.jpg';
import stetind from '../img/stetinden.jpg';
import storeBjorn from '../img/storebjorn.jpeg';
import surtningstiden from '../img/surtningstiden.jpg';

export const Leirvassbu: IStory = {
  accessToken: MAPBOX_TOKEN,
  alignment: 'left',
  byline: 'By Vertrax',
  chapters: [
    {
      description: `
Leirvassbu Mountain Lodge is situated in the heart of Jotunheimen’s hiking network, that has been known for generations as \"The Pearl of Jotunheimen\". The Mountain Lodge is built with ramblers and climbers in mind. The Mountain Lodge (1400 metres above sea level) is right next to the lake “Leirvatnet”, just below the characteristic mountain “Kyrkja” (The Church).

The Norwegian Trekking Association (DNT) have listed and marked routes going from Leirvassbu to all the mountain lodges in the area. In the winter season there are well marked and tracks to all the lodges.`,
      id: 'other-identifier',
      image: leirvassbuPhoto,
      location: {
        bearing: -40.0,
        center: [8.24806, 61.54856],
        pitch: 45.0,
        zoom: 16.0,
      },
      onChapterEnter: [],
      onChapterExit: [],
      title: 'Leirvassbu',
    },
    {
      description:
        'From Leirvassbu you can climb at least 40 peaks over 2000 metres in the immediate area.',
      id: 'slug-style-id',
      image: leirvassbuPhoto,
      location: {
        bearing: -40.0,
        center: [8.24806, 61.54856],
        pitch: 45.0,
        zoom: 12.3,
      },
      onChapterEnter: [
        {
          layer: 'kyrkja',
          opacity: 1,
        },
        {
          layer: 'semelholstiden',
          opacity: 1,
        },
        {
          layer: 'midtreHogvalgtiden',
          opacity: 1,
        },
        {
          layer: 'stetind',
          opacity: 1,
        },
        {
          layer: 'surtningstiden',
          opacity: 1,
        },
        {
          layer: 'storeBjorn',
          opacity: 1,
        },
      ],
      onChapterExit: [
        {
          layer: 'kyrkja',
          opacity: 0,
        },
        {
          layer: 'semelholstiden',
          opacity: 0,
        },
        {
          layer: 'midtreHogvalgtiden',
          opacity: 0,
        },
        {
          layer: 'stetind',
          opacity: 0,
        },
        {
          layer: 'surtningstiden',
          opacity: 0,
        },
        {
          layer: 'storeBjorn',
          opacity: 0,
        },
      ],
      title: 'Ski touring',
    },
    {
      description: `
This is a great tour for your first or last day at Leirvassbu. Kyrkja, also referred to as The Church, is an iconic peak, visible from Leirvassbu. You start by aiming for the saddle to the south of the summit. 

Once you reach the saddle, it is usually best to switch your skis for crampons and bring an ice axe to make your way to the summit. 

After summiting, make your way back to your skis, there are two options for skiing, you can ski the way you came up, or you can ski off the backside over the glacier when conditions are stable, and loop around back to Leirvassbu.`,
      id: 'kyrkja',
      image: kyrkja,
      location: {
        bearing: -43.16,
        center: [8.28001, 61.54377],
        pitch: 60.0,
        zoom: 14.44,
      },
      onChapterEnter: [
        {
          layer: 'kyrkja',
          opacity: 1,
        },
      ],
      onChapterExit: [
        {
          layer: 'kyrkja',
          opacity: 0,
        },
      ],
      title: 'Kyrkja',
    },
    {
      description: `
  Summit: 2147 moh 
  Vertical: 750 meters
  Distance to summit: 7 km
  Duration: 5 hours
  Aspect: West - North West
  Steepest point: under 30 degrees
  Difficulty: 1 - Easy
  Starting point: Leirvassbu
      `,
      id: 'semelholstiden',
      image: semelholstiden,
      location: {
        bearing: -79.16,
        center: [8.32668, 61.53899],
        pitch: 59.0,
        zoom: 13.59,
      },
      onChapterEnter: [
        {
          layer: 'semelholstiden',
          opacity: 1,
        },
      ],
      onChapterExit: [
        {
          layer: 'semelholstiden',
          opacity: 0,
        },
      ],
      title: 'Semelholstiden',
    },
    {
      description: `
Summit: 2066 moh
Vertical: 670 meters
Distance to summit: 6 km
Duration: 4 hours
Aspect: North
Steepest point: under 30 degrees
Difficulty: 1 - Easy
Starting point: Leirvassbu
      `,
      id: 'midte-hogvagltinden',
      image: mh,
      location: {
        bearing: -51.96,
        center: [8.25479, 61.51292],
        pitch: 57.5,
        zoom: 14.06,
      },
      onChapterEnter: [
        {
          layer: 'midtreHogvalgtiden',
          opacity: 1,
        },
      ],
      onChapterExit: [
        {
          layer: 'midtreHogvalgtiden',
          opacity: 0,
        },
      ],
      title: 'Midtre Høgvagldtinden',
    },
    {
      description: `
Summit: 2020 moh
Vertical: 550 meters
Distance to summit: 5.5 km
Duration: 4-5 hours
Starting Point: Lerivassbu
      `,
      id: 'stetind',
      image: stetind,
      location: {
        bearing: 87.48,
        center: [8.20668, 61.54698],
        pitch: 56.5,
        zoom: 14.34,
      },
      onChapterEnter: [
        {
          layer: 'stetind',
          opacity: 1,
        },
      ],
      onChapterExit: [
        {
          layer: 'stetind',
          opacity: 0,
        },
      ],
      title: 'Stetind',
    },
    {
      description: `
Summit: 1997 moh
Vertical: 650 meters
Distance to summit: 5.8 km
Duration: 4 hours
Aspect: East and North
Steepest point: under 30 degrees
Difficulty: 1 - Easy
Starting point: Leirvassbu
      `,
      id: 'surtningstiden',
      image: surtningstiden,
      location: {
        bearing: 87.48,
        center: [8.15692, 61.52913],
        pitch: 56.5,
        zoom: 13.7,
      },
      onChapterEnter: [
        {
          layer: 'surtningstiden',
          opacity: 1,
        },
      ],
      onChapterExit: [
        {
          layer: 'surtningstiden',
          opacity: 0,
        },
      ],
      title: 'Surtningstiden',
    },
    {
      description: `
Summit: 2222 moh
Vertical: 922 meters
Distance: 15 km
Duration: 5-6 hours
Starting Point: Lerivassbu
      `,
      id: 'store-bjorn',
      image: storeBjorn,
      location: {
        bearing: 87.48,
        center: [8.16423, 61.55406],
        pitch: 56.5,
        zoom: 13.7,
      },
      onChapterEnter: [
        {
          layer: 'storeBjorn',
          opacity: 1,
        },
      ],
      onChapterExit: [
        {
          layer: 'storeBjorn',
          opacity: 0,
        },
      ],
      title: 'Store Bjørn',
    },
  ],
  footer: 'Source: source citations, etc.',
  showMarkers: false,
  style: 'mapbox://styles/vertrax/ck433h4vb1hfq1do08ybntxza',
  subtitle: 'Ski the tallest peaks in norway',
  theme: 'dark',
  title: 'Leirvassbu',
};
