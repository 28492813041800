import tilebelt from '@mapbox/tilebelt';
import getPixels from 'get-pixels';
import { MAPBOX_TOKEN } from '../constants';

export function getElevations(
  p: [number, number],
  cb: (err: any, value?: number) => void,
) {
  const tf = tilebelt.pointToTileFraction(p[0], p[1], 20);
  const tile = tf.map(Math.floor);
  const domain = 'https://api.mapbox.com/v4/';
  const source = `mapbox.terrain-rgb/${tile[2]}/${tile[0]}/${tile[1]}.pngraw`;
  const url = `${domain}${source}?access_token=${MAPBOX_TOKEN}`;

  getPixels(url, (err, pixels) => {
    if (err) {
      return cb(err);
    }
    const xp = tf[0] - tile[0];
    const yp = tf[1] - tile[1];
    const x = Math.floor(xp * pixels.shape[0]);
    const y = Math.floor(yp * pixels.shape[1]);

    const R = pixels.get(x, y, 0);
    const G = pixels.get(x, y, 1);
    const B = pixels.get(x, y, 2);

    const height = -10000 + (R * 256 * 256 + G * 256 + B) * 0.1;

    return cb(null, height);
  });
}
