import * as React from 'react';

import classNames from 'classnames';
import { IRegionLabelsProps } from './types';

import { useDevice } from '../../../../hooks/use-device';
import BackgroundLabel from '../../../elements/BackgroundLabel';

import './RegionLabels.scss';

const RegionLabels = ({
  topLabel,
  bottomLabel,
  className,
  onBottomLabelClick,
  onTopLabelClick,
  onWeatherLabelClick,
  weatherLabel,
}: IRegionLabelsProps) => {
  const device = useDevice();

  return (
    <div className={classNames('vx-region-label', className)}>
      <BackgroundLabel
        title={topLabel}
        color="black"
        size={!device.isLaptop ? 'sm' : 'lg'}
        onClick={onTopLabelClick}
      />
      <BackgroundLabel
        title={bottomLabel}
        color="purple"
        size={!device.isLaptop ? 'md' : 'xl'}
        onClick={onBottomLabelClick}
      />
      {weatherLabel && (
        <BackgroundLabel
          title={weatherLabel}
          color="purple"
          size={!device.isLaptop ? 'sm' : 'md'}
          onClick={onWeatherLabelClick}
        />
      )}
    </div>
  );
};

export default RegionLabels;
