import React, { useEffect, useState } from 'react';

import * as turf from '@turf/turf';
import L from 'leaflet';
import 'leaflet-control-geocoder';
import 'leaflet-draw';
import * as qs from 'query-string';
import '../../../../assets/vendors/leaflet/leaflet.scss';

import { MAPBOX_TOKEN } from '../../../../constants';

import { toLineString } from '../../../../utils/geo-json/converter';
import { getElevations } from '../../../../utils/get-elevation';

import RouteBuilderBottomMenu from '../../Menus/RouteBuilderBottomMenu';
import SaveRouteModal from '../../Modals/SaveRouteModal';
import RegionLabels from '../../RegionLabels/RegionLabels';

import {
  Activity,
  ActivityType,
  CreateRouteInput,
  Route,
  useExploreGeocodeQuery,
  UpdateRouteInput,
} from '../../../../generated/models';
import { IRouteBuilderProps } from './types';

import { RegionInfoProvider } from '../../../../providers/RegionInfoProvider';
import './RouteBuilder.scss';

const RouteBuilder = (props: IRouteBuilderProps) => {
  const { onSave, route, onError } = props;

  let map: L.Map;
  const editableLayers = new L.FeatureGroup();
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [topLabel, setTopLabel] = useState('');
  const [bottomLabel, setBottomLabel] = useState('');
  const [geoJson, setGeoJson] = useState<any>();
  const [routeDistance, setRouteDistance] = useState<number>(0.0);
  const [routeElevation, setRouteElevation] = useState<number>(0.0);
  const [activityType, setActivityType] = useState<ActivityType>();
  const [mapInstance, setMapInstance] = useState<any>();
  const [activity, setActivity] = useState<Partial<Route>>();
  const [isFileUpload, setIsFileUpload] = useState(false);
  const [elevations, setElevations] = useState([]);

  const geocode = useExploreGeocodeQuery({
    variables: { lat: 8, lon: 63 },
  });

  const { refetch } = geocode;

  useEffect(() => {
    const params = qs.parse(window.location.search);

    map = L.map('map', { zoomControl: false }).setView(
      [Number(params.lat ?? 63), Number(params.lng ?? 8)],
      13,
    );

    L.tileLayer(
      `https://api.mapbox.com/styles/v1/vertrax/cjoe2qtes4ny12slb16w57nyj/tiles/256/{z}/{x}/{y}@2x?access_token=${MAPBOX_TOKEN}`,
      {
        attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      },
    ).addTo(map);

    map.addLayer(editableLayers);

    // @ts-ignore
    const drawControl = new L.Control.Draw({
      draw: {
        circle: false,
        circlemarker: false,
        marker: false,
        polygon: false,
        rectangle: false,
      },
      edit: {
        featureGroup: editableLayers,
      },
      position: 'topright',
    });
    map.addControl(drawControl);

    // @ts-ignore
    L.Control.geocoder().addTo(map);

    const zoomControl = new L.Control.Zoom({
      position: 'topright',
    });

    map.addControl(zoomControl);

    if (route) {
      const routeGeoJson = JSON.parse(route.geoJson);
      const point = L.latLng(route.marker.lat, route.marker.lng);
      setActivityType(route.activityType);
      setGeoJson(routeGeoJson);
      setActivity(route);

      const geoJsonLayer = L.geoJSON(routeGeoJson);

      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      updateCalculations(routeGeoJson);

      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      addNonGroupLayers(geoJsonLayer, editableLayers);
      map.setView(point, 13);
    }

    // @ts-ignore
    map.on(L.Draw.Event.CREATED, (e: any) => {
      const layer = e.layer;

      editableLayers.addLayer(layer);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      getGeoJsonFromlayer();
    });

    map.on('draw:drawstart', () => {
      // TODO: Clear map
      setRouteDistance(0);
      setRouteElevation(0);
    });

    map.on('draw:drawvertex', () => {
      // TODO: Update Distance, Elevation Gain, Moving Time
    });

    map.on('draw:edited', (e: any) => {
      const layers = e.layers;
      layers.eachLayer(() => {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        getGeoJsonFromlayer();
      });
    });

    map.on('moveend', () => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      fetchLabelLocation();
    });

    const getGeoJsonFromlayer = () => {
      const featureCollection = editableLayers.toGeoJSON();
      // @ts-ignore
      const line = featureCollection.features[0];

      setGeoJson(line);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      updateCalculations(line);
    };

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    fetchLabelLocation();
    setMapInstance(map);
  }, []);

  // Would benefit from https://github.com/Leaflet/Leaflet/issues/4461
  function addNonGroupLayers(sourceLayer, targetGroup) {
    if (sourceLayer instanceof L.LayerGroup) {
      sourceLayer.eachLayer(layer => {
        addNonGroupLayers(layer, targetGroup);
      });
    } else {
      targetGroup.addLayer(sourceLayer);
    }
  }

  const onFileUpload = file => {
    mapInstance.removeLayer(editableLayers);

    editableLayers.clearLayers();
    setRouteDistance(0);
    setIsFileUpload(true);

    const newActivity: Pick<Activity, 'polyline' | 'title' | 'activityType'> = {
      activityType,
      polyline: {
        coordinates: file.points.map(coord => [
          coord.lng,
          coord.lat,
          coord.elev,
        ]),
        polyline: '',
        summaryPolyline: '',
      },
      title: file.name,
    };

    const ele = file.points.map(coord => coord.elev);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    calculateTotalAscent(ele);

    const activityGeoJSON = toLineString(newActivity);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    calculateDistance(activityGeoJSON);
    setGeoJson(activityGeoJSON);

    const geojsonLayer = L.geoJSON(activityGeoJSON as any);
    addNonGroupLayers(geojsonLayer, editableLayers);

    const midPoint = file.points[Math.floor(file.points.length / 2)];
    const point = L.latLng(midPoint.lat, midPoint.lng, midPoint.elev);

    setActivity(newActivity);
    mapInstance.setView(point, 11);
  };

  const updateCalculations = lineGeoJSON => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    calculateDistance(lineGeoJSON);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    calculateElevation(lineGeoJSON);
  };

  const calculateDistance = lineGeoJSON => {
    const distance = parseFloat(turf.lineDistance(lineGeoJSON).toFixed(2));
    setRouteDistance(distance);
  };

  const calculateElevation = lineGeoJSON => {
    const ele = [];
    lineGeoJSON.geometry.coordinates.forEach(coordinate => {
      getElevations([coordinate[0], coordinate[1]], (err, value) => {
        ele.push(value);
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        calculateTotalAscent(ele);
      });
    });

    setElevations(ele);
  };

  function fetchLabelLocation() {
    if (!map) {
      return;
    }

    const centerPoint = map.getCenter();

    refetch({
      lat: centerPoint.lat,
      lon: centerPoint.lng,
    }).then(data => {
      if (!data || !data.data || !data.data.exploreGeocode) {
        setTopLabel('');
        setBottomLabel('');

        return;
      }

      const { country, county, city } = data.data.exploreGeocode;
      setTopLabel(country);

      if (county) {
        setBottomLabel(county);
      }

      if (city) {
        setBottomLabel(city);
      }
    });
  }

  function calculateTotalAscent(ele?) {
    let elevationPoints;

    if (ele && ele.length > 0) {
      elevationPoints = ele;
    }

    let totalUp = 0;

    for (let index = 0; index < elevationPoints.length - 1; index++) {
      const current = elevationPoints[index];
      const next = elevationPoints[index + 1];

      if (next > current) {
        totalUp += next - current;
      }
    }

    totalUp = parseFloat(totalUp.toFixed(2));
    setRouteElevation(totalUp);
  }

  fetchLabelLocation();

  const handleSubmit = async (data: CreateRouteInput) => {
    if (!isFileUpload) {
      for (let i = 0; i < geoJson.geometry.coordinates.length; i++) {
        geoJson.geometry.coordinates[i].push(elevations[i]);
      }
    }

    // `data` is the form data, which is getting appended to the GeoJson
    const newActivity: CreateRouteInput = {
      ...data,
      geoJson: JSON.stringify(geoJson),
    };

    await onSave(newActivity);
  };

  return (
    <div>
      <RegionInfoProvider country={topLabel} county={bottomLabel}>
        <SaveRouteModal
          route={activity}
          isOpen={isSaveModalOpen}
          onCancel={() => setIsSaveModalOpen(false)}
          onClose={() => setIsSaveModalOpen(false)}
          onSave={handleSubmit}
          onError={onError}
        />
      </RegionInfoProvider>
      <div
        id="map"
        className="vx-route-builder absolute top left right bottom"
      />
      {topLabel && bottomLabel && (
        <RegionLabels
          className={'vx-route-builder__labels'}
          topLabel={topLabel}
          bottomLabel={bottomLabel}
        />
      )}
      <RouteBuilderBottomMenu
        routeDistance={routeDistance}
        routeElevation={routeElevation}
        setActivityType={setActivityType}
        activityType={activityType}
        onFileUpload={onFileUpload}
        onSave={() => setIsSaveModalOpen(true)}
      />
    </div>
  );
};

export default RouteBuilder;
