import React, { Fragment } from 'react';

import { useExploreViewQuery } from '../../generated/models';

import ErrorMessage from '../../components/elements/ErrorMessage';
import LoadingIcon from '../../components/elements/LoadingIcon';
import ExploreMap from '../../components/modules/Maps/ExploreMap';

import './explore.scss';

const Explore = () => {
  const activityQuery = useExploreViewQuery({
    variables: { first: 100 },
  });

  const { data, error, loading } = activityQuery;

  if (error) {
    return (
      <ErrorMessage message={`Error Loading activities: ${error.message}`} />
    );
  }

  if (loading) {
    return <LoadingIcon />;
  }

  if (!data || !data.routes) {
    return null;
  }

  const { routes } = data;

  return (
    <Fragment>
      <ExploreMap routes={routes} />
    </Fragment>
  );
};

export default Explore;
