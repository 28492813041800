import * as turf from '@turf/turf';
import { Activity, ActivityType, Route } from '../../generated/models';

interface ICoordinates {
  multiple?: number[][];
  single?: [number, number];
}
enum GeoJsonTypes {
  GeometryCollection = 'GeometryCollection',
  LineString = 'LineString',
  MultiLineString = 'MultiLineString',
  MultiPoint = 'MultiPoint',
  MultiPolygon = 'MultiPolygon',
  Point = 'Point',
  Polygon = 'Polygon',
}

interface IGeoJson {
  bbox?: [number, number, number, number];
  geometry: {
    coordinates: ICoordinates;
    type: GeoJsonTypes;
  };
  properties?: IGeoJsonMeta;
  type: 'Feature' | 'Geometry';
}

interface IGeoJsonMeta {
  activityType: ActivityType;
  name: string;
}

function calculateBBox(geoJson) {
  return turf.bbox(geoJson);
}

// "geometry type" refers to seven
// case-sensitive strings: "Point", "MultiPoint", "LineString",
// "MultiLineString", "Polygon", "MultiPolygon", and
// "GeometryCollection".

function createGeoJson(
  type: GeoJsonTypes,
  coordinates: {
    multiple?: ICoordinates['multiple'];
    single?: ICoordinates['single'];
  },
  metadata?: IGeoJsonMeta,
): IGeoJson {
  const geoJson: any = {
    geometry: {
      coordinates: coordinates.single
        ? coordinates.single
        : coordinates.multiple,
      type,
    },
    properties: metadata,
    type: 'Feature',
  };

  if (type !== GeoJsonTypes.Point) {
    geoJson.bbox = calculateBBox(geoJson);
  }

  return geoJson;
}

export function toLineString(
  activity: Pick<Activity, 'polyline' | 'title' | 'activityType'>,
) {
  return createGeoJson(
    GeoJsonTypes.LineString,
    {
      multiple: activity.polyline.coordinates,
    },
    {
      activityType: activity.activityType,
      name: activity.title,
    },
  );
}

export function toMultiLineString(coordinates: [[number, number]]) {
  /**
   *    {
   *      "type": "MultiLineString",
   *     "coordinates": [
   *          [
   *              [100.0, 0.0],
   *              [101.0, 1.0]
   *          ],
   *          [
   *              [102.0, 2.0],
   *              [103.0, 3.0]
   *          ]
   *      ]
   *  }
   */
  createGeoJson(GeoJsonTypes.LineString, { multiple: coordinates });
}

// Point coordinates are in x, y order (easting, northing for projected
//   coordinates, longitude, and latitude for geographic coordinates):
/**
 *
 * @param coordinates (pLongitude, Latitude])
 */
export function toPoint(
  activity:
    | Pick<Activity, 'marker' | 'title' | 'activityType'>
    | Pick<Route, 'marker' | 'title' | 'activityType'>,
) {
  return createGeoJson(
    GeoJsonTypes.Point,
    { single: [activity.marker.lng, activity.marker.lat] },
    { activityType: activity.activityType, name: activity.title },
  );
}

export function toMultiPoint(coordinates: [[number, number]]) {
  /**
   *     {
   *      "type": "MultiPoint",
   *      "coordinates": [
   *          [100.0, 0.0],
   *          [101.0, 1.0]
   *      ]
   *  }
   */

  return createGeoJson(GeoJsonTypes.MultiPoint, { multiple: coordinates });
}

export function toPointFeatureCollection(
  activities: Array<Pick<Activity, 'marker' | 'title' | 'activityType'>>,
) {
  const features = activities.map(activity =>
    createGeoJson(
      GeoJsonTypes.Point,
      { single: [activity.marker.lng, activity.marker.lat] },
      { activityType: activity.activityType, name: activity.title },
    ),
  );

  const geoJson = {
    features,
    type: 'FeatureCollection',
  };

  return geoJson;
}
