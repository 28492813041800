import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';

interface IProps {
  component: any;
  currentUser: any;
  location?: any;
  path: string;
}

const defaultProps = {
  currentUser: undefined,
  location: {},
};

const PrivateRoute = ({
  component: Component,
  currentUser,
  ...rest
}: IProps) => (
  <Route
    {...rest}
    render={props =>
      currentUser && currentUser.id ? (
        <Component currentUser={currentUser} {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

export default PrivateRoute;

PrivateRoute.defaultProps = defaultProps;
