// This file is adapted from taterbase/gpx-parser
//
// https://github.com/taterbase/gpx-parser

import EasyFit from 'easy-fit';
import Pako from 'pako';
import xml2js from 'xml2js';

const parser = new xml2js.Parser();

function extractGPXTracks(gpx) {
  if (!gpx.trk) {
    // eslint-disable-next-line no-console
    console.log('GPX file has no tracks!', gpx);
    throw new Error('Unexpected gpx file format.');
  }

  const parsedTracks = [];

  gpx.trk.forEach(trk => {
    const name = trk.name && trk.name.length > 0 ? trk.name[0] : 'untitled';

    trk.trkseg.forEach(trkseg => {
      const points = trkseg.trkpt.map(trkpt => ({
        elev: parseFloat(trkpt.ele) || 0,
        lat: parseFloat(trkpt.$.lat),
        // These are available to us, but are currently unused
        lng: parseFloat(trkpt.$.lon),
        // time: new Date(trkpt.time || '0')
      }));

      parsedTracks.push({ name, points });
    });
  });

  return parsedTracks;
}

function extractTCXTracks(tcx, name) {
  if (!tcx.Activities) {
    // eslint-disable-next-line no-console
    console.log('TCX file has no activities!', tcx);
    throw new Error('Unexpected tcx file format.');
  }

  const parsedTracks = [];

  for (const act of tcx.Activities[0].Activity) {
    for (const lap of act.Lap) {
      const points = lap.Track[0].Trackpoint.filter(
        trkpt => trkpt.Position,
      ).map(trkpt => ({
        lat: parseFloat(trkpt.Position[0].LatitudeDegrees[0]),
        lng: parseFloat(trkpt.Position[0].LongitudeDegrees[0]),
        // These are available to us, but are currently unused
        // elev: parseFloat(trkpt.ElevationMeters[0]) || 0
        // time: new Date(trkpt.Time[0] || '0')
      }));

      parsedTracks.push({ name, points });
    }
  }

  return parsedTracks;
}

function extractFITTracks(fit, name) {
  if (!fit.records || fit.records.length === 0) {
    // eslint-disable-next-line no-console
    console.log('FIT file has no records!', fit);
    throw new Error('Unexpected FIT file format.');
  }

  const points = [];

  for (const record of fit.records) {
    if (record.position_lat && record.position_long) {
      points.push({
        lat: record.position_lat,
        lng: record.position_long,
        // Other available fields: timestamp, distance, altitude, speed, heart_rate
      });
    }
  }

  return [{ name, points }];
}

function readFile(file, encoding, isGzipped) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e: any) => {
      const result = e.target.result;

      try {
        return resolve(isGzipped ? Pako.inflate(result) : result);
      } catch (err) {
        return reject(err);
      }
    };

    if (encoding === 'binary') {
      reader.readAsArrayBuffer(file);
    } else {
      reader.readAsText(file);
    }
  });
}

export default function extractTracks(file) {
  const isGzipped = /\.gz$/i.test(file.name);
  const strippedName = file.name.replace(/\.gz$/i, '');
  const format = strippedName
    .split('.')
    .pop()
    .toLowerCase();

  switch (format) {
    case 'gpx':
    case 'tcx' /* Handle XML based file formats the same way */:
      return readFile(file, 'text', isGzipped).then(
        textContents =>
          new Promise((resolve, reject) => {
            parser.parseString(textContents, (err, result) => {
              if (err) {
                reject(err);
              } else if (result.gpx) {
                resolve(extractGPXTracks(result.gpx));
              } else if (result.TrainingCenterDatabase) {
                resolve(
                  extractTCXTracks(result.TrainingCenterDatabase, strippedName),
                );
              } else {
                reject(new Error('Invalid file type.'));
              }
            });
          }),
      );

    case 'fit':
      return readFile(file, 'binary', isGzipped).then(
        contents =>
          new Promise((resolve, reject) => {
            const easyFitParser = new EasyFit({
              force: true,
              mode: 'list',
            });

            easyFitParser.parse(contents, (err, result) => {
              if (err) {
                reject(err);
              } else {
                resolve(extractFITTracks(result, strippedName));
              }
            });
          }),
      );

    default:
      throw new Error(`Unsupported file format: ${format}`);
  }
}
