import classNames from 'classnames';
import * as React from 'react';

import './Card.scss';

interface IProps {
  bgColor?: string;
  children: any;
  className?: string;
  fontColor?: string;
  style?: object;
}

const defaultProps = {
  bgColor: '#fff',
  className: '',
  fontColor: '#000',
  style: undefined,
};

const Card = ({ className, bgColor, fontColor, style, children }: IProps) => {
  const styles = {
    ...style,
    backgroundColor: bgColor,
    color: fontColor,
  };

  const classes = classNames('vx-card', className);

  return (
    <div className={classes} style={styles}>
      {children}
    </div>
  );
};

Card.defaultProps = defaultProps;

export default Card;
