import * as React from 'react';
import { Link } from 'react-router-dom';

import SkiingIcon from '../../../../assets/icons/icon-ski-freeride.svg';
import Logo from '../../../../assets/images/logo.png';
import { Card } from '../../../elements/Card';
import { IActivityCardProps } from './types';

import './ActivityCard.scss';

const ActivityCard = (
  { activity, isClickable = true, size = 'lg' }: IActivityCardProps,
  props,
) => {
  const { id, county, country, stats } = activity;

  return (
    <Card
      key={props.key}
      className={`vx-card-activity vx-card-activity--${size}`}
    >
      <Link to={isClickable ? `/activities/${id}` : '#'}>
        <div className="vx-card-activity__image">
          <span className="vx-tag-label">
            <SkiingIcon height="14" width="14" />
            {'Route'}
          </span>
          <span className="vx-tag-black">{country.name}</span>
          <span className="vx-tag-off-black">{county.name}</span>
          <img src={Logo} alt={`${activity.title} ${activity.activityType}`} />
        </div>
        <div className="vx-card-activity__stats">
          <div className="vx-row">
            <div className="vx-column">
              <span className="vx-card-activity__stats--title">Distance</span>
              <span className="vx-card-activity__stats--stat">
                {stats.totalDistance} km
              </span>
            </div>
            <div className="vertical-line" />
            <div className="vx-column">
              <span className="vx-card-activity__stats--title">Ascent</span>
              <span className="vx-card-activity__stats--stat">
                {stats.totalAscent} m
              </span>
            </div>
          </div>
        </div>
      </Link>
    </Card>
  );
};

export default ActivityCard;
