import { isEmpty } from 'lodash';
import * as React from 'react';
import { Layer, Source, StaticMap } from 'react-map-gl';
import { Link } from 'react-router-dom';

import { IRouteDetailModalProps } from './types';

import { MAPBOX_TOKEN } from '../../../../constants';
import { ROUTES } from '../../../../constants/routes';
import Modal from '../../../elements/Modal';
import { Paragraph } from '../../../elements/Paragraph';
// import PolylineOverlay from '../../../Maps/components/PolylineOverlay/PolylineOverlay';
import BaseMap from '../../Maps/BaseMap';

import './RouteDetailModal.scss';

const { Fragment } = React;

const RouteDetailModal = (props: IRouteDetailModalProps) => {
  const { isOpen, onClose, route } = props;

  if (isEmpty(route)) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className="vx-modal-route-details"
      overlayClassName="frost"
    >
      <Fragment>
        <div className="map-overlay" style={{ height: '75vh', width: '100%' }}>
          {route?.featuredImage ? (
            <img
              src={route?.featuredImage.url}
              height={'100%'}
              width={'100%'}
            />
          ) : (
            <StaticMap
              {...{
                bearing: 0,
                latitude: route?.marker.lat,
                longitude: route?.marker.lng,
                pitch: 60,
                zoom: 13,
              }}
              width="100%"
              height="100%"
              attributionControl={false}
              mapStyle="mapbox://styles/vertrax/ck433h4vb1hfq1do08ybntxza"
              mapboxApiAccessToken={MAPBOX_TOKEN}
            >
              <Source type="geojson" data={JSON.parse(route?.geoJson)}>
                <Layer {...{ type: 'line' }} />
              </Source>
            </StaticMap>
          )}
        </div>
        <div className="vx-route__back-link">
          <Link to={ROUTES.EXPLORE}>Back to map</Link>
        </div>
        <div className="vx-route__hero-container">
          <h1>{route?.title}</h1>
          <div>{route?.activityType}</div>
        </div>
        <hr />
        <div className="vx-route__container">
          <div className="vx-route__left-col">
            {route?.description && (
              <div className="vx-route__description">
                <h3>Trip Notes</h3>
                <Paragraph
                  content={route?.description ? route?.description : ''}
                />
              </div>
            )}
          </div>
          <div className="vx-route__right-col">
            <BaseMap
              viewport={{
                latitude: route?.marker.lat,
                longitude: route?.marker.lng,
                pitch: 60,
                zoom: 13,
              }}
            >
              {/* <PolylineOverlay
                points={JSON.parse(route?.geoJson).geometry.coordinates}
              /> */}
            </BaseMap>
          </div>
        </div>
      </Fragment>
    </Modal>
  );
};

export default RouteDetailModal;
