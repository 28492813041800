export const addMarkerEventListeners = map => {
  // Change the cursor to a pointer when the it enters a feature in the 'activities' layer.
  map.on('mouseenter', 'activities', () => {
    map.getCanvas().style.cursor = 'pointer';
  });

  // Change it back to a pointer when it leaves.
  map.on('mouseleave', 'activities', () => {
    map.getCanvas().style.cursor = '';
  });
};
