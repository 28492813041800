import * as qs from 'query-string';
import * as React from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';
import ArrowIcon from '../../../../assets/icons/icon-arrow-left.svg';
import PlusIcon from '../../../../assets/icons/icon-plus.svg';
import ShareIcon from '../../../../assets/icons/icon-share.svg';

import { IMapNavbarProps } from './types';

import { useDevice } from '../../../../hooks/use-device';
import { AuthContext } from '../../../../providers/AuthProvider';

import './MapNavbar.scss';

const MapNavbar = (props: IMapNavbarProps) => {
  const { isSidebarOpen, onArrowClick, onShareClick, className } = props;
  const authContext = React.useContext(AuthContext);

  const device = useDevice();

  const params = qs.parse(window.location.search);
  if (params.id) {
    delete params.id;
  }

  const cleanParams = qs.stringify(params);

  return (
    <div className={classNames('vx-map-navbar', className)}>
      {device.isDesktop && (
        <>
          <button className="vx-map-menu-btn vx-map-menu-btn__icon">
            <ArrowIcon
              className={
                isSidebarOpen
                  ? 'vx-map-menu-btn__arrow--left'
                  : 'vx-map-menu-btn__arrow--right'
              }
              onClick={onArrowClick}
            />
          </button>
          <Link
            to={
              authContext.isLoggedIn() ? `/routes/new?${cleanParams}` : '/login'
            }
          >
            <button className="vx-map-menu-btn vx-map-menu-btn__icon">
              <PlusIcon />
            </button>
          </Link>{' '}
        </>
      )}
      <div id="geocoder" className="vx-geocoder" />
      {device.isDesktop && (
        <>
          <button className="vx-map-menu-btn vx-map-menu-btn__icon">
            <ShareIcon onClick={onShareClick} />
          </button>
        </>
      )}
    </div>
  );
};

export default MapNavbar;
