export enum ButtonSizes {
  LARGE = 'lg',
  MEDIUM = 'md',
  SMALL = 'sm',
}

export enum ButtonColor {
  DEFAULT = 'default',
  NEGATIVE = 'negative',
  POSITIVE = 'positive',
  PRIMARY = 'primary',
}

export interface IButtonProps {
  active?: boolean;
  children?: any;
  className?: string;
  color?:
    | ButtonColor
    | 'default'
    | 'negative'
    | 'opaque'
    | 'positive'
    | 'primary'
    | 'white'
    | 'white-line';
  disabled?: boolean;
  focus?: boolean;
  hover?: boolean;
  name?: string;
  onClick?: (param: any) => void;
  size?: ButtonSizes | 'sm' | 'md' | 'lg' | 'xl';
  style?: any;
  type?: 'button' | 'submit' | 'reset';
  value?: any;
}
