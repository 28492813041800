import * as React from 'react';
import MapGL, { FlyToInterpolator } from 'react-map-gl';

import { MAPBOX_TOKEN } from '../../../../constants';
import { IBaseMapProps } from './types';

import './BaseMap.scss';

class BaseMap extends React.Component<IBaseMapProps, IBaseMapProps> {
  constructor(props) {
    super(props);

    const { viewport } = props;

    this.state = {
      viewport,
    };

    this.flyToPoint = this.flyToPoint.bind(this);
  }

  public componentDidMount() {
    const { viewport } = this.props;

    this.setState({
      viewport,
    });
  }

  // eslint-disable-next-line react/no-deprecated
  public componentWillReceiveProps(prevProps) {
    const { flyToPoint } = prevProps;

    if (flyToPoint) {
      this.flyToPoint(flyToPoint.lng, flyToPoint.lat, 13.3);
    }
  }

  public updateViewport = viewport => {
    this.setState({ viewport });
  };

  public flyToPoint = (latitude: number, longitude: number, zoom?: number) => {
    this.updateViewport({
      latitude,
      longitude,
      pitch: 60,
      transitionDuration: 3000,
      transitionInterpolator: new FlyToInterpolator(),
      zoom: zoom ? zoom : 15,
    });
  };

  public render() {
    const { viewport } = this.state;
    const { children } = this.props;

    return (
      <MapGL
        {...viewport}
        width="100%"
        height="100%"
        mapStyle="mapbox://styles/vertrax/ck433h4vb1hfq1do08ybntxza"
        onViewportChange={this.updateViewport}
        mapboxApiAccessToken={MAPBOX_TOKEN}
      >
        {children}
      </MapGL>
    );
  }
}

export default BaseMap;
