import * as React from 'react';

interface IProps {
  text: string;
}
const ImageLabel = ({ text }: IProps) => (
  <div className="vx-card__image--label">{text}</div>
);

export default ImageLabel;
