import classNames from 'classnames';
import noop from 'lodash/noop';
import React, { DOMAttributes } from 'react';
import { Link } from 'react-router-dom';
import { INavLink } from './types';

import AcheivedIcon from '../../../assets/icons/icon-acheived.svg';
import ChallengesIcon from '../../../assets/icons/icon-challenges.svg';
import FacebookIcon from '../../../assets/icons/icon-facebook.svg';
import InstagramIcon from '../../../assets/icons/icon-instagram.svg';
import SavedIcon from '../../../assets/icons/icon-save.svg';
import TwitterIcon from '../../../assets/icons/icon-twitter.svg';
import WorldIcon from '../../../assets/icons/icon-world-map.svg';

const NavLink = ({
  link,
  icon,
  className,
  onLinkClick = noop,
  children,
  ...anchorProps
}: INavLink & DOMAttributes<HTMLAnchorElement>) => {
  const linkClasses = classNames(
    'vx-nav__link',
    `${window.location.pathname === link.href ? 'vx-nav__link--active' : ''}`,
    className,
  );

  let iconSvg;

  switch (icon) {
    case 'ACHEIVED':
      iconSvg = <AcheivedIcon />;
      break;
    case 'CHALLENGES':
      iconSvg = <ChallengesIcon />;
      break;
    case 'MAP':
      iconSvg = <WorldIcon />;
      break;
    case 'SAVED':
      iconSvg = <SavedIcon />;
      break;
    case 'FACEBOOK':
      iconSvg = <FacebookIcon />;
      break;
    case 'INSTAGRAM':
      iconSvg = <InstagramIcon />;
      break;
    case 'TWITTER':
      iconSvg = <TwitterIcon />;
      break;
    default:
      break;
  }

  return (
    <>
      <li className="vx-nav__item" onClick={onLinkClick}>
        {iconSvg && (
          <a
            href={link.href}
            target={link.openInNewTab && '_blank'}
            className={linkClasses}
            {...(anchorProps as DOMAttributes<HTMLAnchorElement>)}
          >
            <div className="vx-nav__svg-container">{iconSvg}</div>
          </a>
        )}
        {link.title && (
          <div className="vx-nav__link-container">
            {link.openInNewTab ? (
              <a
                href={link.href}
                target={link.openInNewTab && '_blank'}
                className={linkClasses}
                {...(anchorProps as DOMAttributes<HTMLAnchorElement>)}
              >
                {link.title}
                {children}
              </a>
            ) : (
              <Link to={link.href} className={linkClasses}>
                {link.title}
                {children}
              </Link>
            )}
          </div>
        )}
      </li>
    </>
  );
};

export default NavLink;
