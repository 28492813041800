import React, { Fragment, useContext, useState } from 'react';

import AuthForm from '../../../components/modules/Forms/AuthForm';
import { ROUTES } from '../../../constants/routes';
import { LoginComponent } from '../../../generated/models';
import auth from '../../../utils/auth';
import { ILoginProps } from './types';

import LoadingIcon from '../../../components/elements/LoadingIcon';
import { AuthContext } from '../../../providers/AuthProvider';
import stylesheet from './login.scss';

const Login = (props: ILoginProps) => {
  const { history } = props;

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<string>('');
  const authContext = useContext(AuthContext);

  return (
    <Fragment>
      <style dangerouslySetInnerHTML={{ __html: stylesheet }} />
      <LoginComponent
        onCompleted={async data => {
          setLoading(true);
          auth.saveAuthTokens(data.login.accessToken, data.login.refreshToken);

          await authContext.fetchUser();

          if (authContext.isLoggedIn()) {
            window.location.reload();

            history.replace(document.referrer ?? ROUTES.EXPLORE);
          }
        }}
      >
        {login => (
          <div className="vx-login">
            {loading && <LoadingIcon />}
            <AuthForm
              serverErrors={errors}
              onSave={async authData => {
                try {
                  await login({
                    variables: {
                      email: authData.email,
                      password: authData.password,
                    },
                  });
                } catch (error) {
                  setErrors(error.message);
                }
              }}
            />
          </div>
        )}
      </LoginComponent>
    </Fragment>
  );
};

export default Login;
