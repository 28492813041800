import React, { Fragment } from 'react';

import {
  Area,
  AreaChart,
  Brush,
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { IActivityDetailChartsProps } from './types';

const ActivityDetailCharts = (props: IActivityDetailChartsProps) => {
  const { streams } = props;

  const graphData = [];

  if (streams) {
    const time = streams.time;
    const distance = streams.distance;
    const elevation = streams.elevation;
    const temp = streams.temperature;

    for (let i = 0; i < time.length; i++) {
      const startTime: number = +time[0];
      const currentTime: number = +time[i];
      const timeDiff: number = currentTime - startTime;
      const mins = timeDiff / 1000 / 60;
      const hours = Math.floor(mins / 60);
      const remaining = Math.floor(mins % 60);

      graphData.push({
        distance: distance[i].toFixed(2),
        elevation: elevation[i].toFixed(2),
        temp: temp[i],
        time: `${hours}:${remaining}`,
      });
    }
  }

  return (
    <Fragment>
      <p>Elevation</p>
      <div className="vx-activity__graph">
        <ResponsiveContainer>
          <AreaChart
            data={graphData}
            syncId="anyId"
            margin={{
              bottom: 0,
              left: 0,
              right: 30,
              top: 10,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="distance" />
            <YAxis domain={['dataMin - 50 ', 'dataMax + 50']} />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="elevation"
              stroke="#82ca9d"
              fill="#82ca9d"
            />
            <Brush />
          </AreaChart>
        </ResponsiveContainer>
      </div>
      <p>Temperature</p>
      <div className="vx-activity__graph">
        <ResponsiveContainer>
          <LineChart
            data={graphData}
            syncId="anyId"
            margin={{
              bottom: 0,
              left: 0,
              right: 30,
              top: 10,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="time" />
            <YAxis domain={['dataMin', 'dataMax']} />
            <Tooltip />
            <Line
              type="monotone"
              dataKey="temp"
              stroke="#82ca9d"
              fill="#82ca9d"
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </Fragment>
  );
};

export default ActivityDetailCharts;
