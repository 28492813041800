//TYPOGRAPHY REPOSITORY
//====================================================
//FONT WEIGHTS
export const FontWeights = {
  bold: 500,
  bolder: 600,
  boldest: 700,
  light: 300,
  lighter: 200,
  lightest: 100,
  normal: 400,
};

export type FontWeightKey = keyof typeof FontWeights;

export const FontSizes = {
  pt10: ' 1rem',
  pt11: ' 1.1rem',
  pt12: ' 1.2rem',
  pt13: ' 1.3rem',
  pt14: ' 1.4rem',
  pt15: ' 1.5rem', // Only use for input fields. 1.5rem === 1.6rem. Input field renders differently'
  pt16: ' 1.6rem',
  pt18: ' 1.8rem',
  pt20: ' 2rem',
  pt22: ' 2.2rem',
  pt24: ' 2.4rem',
  pt26: ' 2.6rem',
  pt28: ' 2.8rem',
  pt32: ' 3.2rem',
  pt36: ' 3.6rem',
  pt40: ' 4rem',
  pt48: ' 4.8rem',
  pt8: '0.8rem',
  pt9: '0.9rem',
};

export type FontSizeKey = keyof typeof FontSizes;
