// Label Function
import { css } from 'emotion';
import { EColors } from '../../../styles/theme/v2/colors';
import { FontSizes } from '../../../styles/theme/v2/typography';
import { LabelColors, LabelSize } from './types';

const labelCommon = (labelColor, textColor) => css`
  color: ${textColor};
  background-color: ${labelColor};

  :hover {
    text-decoration: none;
    opacity: 0.8;
  }

  :active {
    opacity: 0.95;
  }

  :focus {
    outline: none;
  }
`;

const labelSize = size => {
  switch (size) {
    case 'xs':
      return css`
        height: 16px;
        font-size: ${FontSizes.pt8};
      `;
    case 'sm':
      return css`
        height: 16px;
        font-size: ${FontSizes.pt16};
      `;
    case 'lg':
      return css`
        height: 46px;
        font-size: ${FontSizes.pt24};
      `;
    case 'xl':
      return css`
        height: 46px;
        font-size: ${FontSizes.pt32};
        padding: 1.2rem 1.6rem;
      `;
    case 'md':
    default:
      return css`
        height: 40px;
        font-size: ${FontSizes.pt16};
      `;
  }
};

export const label = (color: LabelColors, size: LabelSize) => css`
  cursor: pointer;
  width: fit-content;
  height: 40px;
  padding: 0.8rem 1.6rem;
  border-radius: 2px;
  font-size: 18px;

  display: flex;
  align-items: left;
  justify-content: left;

  margin-bottom: 2px;
  ${color === 'black' && labelCommon(EColors.black, EColors.white)}
  ${color === 'purple' && labelCommon(EColors.offBlack, EColors.white)}

  ${labelSize(size)};
`;
