import React, { FC, useContext, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import LogRocket from 'logrocket';
import { withRouter } from 'react-router';

import ProfileModal from '../components/modules/Modals/ProfileModal';
import Navbar from '../components/Navbar';
import Main from '../pages';

import * as constants from '../constants';

import { AuthContext } from '../providers/AuthProvider';
import './app.scss';

const App: FC<any> = () => {
  const authContext = useContext(AuthContext);
  const [isProfileVisible, setIsProfileVisible] = useState(false);
  if (!constants.isDev) {
    LogRocket.init('fs2j2e/vertrax');
  }

  const user = authContext.user;

  if (authContext.isLoggedIn()) {
    LogRocket.identify(user.id, {
      email: user.email,
      name: user.name,
    });
  }

  return (
    <>
      <Navbar onAvatarClick={() => setIsProfileVisible(true)} />
      <ProfileModal
        isOpen={isProfileVisible && !isEmpty(user)}
        onClose={() => setIsProfileVisible(false)}
      />
      <Main user={authContext.user} />
    </>
  );
};

export default withRouter(App as React.FunctionComponent<any>);
