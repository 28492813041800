import * as React from 'react';
import { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import { Layer, Source, StaticMap } from 'react-map-gl';
import client from '../../../lib/apolloClient';

import { MAPBOX_TOKEN } from '../../../constants';
import { RouteDetailsViewComponent } from '../../../generated/models';

import LoadingIcon from '../../../components/elements/LoadingIcon';
import { Paragraph } from '../../../components/elements/Paragraph';
import PolylineOverlay from '../../../components/Maps/components/PolylineOverlay/PolylineOverlay';
import BaseMap from '../../../components/modules/Maps/BaseMap';

import { ROUTES } from '../../../constants/routes';

import './details.scss';
interface IRouteProps {
  location: string;
}

interface IRouteState {
  id: string;
}

class Route extends Component<IRouteProps, IRouteState> {
  public state = {
    id: undefined,
  };

  public static getDerivedStateFromProps(props) {
    const id = props.location.pathname.replace('/routes/', '');

    return { id };
  }

  public componentWillUnmount() {
    client.resetStore();
  }

  public render() {
    const { id } = this.state;

    return (
      <div>
        <div className="vx-route">
          <RouteDetailsViewComponent
            variables={{
              id,
            }}
          >
            {({ data, loading, error, networkStatus }) => {
              if (loading && networkStatus !== 3) {
                return <LoadingIcon />;
              }

              // Show Error View
              if (error) {
                return <div>Something went wrong</div>;
              }

              const { route } = data;

              return (
                <Fragment>
                  <div
                    className="map-overlay"
                    style={{ height: '75vh', width: '100%' }}
                  >
                    {route.featuredImage ? (
                      <img
                        src={route.featuredImage.url}
                        height={'100%'}
                        width={'100%'}
                      />
                    ) : (
                      <StaticMap
                        {...{
                          bearing: 0,
                          latitude: route.marker.lat,
                          longitude: route.marker.lng,
                          pitch: 60,
                          zoom: 13,
                        }}
                        width="100%"
                        height="100%"
                        attributionControl={false}
                        mapStyle="mapbox://styles/vertrax/ck433h4vb1hfq1do08ybntxza"
                        mapboxApiAccessToken={MAPBOX_TOKEN}
                      >
                        <Source type="geojson" data={JSON.parse(route.geoJson)}>
                          <Layer {...{ type: 'line' }} />
                        </Source>
                      </StaticMap>
                    )}
                  </div>
                  <div className="vx-route__back-link">
                    <Link to={`${ROUTES.EXPLORE}?id=${route.id}`}>
                      Back to map
                    </Link>
                  </div>
                  <div className="vx-route__hero-container">
                    <h1>{route.title}</h1>
                    <div>{route.activityType}</div>
                  </div>
                  <hr />
                  <div className="vx-route__container">
                    <div className="vx-route__left-col">
                      {route.description && (
                        <div className="vx-route__description">
                          <h3>Trip Notes</h3>
                          <Paragraph
                            content={route.description ? route.description : ''}
                          />
                        </div>
                      )}
                    </div>
                    <div className="vx-route__right-col">
                      <BaseMap
                        viewport={{
                          latitude: route.marker.lat,
                          longitude: route.marker.lng,
                          pitch: 60,
                          zoom: 13,
                        }}
                      >
                        <PolylineOverlay
                          points={
                            JSON.parse(route.geoJson).geometry.coordinates
                          }
                        />
                      </BaseMap>
                    </div>
                  </div>
                </Fragment>
              );
            }}
          </RouteDetailsViewComponent>
        </div>
      </div>
    );
  }
}

export default Route;
