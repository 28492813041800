export const ActivityTypes = [
  { label: '', value: '' },
  {
    label: 'Backcountry Skiing',
    value: 'BackcountrySkiing',
  },
  {
    label: 'Biking',
    value: 'Biking',
  },
  {
    label: 'Climbing',
    value: 'Climbing',
  },
  {
    label: 'Cross-Country Skiing',
    value: 'CrossCountrySkiing',
  },
  {
    label: 'Hiking',
    value: 'Hiking',
  },
  {
    label: 'Mountaineering',
    value: 'Mountaineering',
  },
  {
    label: 'Other',
    value: 'Other',
  },
  {
    label: 'Resort Skiing',
    value: 'ResortSkiing',
  },
];

export const SummerActivityTypes = [
  {
    label: 'Biking',
    value: 'Biking',
  },
  {
    label: 'Climbing',
    value: 'Climbing',
  },
  {
    label: 'Hiking',
    value: 'Hiking',
  },
  {
    label: 'Mountaineering',
    value: 'Mountaineering',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

export const WinterActivityTypes = [
  {
    label: 'Backcountry Skiing',
    value: 'BackcountrySkiing',
  },
  {
    label: 'Cross-Country Skiing',
    value: 'CrossCountrySkiing',
  },
  {
    label: 'Mountaineering',
    value: 'Mountaineering',
  },
  {
    label: 'Other',
    value: 'Other',
  },
  {
    label: 'Resort Skiing',
    value: 'ResortSkiing',
  },
];
