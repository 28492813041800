import * as React from 'react';

import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import * as turf from '@turf/turf';
import classNames from 'classnames';
import mapboxgl, { Map } from 'mapbox-gl';
import * as qs from 'query-string';
import { useHistory } from 'react-router-dom';

import RegionLabels from '../../RegionLabels/RegionLabels';
import MapNavbar from '../MapNavbar';
import ExploreSidebar from './components/ExploreSidebar';

import { MAPBOX_TOKEN } from '../../../../constants';
import { toPointFeatureCollection } from '../../../../utils/geo-json/converter';

import {
  ExploreRouteFragment,
  useExploreGeocodeQuery,
} from '../../../../generated/models';
import { useDevice } from '../../../../hooks/use-device';

import { addMarkerEventListeners } from './markerEventListeners';
import { IExploreMapProps, ZoomLevel } from './types';

import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import './ExploreMap.scss';

mapboxgl.accessToken = MAPBOX_TOKEN;

const geocoder = new MapboxGeocoder({
  accessToken: MAPBOX_TOKEN,
  mapboxgl,
});

const layers = [
  'mapbox://styles/vertrax/ck433h4vb1hfq1do08ybntxza',
  'mapbox://styles/mapbox/satellite-v9',
];

export const getMap = (map, mapInstance) => {
  let initializedMap = map;
  if (!initializedMap) {
    initializedMap = mapInstance;
  }

  return initializedMap;
};

const ExploreMap = (props: IExploreMapProps) => {
  let IS_UNMOUNTING = false;
  let IS_FULLY_LOADED = false;
  let mapContainer;
  let map: Map;
  let isFlying = false;

  const { routes } = props;

  // TODO: Refactor to useReducer() instead
  const [topLabel, setTopLabel] = React.useState('');
  const [selectedRoute, setSelectedRoute] = React.useState(null);
  const [bottomLabel, setBottomLabel] = React.useState('');
  const [isOpen, setIsOpen] = React.useState(false);
  const [isOverlayVisible, setIsOverlayVisible] = React.useState(false);
  const [selectedLayer] = React.useState(layers[0]);
  const [mapInstance, setMapInstance] = React.useState<Map>(null);
  const [sidebarActivities, setSidebarActivities] = React.useState<
    ExploreRouteFragment[]
  >([]);

  const [, setUrlParams] = React.useState<any>();

  const history = useHistory();

  const [zoom, setZoom] = React.useState(3.5);

  const geocode = useExploreGeocodeQuery({
    variables: { lat: 8, lon: 63 },
  });

  const { refetch } = geocode;

  const device = useDevice();

  React.useEffect(() => {
    if (IS_UNMOUNTING) {
      return;
    }

    const params = new URLSearchParams(window.location.search);
    setUrlParams(params);
    const byPassAutoLoad: boolean =
      !!Number(params.get('lat')) && !!Number(params.get('lng'));

    const urlCenter: [number, number] = [
      Number(params.get('lng')),
      Number(params.get('lat')),
    ];

    const urlRouteId = params.get('id');

    map = new mapboxgl.Map({
      center: byPassAutoLoad ? urlCenter : [8, 63],
      container: mapContainer,
      style: selectedLayer,
      zoom,
    });

    setMapInstance(map);

    map.on('load', () => {
      map.addSource('mapbox-dem', {
        maxzoom: 14,
        tileSize: 512,
        type: 'raster-dem',
        url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
      });
      // add the DEM source as a terrain layer with exaggerated height
      // @ts-ignore
      map.setTerrain({ exaggeration: 1.5, source: 'mapbox-dem' });

      // add a sky layer that will show when the map is highly pitched
      map.addLayer({
        id: 'sky',
        paint: {
          // @ts-ignore
          'sky-atmosphere-sun': [0.0, 0.0],
          'sky-atmosphere-sun-intensity': 15,
          'sky-type': 'atmosphere',
        },
        // @ts-ignore
        type: 'sky',
      });
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      loadActivityMarkers();
      addMarkerEventListeners(map);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      loadNorwayRegionBoundaries();

      const routeFromUrl = routes.filter(route => route.id === urlRouteId)[0];

      if (IS_UNMOUNTING) {
        return;
      }

      if (routeFromUrl) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        onSelectedActivity(routeFromUrl);
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        updateUrlParams(routeFromUrl.id);
      } else {
        // Fly to a random Activity or center point in the App
        // eslint-disable-next-line
        byPassAutoLoad ? flyToCenter(urlCenter) : flyToRandomActivity();
      }

      map.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
      map.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true,
          },
          trackUserLocation: true,
        }),
        'bottom-right',
      );
      map.fire('done-loading');
    });

    // Add an event listener for when a user clicks on the map
    map.on('click', e => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      handleMarkerClick(e);
    });

    map.on('flystart', () => {
      isFlying = true;
    });
    map.on('flyend', () => {
      isFlying = false;
    });

    map.on('done-loading', () => {
      IS_FULLY_LOADED = true;
    });

    map.on('moveend', () => {
      const features = map.queryRenderedFeatures(null, {
        layers: ['routes'],
      });

      setZoom(map.getZoom());

      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      fetchLabelLocation();

      if (isFlying) {
        if (device.isDesktop) {
          setIsOpen(true);
        }

        getMap(map, mapInstance).fire('flyend');
      }

      // Show Only Activities visible on Map
      if (features) {
        const intersection = routes.filter(n =>
          features.some(n2 => n.title === n2.properties.name),
        );

        setSidebarActivities(intersection);
      }

      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      updateUrlParams(selectedRoute?.id, !IS_UNMOUNTING);
    });

    document.getElementById('geocoder').appendChild(geocoder.onAdd(map));

    return () => {
      IS_UNMOUNTING = true;
      map.remove();
    };
  }, []);

  React.useEffect(() => {
    const routeId = qs.parse(window.location.search).id;

    if (routeId && IS_FULLY_LOADED) {
      const newRoute = routes.filter(route => route.id === routeId)[0];
      setSelectedRoute(newRoute);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      onSelectedActivity(newRoute);
    }
  }, [qs.parse(window.location.search).id]);

  function updateUrlParams(activityId?: string, isOnlyMoving = false) {
    const center = getMap(map, mapInstance).getCenter();
    const params = qs.parse(window.location.search);

    if (!IS_UNMOUNTING) {
      history.push({
        pathname: '/explore',
        search: `?lat=${center.lat}&lng=${center.lng}${
          isOnlyMoving
            ? params.id
              ? `&id=${params.id}`
              : ''
            : activityId && `&id=${activityId}`
        }`,
      });
    }
  }

  function fetchLabelLocation() {
    const centerPoint = map.getCenter();

    refetch({
      lat: centerPoint.lat,
      lon: centerPoint.lng,
    }).then(data => {
      if (!data || !data.data || !data.data.exploreGeocode) {
        setTopLabel('');
        setBottomLabel('');

        return;
      }

      const { country, county, city } = data.data.exploreGeocode;

      setTopLabel(country);

      if (county) {
        setBottomLabel(county);
      }

      if (city) {
        setBottomLabel(city);
      }
    });
  }

  const handleMarkerClick = (
    e: mapboxgl.MapMouseEvent & mapboxgl.EventData,
  ) => {
    // Query all the rendered points in the view
    const features = map.queryRenderedFeatures(e.point, {
      layers: ['routes'],
    });
    if (features.length) {
      const clickedPoint = features[0];

      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      onSelectedActivity(clickedPoint);
    }
  };

  const loadNorwayRegionBoundaries = () => {
    map.addLayer({
      id: 'regions',
      paint: {
        'line-color': '#000',
        'line-opacity': 0.5,
        'line-width': 2,
      },
      source: {
        data: 'https://api-staging.vertrax.io/webhooks/regions',
        type: 'geojson',
      },
      type: 'line',
    });
  };

  const loadActivityMarkers = () => {
    // Get All Markers in GeoJSON
    const geoJson = toPointFeatureCollection(routes);

    map.addLayer({
      id: 'routes',
      layout: {
        'icon-allow-overlap': true,
        'icon-image': 'skiing',
      },
      // Add a GeoJSON source containing place coordinates and information.
      source: {
        data: geoJson as any,
        type: 'geojson',
      },
      type: 'symbol',
    });
  };

  const flyToCenter = center => {
    getMap(map, mapInstance).flyTo({
      center,
      pitch: 0,
      zoom: 8,
    });
  };

  const flyToActivity = currentFeature => {
    getMap(map, mapInstance).flyTo({
      center: currentFeature?.geometry?.coordinates ?? [
        currentFeature.marker.lng,
        currentFeature.marker.lat,
      ],
      pitch: 50,
      zoom: 12.5,
    });
  };

  const removePolyline = () => {
    const initializedMap = getMap(map, mapInstance);

    if (!IS_UNMOUNTING && initializedMap?.getLayer('line')) {
      getMap(map, mapInstance).removeLayer('line');
      getMap(map, mapInstance).removeSource('line');
    }
  };

  const removeMileMarkers = () => {
    const popups = document.querySelectorAll('.vx-mile-marker');

    if (popups) {
      popups.forEach(element => {
        element.remove();
      });
    }
  };

  const addMileMarkers = (activity: ExploreRouteFragment) => {
    removeMileMarkers();

    const polyline = JSON.parse(activity.geoJson);
    const totalDistance = turf.lineDistance(polyline);

    for (let index = 1; index <= totalDistance; index++) {
      const point = turf.along(polyline, index);

      new mapboxgl.Popup({
        className: 'vx-mile-marker',
        closeButton: false,
        closeOnClick: false,
      })
        .setLngLat([
          point.geometry.coordinates[0],
          point.geometry.coordinates[1],
        ])
        .setHTML(`<p>${index}</p>`)
        .addTo(getMap(map, mapInstance));
    }
  };

  function onLabelClick(level: ZoomLevel) {
    let newZoomLevel = level === ZoomLevel.top ? 3 : 7;

    if (newZoomLevel < 0) {
      newZoomLevel = 1.5;
    }

    setZoom(newZoomLevel);
    getMap(map, mapInstance).setZoom(newZoomLevel);
    getMap(map, mapInstance).setPitch(0);
    setIsOpen(false);
  }

  const createPolyline = (route: ExploreRouteFragment) => {
    // Remove previous line
    removePolyline();

    const geoJson = JSON.parse(route.geoJson);
    getMap(map, mapInstance).addLayer({
      id: 'line',
      paint: {
        'line-color': '#302E45',
        'line-opacity': 0.8,
        'line-width': 2,
      },
      source: {
        data: geoJson,
        lineMetrics: true,
        type: 'geojson',
      },
      type: 'line',
    });
    getMap(map, mapInstance).setPitch(50);
    getMap(map, mapInstance).fitBounds(turf.bbox(geoJson));

    addMileMarkers(route);
  };

  const createPopUp = currentFeature => {
    const popUps = document.getElementsByClassName('mapboxgl-popup');
    // Check if there is already a popup on the map and if so, remove it
    if (popUps[0]) {
      popUps[0].remove();
    }

    const popup = new mapboxgl.Popup({
      className: 'vx-popup',
      closeOnClick: false,
    })
      .setLngLat(
        currentFeature?.geometry?.coordinates ?? [
          currentFeature.marker.lng,
          currentFeature.marker.lat,
        ],
      )
      .setHTML(
        `<p">${currentFeature?.properties?.name ?? currentFeature.title}</p">`,
      )
      .addTo(getMap(map, mapInstance));

    popup.on('close', () => {
      removeMileMarkers();
      removePolyline();
      setIsOpen(false);

      updateUrlParams(null);

      getMap(map, mapInstance).flyTo({
        center: currentFeature?.geometry?.coordinates ?? [
          currentFeature.marker.lng,
          currentFeature.marker.lat,
        ],
        pitch: 0,
        zoom: 8,
      });
    });
  };

  const onSelectedActivity = currentFeature => {
    // 1. Fly to the point
    flyToActivity(currentFeature);
    // 2. Close all other popups and display popup for clicked activity
    createPopUp(currentFeature);

    // 3. Show Polyline for selected activity
    const currentActivity = routes.filter(
      el =>
        el.title === currentFeature?.properties?.name ||
        el.title === currentFeature?.title,
    );

    setSelectedRoute(currentActivity[0]);
    updateUrlParams(currentActivity[0]?.id);
    createPolyline(currentActivity[0]);
    getMap(map, mapInstance).fire('flystart');
  };

  const flyToRandomActivity = () => {
    // Get All Markers in GeoJSON
    const geoJson = toPointFeatureCollection(routes.map(r => r));
    const randomActivity =
      geoJson.features[Math.floor(Math.random() * (routes.length - 1))];

    if (randomActivity) {
      const currentActivity = routes.filter(
        el => el.title === randomActivity?.properties?.name,
      );
      setSelectedRoute(currentActivity[0]);
      updateUrlParams(selectedRoute?.id);
    }

    // Fly to a random Activity in the App
    setTimeout(() => onSelectedActivity(randomActivity), 750);
  };

  return (
    <div className="vx-explore">
      <div
        id="explore-map"
        ref={el => (mapContainer = el)}
        className={classNames('absolute top right left bottom', {
          'vx-explore__overlay': isOverlayVisible,
        })}
      />
      {topLabel && bottomLabel && (
        <RegionLabels
          topLabel={topLabel}
          bottomLabel={bottomLabel}
          onTopLabelClick={() => onLabelClick(ZoomLevel.top)}
          onBottomLabelClick={() => onLabelClick(ZoomLevel.mid)}
          weatherLabel={'12°C'}
        />
      )}
      <div className="flex w-100" style={{ height: 'calc(100vh - 65px)' }}>
        <div
          className="vx-explore__sidebar"
          style={{ overflowY: 'hidden', zIndex: 10 }}
        >
          <ExploreSidebar
            show={isOpen}
            activities={sidebarActivities}
            selectedActivity={selectedRoute}
            onClick={activity => onSelectedActivity(activity)}
          />
        </div>
        <div className="vx-explore__navbar">
          <MapNavbar
            isSidebarOpen={isOpen}
            onArrowClick={() => setIsOpen(!isOpen)}
            onShareClick={() => setIsOverlayVisible(!isOverlayVisible)}
          />
        </div>
      </div>
    </div>
  );
};

export default ExploreMap;
