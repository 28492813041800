import classNames from 'classnames';
import * as React from 'react';
import { useState } from 'react';

import startCase from 'lodash/startCase';

import BackgroundLabel from '../../../elements/BackgroundLabel';
import { Card } from '../../../elements/Card';

import ExploreCardExpansion from './ExploreCardExpansion';

import { IExploreCardProps } from './types';

import PlayIcon from '../../../../assets/icons/icon-arrow-down-soild.svg';
import SkiingIcon from '../../../../assets/icons/icon-ski-freeride.svg';
import Logo from '../../../../assets/images/logo.png';

import { convertToHoursAndMins } from '../../../../utils/time';

import './ExploreCard.scss';

const ExploreCard = (props: IExploreCardProps) => {
  const {
    route,
    isSelected = false,
    size = 'lg',
    isExpandable = false,
  } = props;

  const {
    activityType,
    approach,
    description,
    distance,
    duration,
    elevation,
    featuredImage,
    mountains,
    title,
  } = route;

  const [isExpanded, setIsExpanded] = useState(isSelected);

  return (
    <div>
      <Card
        className={classNames(`vx-explore-card vx-explore-card--${size}`, {
          'vx-explore-card__expanded': Boolean(size !== 'sm' && isExpanded),
        })}
      >
        <div onClick={() => setIsExpanded(!isExpanded)}>
          <div className="vx-explore-card__body">
            <div className="vx-card-activity__image">
              <div className="flex">
                <span className="vx-tag-label">
                  <SkiingIcon height="16" className="mr2" />
                  {'Ski Route'}
                </span>
              </div>
              {approach && (
                <BackgroundLabel
                  title={`${startCase(approach)} approach`}
                  className="vx-explore-card__title-label"
                />
              )}
              {mountains.length > 0 && (
                <BackgroundLabel
                  title={mountains.map(mtn => mtn.name).join()}
                  className="vx-explore-card__mt-label"
                />
              )}
              <div className="vx-card-activity__image--overlay">
                <img
                  src={featuredImage ? featuredImage.url : Logo}
                  alt={`${title} ${activityType}`}
                />
              </div>
            </div>
          </div>
          <div className="vx-explore-card__graph">
            <div className="vx-explore-card__details">
              <SkiingIcon className="vx-explore-card__activity-icon" />
              <div className="vertical-line" />
              <div className="vx-explore-card__stats">
                <div className="vx-explore-card__stat">
                  <div className="vx-explore-card__stat--header">
                    <PlayIcon style={{ height: '12px', width: '12px' }} />
                    <span>Distance</span>
                  </div>

                  <p className="vx-explore-card__stat--value">{distance} km</p>
                </div>
              </div>
              {duration > 0 && (
                <div className="vx-explore-card__stat">
                  <div className="vx-explore-card__stat--header">
                    <PlayIcon style={{ height: '12px', width: '12px' }} />
                    <span>Duration</span>
                  </div>

                  <p className="vx-explore-card__stat--value">
                    {convertToHoursAndMins(duration)}
                  </p>
                </div>
              )}
              <div className="vx-explore-card__stat">
                <div className="vx-explore-card__stat--header">
                  <PlayIcon style={{ height: '12px', width: '12px' }} />
                  <span>Descent</span>
                </div>

                <p className="vx-explore-card__stat--value">{elevation} m</p>
              </div>
            </div>
          </div>
        </div>
      </Card>
      {Boolean(isExpanded && isExpandable) && (
        <ExploreCardExpansion route={route} description={description} />
      )}
    </div>
  );
};

export default ExploreCard;
