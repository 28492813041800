export default {
  type: "Feature",
  properties: {},
  geometry: {
    type: "LineString",
    coordinates: [
      [8.247262, 61.548499, 1403.9000000000015, 1403.9000000000015],
      [8.249908, 61.548396, 1401.1000000000004, 1401.1000000000004],
      [8.255683, 61.547885, 1401.1000000000004, 1401.1000000000004],
      [8.265528, 61.547342, 1401, 1401],
      [8.272773, 61.547502, 1449.4000000000015, 1436.1000000000004],
      [8.277791, 61.548448, 1401, 1435.2000000000007],
      [8.281966, 61.548646, 1401, 1401],
      [8.284807, 61.54899, 1435.2000000000007, 1401],
      [8.296459, 61.548489, 1436.1000000000004, 1449.4000000000015],
      [8.310205, 61.546072, 1446, 1449.2000000000007],
      [8.321015, 61.545045, 1446, 1449.2000000000007],
      [8.324945, 61.543801, 1449.2000000000007, 1446],
      [8.326886, 61.542624, 1449.2000000000007, 1446],
      [8.32872, 61.540196, 1459.800000000001, 1459.800000000001],
      [8.330529, 61.5396, 1460.1000000000004, 1460.1000000000004],
      [8.334235, 61.53938, 1464.7000000000007, 1464.7000000000007],
      [8.338155, 61.538818, 1464.7000000000007, 1464.7000000000007],
      [8.344459, 61.536065, 1473.9000000000015, 1508.300000000001],
      [8.347952, 61.533628, 1508.300000000001, 1473.9000000000015],
      [8.355861, 61.532719, 1549.9000000000015, 1663.7000000000007],
      [8.357055, 61.534425, 1553, 1666.2000000000007],
      [8.35636, 61.535045, 1647.4000000000015, 1553],
      [8.357097, 61.534466, 1666.2000000000007, 1549.9000000000015],
      [8.355845, 61.532749, 1663.7000000000007, 1647.4000000000015],
      [8.347951, 61.533684, 1720.2000000000007, 1720.2000000000007],
      [8.344411, 61.536164, 1856.800000000001, 1698.6000000000004],
      [8.338119, 61.538915, 1698.6000000000004, 1856.800000000001],
      [8.334078, 61.53945, 1958.1000000000004, 1958.1000000000004],
      [8.330557, 61.539694, 1957, 2033.2000000000007],
      [8.328612, 61.540324, 1853, 2033.1000000000004],
      [8.326775, 61.542656, 2033.2000000000007, 2109.2000000000007],
      [8.32488, 61.543846, 2033.1000000000004, 2111.6000000000004],
      [8.321151, 61.545085, 1716.4000000000015, 2137.4000000000015],
      [8.310219, 61.546086, 1695.2000000000007, 1957],
      [8.296501, 61.548538, 1639.2000000000007, 1716.4000000000015],
      [8.284959, 61.549024, 1474.5, 1853],
      [8.281971, 61.548633, 2111.6000000000004, 1695.2000000000007],
      [8.277619, 61.548573, 2109.2000000000007, 1507.1000000000004],
      [8.272882, 61.547524, 2137.4000000000015, 1474.5],
      [8.265537, 61.547395, 1404, 1639.2000000000007],
      [8.255715, 61.547972, 1507.1000000000004, 1404],
      [8.24984, 61.548524, 1449, 1449],
      [8.247372, 61.548524, 1401, 1401]
    ]
  }
};
