export const isDev = process.env.NODE_ENV === 'development';
export const API_URL = process.env.API_URL;

export const MAPBOX_TOKEN = process.env.MAPBOX_API_KEY;

export default {
  API_URL,
  MAPBOX_TOKEN,
  isDev,
};
