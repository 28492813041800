import classNames from 'classnames';
import * as React from 'react';

import { EmptyImage } from './EmptyImage';
import { ImageHeader } from './ImageHeader';

interface IProps {
  alt?: string;
  className?: string;
  disableHover?: boolean;
  imgLabel?: string;
  imgSrc?: string;
  isTitleVisible?: boolean;
  onClick?: any;
  roundedCorners?: boolean;
  subTitle?: string;
  title?: string;
}

const defaultProps = {
  alt: '',
  className: '',
  disableHover: false,
  imgLabel: '',
  imgSrc: '',
  isTitleVisible: false,
  onClick: undefined,
  roundedCorners: false,
  subTitle: '',
  title: '',
};

const CardImage = ({
  alt,
  className,
  disableHover = false,
  imgLabel,
  imgSrc,
  isTitleVisible = false,
  onClick,
  roundedCorners = false,
  subTitle,
  title,
}: IProps) => {
  const classes = classNames('vx-card__image', className);

  if (imgSrc) {
    return (
      <ImageHeader
        alt={alt}
        className={classes}
        disableHover={disableHover}
        imgLabel={imgLabel}
        imgSrc={imgSrc}
        isTitleVisible={isTitleVisible}
        onClick={onClick}
        roundedCorners={roundedCorners}
        title={title}
        subTitle={subTitle}
      />
    );
  }

  return (
    <EmptyImage
      title={title}
      subTitle={subTitle}
      className={classes}
      roundedCorners={roundedCorners}
    />
  );
};

CardImage.defaultProps = defaultProps;

export default CardImage;
