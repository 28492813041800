import { useParams } from 'react-router-dom';
import React from 'react';
import { storyStyles } from './styles';
import { Leirvassbu } from '../../../assets/storytelling/stories/leirvassbu';
import { Jonndalen } from '../../../assets/storytelling/stories/jonndalen';
import { Story } from './story';

export const StoryContainer = () => {
  const { id } = useParams<{ id: string }>();

  let story;

  if (id.toLowerCase() === 'leirvassbu') {
    story = Leirvassbu;
  } else if (id.toLowerCase() === 'jonndalen') {
    story = Jonndalen;
  }

  return (
    <>
      {storyStyles}
      <Story {...story} />
    </>
  );
};
