import classNames from 'classnames';
import * as React from 'react';

import './CardBody.scss';

interface IProps {
  children: any;
  className?: string;
  style?: object;
}

const defaultProps = {
  className: '',
  style: undefined,
};

const CardBody = ({ className, style, children }: IProps) => {
  const styles = { ...style };
  const classes = classNames('vx-card__body', className);

  return (
    <div className={classes} style={styles}>
      {children}
    </div>
  );
};

export default CardBody;

CardBody.defaultProps = defaultProps;
