import { css } from 'emotion';
import { EColors } from '../../../../styles/theme/v2/colors';

const container = css`
  color: white;
`;

const form = css`
  width: 100%;
  height: 56rem;
`;

const formFooter = css`
  margin: 2rem 0;
  display: flex;
  justify-content: space-between;

  button {
    height: 36px;
    width: 49%;
  }
`;
const sectionLabel = css`
  height: 20px;
  line-height: 1.8rem;
  opacity: 0.25;
  font-size: 16px;
  color: #ffffff;
  margin: 2rem 0 0.5rem 0;
`;

const textArea = css`
  height: 56px;
  padding: 16px;
  font-family: 'Zilla Slab', serif;
  border: solid 0.6px ${EColors.grey_A01};
  background-color: ${EColors.grey_A002};
  color: white;
`;

export const formStyles = {
  container,
  form,
  formFooter,
  sectionLabel,
  textArea,
};
