import { CreateRouteInput } from '../../../../generated/models';

export const validateRouteForm = (route: Partial<CreateRouteInput>) => {
  const errors: any = {};
  if (!route.title) {
    errors.title = 'Must include title';
  }

  if (!route.activityType) {
    errors.activityType = 'Must select an activity type';
  }

  return errors;
};
