import * as React from 'react';
import { PureComponent } from 'react';
import { CanvasOverlay } from 'react-map-gl';

interface IProps {
  color?: 'blue' | 'grey' | 'red';
  lineWidth?: number;
  points: any[];
  renderWhileDragging?: boolean;
}

export default class PolylineOverlay extends PureComponent<IProps> {
  public _redraw({ width, height, ctx, isDragging, project }) {
    const {
      points,
      color,
      lineWidth = 2,
      renderWhileDragging = false,
    } = this.props;

    ctx.clearRect(0, 0, width, height);
    ctx.globalCompositeOperation = 'lighter';

    if ((renderWhileDragging || !isDragging) && points) {
      ctx.lineWidth = lineWidth;
      ctx.strokeStyle = color === 'grey' ? '#4a4a4a33' : color;
      ctx.beginPath();
      points.forEach(point => {
        const pixel = project([point[0], point[1]]);

        ctx.lineTo(pixel[0], pixel[1]);
      });
      ctx.stroke();
    }
  }

  public render() {
    return <CanvasOverlay redraw={this._redraw.bind(this)} />;
  }
}
