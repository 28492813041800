import { css } from 'emotion';
import { EColors } from '../../../../styles/theme/v2/colors';

const errorLabel = css`
  color: ${EColors.red_danger};
`;

export default {
  errorLabel,
};
