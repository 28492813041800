import React from 'react';

import { Link } from 'react-router-dom';
import SaveIcon from '../../../assets/icons/icon-save.svg';
import LoadingIcon from '../../../components/elements/LoadingIcon';
import ExploreCard from '../../../components/modules/Cards/ExploreCard';
import { ROUTES } from '../../../constants/routes';
import { useUserSavedRoutesQuery } from '../../../generated/models';
import './saved.scss';

const SavedRoutes = ({ onClose }) => {
  const trips = useUserSavedRoutesQuery();

  const { data, loading } = trips;

  if (loading) {
    return <LoadingIcon />;
  }

  if (!data || !data.me) {
    window.location.href = '/login';

    return null;
  }

  const routes = data?.me?.savedRoutesConnection?.edges.map(node => node.node);

  if (routes?.length > 0) {
    return (
      <div className="vx-layout">
        <h1>Saved Routes</h1>
        <div className="vx-row">
          {routes.map(route => (
            <div className="pr4" key={route.id}>
              <Link to={`${ROUTES.EXPLORE}?id=${route.id}`} onClick={onClose}>
                <ExploreCard route={route} size="sm" />
              </Link>
            </div>
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <div className="vx-layout">
        <h1>Saved Routes</h1>
        <span className="vx-routes__empty">
          You do not have any saved routes yet. Go to the{' '}
          <Link to={ROUTES.EXPLORE}>world map</Link>, and click on the{' '}
          <SaveIcon height="20px" width="20px" className="mt3" />
          icon to save routes for later.
        </span>
      </div>
    );
  }
};

export default SavedRoutes;
