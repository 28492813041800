import React, { useContext } from 'react';
import { IEditRouteProps } from './types';

import RouteBuilder from '../../../components/modules/Maps/RouteBuilder';
import {
  ExploreViewDocument,
  UpdateRouteInput,
  useGetRouteQuery,
  useUpdateRouteMutation,
} from '../../../generated/models';

import { ROUTES } from '../../../constants/routes';

import LoadingIcon from '../../../components/elements/LoadingIcon';
import { AuthContext } from '../../../providers/AuthProvider';
import './edit.scss';

const RouteBuilderEdit = ({ history, match }: IEditRouteProps) => {
  const id = match.params.id;
  const authContext = useContext(AuthContext);
  const { data, loading } = useGetRouteQuery({ variables: { id } });

  const [updateRouteMutation] = useUpdateRouteMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ExploreViewDocument, variables: { first: 100 } }],
  });

  if (loading) {
    return <LoadingIcon />;
  }

  if (!authContext.isLoggedIn()) {
    history.push(ROUTES.EXPLORE);
  }

  if (!data || !data.route) {
    return;
  }

  return (
    <RouteBuilder
      route={data.route}
      onSave={async (route: UpdateRouteInput) => {
        try {
          await updateRouteMutation({
            variables: { data: route, id: data.route.id },
          });

          history.push(`${ROUTES.EXPLORE}?id=${data.route.id}`);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      }}
    />
  );
};

export default RouteBuilderEdit;
