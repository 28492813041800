import React, { useContext } from 'react';
// import { animated, config, useSpring } from 'react-spring';
import CloseIcon from '../../../assets/icons/icon-close.svg';
import { NAV_LINKS } from '../../../constants/routes';
import { AuthContext } from '../../../providers/AuthProvider';
import NavAuthButtons from '../NavAuthButtons';
import NavLink from '../NavLink';
import NavLogo from '../NavLogo';
import NavUser from '../NavUser';
import SocialMediaLinks from '../SocialMediaLinks';

interface INavLaptopMenuProps {
  isVisible: boolean;
  onAvatarClick?: () => void;
  onClose: (value: boolean) => void;
}
const NavLaptopMenu = (props: INavLaptopMenuProps) => {
  const { onAvatarClick, isVisible, onClose } = props;

  const authContext = useContext(AuthContext);

  if (!isVisible) {
    return null;
  }

  return (
    <nav className="vx-nav">
      <NavLogo />
      <ul className="vx-nav__primary-links with-corners">
        <NavLink icon="MAP" link={NAV_LINKS.explore} />
        <NavLink icon="CHALLENGES" link={NAV_LINKS.guides} />
        <NavLink icon="SAVED" link={NAV_LINKS.saved} />
        <NavLink icon="ACHEIVED" link={NAV_LINKS.achieved} />
      </ul>
      <SocialMediaLinks className="vx-nav__social-links with-corners" />
      {authContext.isLoggedIn() ? (
        <NavUser onClick={onAvatarClick} />
      ) : (
        <NavAuthButtons />
      )}
      <CloseIcon
        className="vx-nav__menu vx-nav__close"
        height="20px"
        width="20px"
        onClick={onClose}
      />
    </nav>
  );
};

export default NavLaptopMenu;
