import * as React from 'react';

import classNames from 'classnames';

import { IStatCardProps } from './types';

import './StatCard.scss';

const StatCard = ({
  title,
  statValue,
  statUnits,
  className,
}: IStatCardProps) => (
  <div className={classNames('vx-card-stat', className)}>
    <div>
      <span className="vx-card-stat__value">{statValue}</span>{' '}
      <span className="vx-card-stat__unit">{statUnits}</span>
    </div>
    <div>
      <h4 className="vx-card-stat__label">{title}</h4>
    </div>
  </div>
);

export default StatCard;
