import classNames from 'classnames';
import * as React from 'react';

import './EmptyImage.scss';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const Logo = require('../../../../../assets/images/logo.png');

interface IProps {
  className?: string;
  imgSrc?: string;
  onClick?: any;
  roundedCorners?: boolean;
  style?: object;
  subTitle?: string;
  title?: string;
}

const defaultProps = {
  className: '',
  onClick: undefined,
  roundedCorners: false,
  style: undefined,
};

const EmptyImage = ({
  className,
  roundedCorners,
  style,
  title,
  imgSrc,
  subTitle,
  onClick,
}: IProps) => {
  const classes = classNames(
    roundedCorners
      ? 'vx-card__image-container--rounded'
      : 'vx-card__image-container--rectangle',
    'vx-card__emptyImage',
    className,
  );

  return (
    <div
      className={classes}
      style={style}
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyDown={onClick}
    >
      <img className="vx-card__logo" src={imgSrc || Logo} alt="vertrax logo" />
      {title && <h1 className="vx-card__title">{title}</h1>}
      {subTitle && <h1 className="vx-card__subTitle">{subTitle}</h1>}
    </div>
  );
};

EmptyImage.defaultProps = defaultProps;

export default EmptyImage;
