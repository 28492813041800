import * as React from 'react';
import { Link } from 'react-router-dom';
import { useDevice } from '../../../hooks/use-device';
import './NavLogo.scss';

const NavLogo = () => {
  const device = useDevice();

  return (
    <Link
      className={`vx-logo-container ${device.isDesktop && 'with-corners'}`}
      to="/"
    >
      <h1 className="vx-logo-text">Vertrax</h1>
    </Link>
  );
};

export default NavLogo;
