import React from 'react';
import { Link } from 'react-router-dom';

import LoadingIcon from '../../../../components/elements/LoadingIcon';
import ExploreCard from '../../../../components/modules/Cards/ExploreCard';
import { ROUTES } from '../../../../constants/routes';
import { useUserCreatedRoutesQuery } from '../../../../generated/models';
import { IProfileSavedTabProps } from '../saved/types';

const CreatedRoutes = ({ onClose }: IProfileSavedTabProps) => {
  const PAGE_SIZE = 21;
  const trips = useUserCreatedRoutesQuery({ variables: { first: PAGE_SIZE } });

  const { data, loading } = trips;

  if (loading) {
    return <LoadingIcon />;
  }

  if (!data || !data.me) {
    return null;
  }

  const routes = data?.me?.createdRoutesConnection?.edges.map(
    node => node.node,
  );

  if (routes.length > 0) {
    return (
      <div className="vx-profile__scroll-container">
        <div className="vx-row">
          {routes.map(route => (
            <div key={route.id} className="mr2 mb3 pointer">
              <Link to={`${ROUTES.EXPLORE}?id=${route.id}`} onClick={onClose}>
                <ExploreCard route={route} size="sm" />
              </Link>
            </div>
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <h2>Go out and adventure!</h2>
        <span>
          Click <Link to={ROUTES.CREATE_ROUTE}>here</Link> to upload a new trip
        </span>
      </div>
    );
  }
};

export default CreatedRoutes;
