import classNames from 'classnames';
import * as React from 'react';

import './CardHeader.scss';

interface IProps {
  borderBottom?: boolean;
  center?: boolean;
  children?: any;
  className?: string;
  style?: object;
}

const defaultProps = {
  borderBottom: false,
  center: false,
  children: undefined,
  className: '',
  style: undefined,
};

const CardHeader = ({
  style,
  children,
  className,
  borderBottom,
  center,
}: IProps) => {
  const classes = classNames('vx-card__header', className, {
    ['vx-card__header--borderBottom']: borderBottom,
    ['vx-card__header--center']: center,
  });

  return (
    <div className={classes} style={style}>
      {children}
    </div>
  );
};

CardHeader.defaultProps = defaultProps;

export default CardHeader;
