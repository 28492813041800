import cx from 'classnames';
import React from 'react';
import { NAV_LINKS } from '../../../constants/routes';
import NavLink from '../NavLink';
import './SocialMediaLinks.scss';

interface ISocialMediaLinksProps {
  className?: string;
}

const SocialMediaLinks = (props: ISocialMediaLinksProps) => {
  const { className } = props;

  return (
    <div className={cx('vx-social-media-links with-corners', className)}>
      <span>Share The Experience</span>
      <ul>
        <NavLink icon="FACEBOOK" link={NAV_LINKS.facebook} />
        <NavLink icon="TWITTER" link={NAV_LINKS.twitter} />
        <NavLink icon="INSTAGRAM" link={NAV_LINKS.instagram} />
      </ul>
    </div>
  );
};

export default SocialMediaLinks;
