import React from 'react';

import { Link } from 'react-router-dom';
import AddIcon from '../../../assets/icons/icon-plus.svg';
import LoadingIcon from '../../../components/elements/LoadingIcon';
import ExploreCard from '../../../components/modules/Cards/ExploreCard';
import { ROUTES } from '../../../constants/routes';
import { useUserCreatedRoutesQuery } from '../../../generated/models';

import './created.scss';

const Created = ({ onClose }) => {
  const PAGE_SIZE = 21;
  const trips = useUserCreatedRoutesQuery({ variables: { first: PAGE_SIZE } });

  const { data, loading } = trips;

  if (loading) {
    return <LoadingIcon />;
  }

  if (!data || !data.me) {
    window.location.href = '/login';

    return null;
  }

  const routes = data?.me?.createdRoutesConnection?.edges.map(
    node => node.node,
  );

  if (routes?.length > 0) {
    return (
      <div className="vx-layout">
        <h1>Created Routes</h1>
        <div className="vx-row">
          {routes.map(route => (
            <div key={route.id} className="mr2 mb3 pointer">
              <Link to={`${ROUTES.EXPLORE}?id=${route.id}`} onClick={onClose}>
                <ExploreCard route={route} size="sm" />
              </Link>
            </div>
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <div className="vx-layout">
        <h1>Created Routes</h1>
        <span className="vx-routes__empty">
          You do not have any created routes yet. Go to the{' '}
          <Link to={ROUTES.EXPLORE}>world map</Link>, and click on the icon to
          <AddIcon height="20px" width="20px" className="mt3" />
          save routes for later.
        </span>
      </div>
    );
  }
};

export default Created;
