export function convertToHoursAndMins(timeMins) {
  const hours = Math.floor(timeMins / 3600);
  const mins = hours % 60;

  return mins < 10 ? `${hours} h 0${mins}m` : `${hours} h ${mins}m`;
}

export const calculateDuration = timeInHours => {
  const hours = Math.floor(timeInHours);
  const minutes = (timeInHours - hours) * 60;
  const rminutes = Math.round(minutes);

  return rminutes < 10 ? `${hours}:0${rminutes}` : `${hours}:${rminutes}`;
};
