import React from 'react';
import { css, cx } from 'emotion';

import Logo from '../../../assets/icons/loader-logo.svg';

import { EColors } from '../../../styles/theme/v2/colors';
import { FontSizes } from '../../../styles/theme/v2/typography';

interface IProps {
  className?: string;
  message?: string;
}

const LoadingIcon = ({ className, message }: IProps) => {
  const animation = css`
    @keyframes pulsate {
      from {
        opacity: 1;
      }
      50% {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  `;

  return (
    <React.Fragment>
      <div
        className={css`
          text-align: center;
        `}
      >
        <Logo
          className={cx(
            css`
              position: relative;
              display: flex;
              justify-content: center;
              min-width: 50px;
              min-height: 50px;
              height: inherit;
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
              margin: 20rem auto 0 auto;
              animation: pulsate 2s linear infinite;
              ${animation};
            `,
            className,
          )}
        />
        <span
          className={css`
            display: block;
            width: 100%;
            text-align: center;
            font-size: ${FontSizes.pt16};
            color: ${EColors.grey_500};
            animation: pulsate 2s linear infinite;
            ${animation};
          `}
        >
          {message || 'Loading...'}
        </span>
      </div>
    </React.Fragment>
  );
};

export default LoadingIcon;
