import classNames from 'classnames';
import * as React from 'react';
import { Fragment } from 'react';

interface IProps {
  disabled?: boolean;
  errors?: any;
  infoText?: string;
  infoTextClassName?: string;
  labelText?: string;
  name: string;
  onChange: any;
  placeholder?: string;
  required?: boolean;
  textAreaClassName?: string;
  value?: any;
}

const defaultProps = {
  disabled: false,
  errors: [],
  infoText: undefined,
  infoTextClassName: undefined,
  labelText: undefined,
  placeholder: '',
  required: false,
  textAreaClassName: '',
  value: undefined,
};

const FormTextArea = ({
  disabled,
  errors,
  labelText,
  name,
  infoText,
  infoTextClassName,
  onChange,
  placeholder,
  required,
  textAreaClassName,
  value,
}: IProps) => (
  <Fragment>
    {labelText && (
      <label htmlFor={name} className="vx-form__label">
        {labelText}
      </label>
    )}
    <textarea
      className={textAreaClassName}
      disabled={disabled}
      id={name}
      name={name}
      onChange={onChange}
      placeholder={placeholder}
      required={required}
      value={value}
    />
    {infoText && (
      <span className={classNames('vx-form__label--info', infoTextClassName)}>
        {infoText}
      </span>
    )}
    {errors && errors[name] && (
      <span className="vx-form__label--error">{errors[name]}</span>
    )}
  </Fragment>
);

FormTextArea.defaultProps = defaultProps;

export default FormTextArea;
