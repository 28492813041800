export default {
  type: "Feature",
  properties: {},
  geometry: {
    type: "LineString",
    coordinates: [
      [8.24729, 61.548544, 1404.1000000000004, 1415.4000000000015],
      [8.246708, 61.547367, 1404.1000000000004, 1415.5],
      [8.245393, 61.54508, 1416, 1404.1000000000004],
      [8.245649, 61.543392, 1401.7000000000007, 1404.1000000000004],
      [8.247161, 61.541635, 1402.1000000000004, 1401.7000000000007],
      [8.250769, 61.5371, 1415.4000000000015, 1402.1000000000004],
      [8.252131, 61.530632, 1415.5, 1416],
      [8.250299, 61.527202, 1439.300000000001, 1436.6000000000004],
      [8.250438, 61.524385, 1436.6000000000004, 1439.300000000001],
      [8.251583, 61.523628, 1452.800000000001, 1452.800000000001],
      [8.252497, 61.522218, 1541.800000000001, 1484.300000000001],
      [8.254389, 61.520595, 1542.9000000000015, 1484.800000000001],
      [8.257357, 61.518861, 1553.800000000001, 1541.800000000001],
      [8.258706, 61.517073, 1484.300000000001, 1542.9000000000015],
      [8.259622, 61.514089, 1484.800000000001, 1553.800000000001],
      [8.26562, 61.507533, 1584.9000000000015, 1584.9000000000015],
      [8.264082, 61.503616, 1584.300000000001, 1584.300000000001],
      [8.264, 61.50324, 1748.5, 1626.800000000001],
      [8.263532, 61.503087, 1747.1000000000004, 1625.9000000000015],
      [8.262734, 61.503174, 1679.800000000001, 1679.800000000001],
      [8.261889, 61.503591, 1709.300000000001, 1709.300000000001],
      [8.26134, 61.504112, 1900.6000000000004, 1748.5],
      [8.260953, 61.504589, 1900.300000000001, 1747.1000000000004],
      [8.260669, 61.504999, 1625.9000000000015, 1900.6000000000004],
      [8.260969, 61.504584, 1626.800000000001, 1900.300000000001],
      [8.26134, 61.50408, 2000.2000000000007, 1977.5],
      [8.261924, 61.503645, 1998.7000000000007, 1976.6000000000004],
      [8.262724, 61.503215, 1988.7000000000007, 1988.7000000000007],
      [8.263474, 61.50307, 1987.4000000000015, 1987.4000000000015],
      [8.264034, 61.503274, 2028.4000000000015, 1998.7000000000007],
      [8.264054, 61.503681, 2029.2000000000007, 2000.2000000000007],
      [8.265658, 61.507564, 2041.7000000000007, 2028.4000000000015],
      [8.259597, 61.51415, 2041.7000000000007, 2029.2000000000007],
      [8.258702, 61.517149, 2015.800000000001, 2015.800000000001],
      [8.257409, 61.518932, 2015.800000000001, 2058.1000000000004],
      [8.254366, 61.520676, 2058.1000000000004, 2058.1000000000004],
      [8.252459, 61.522216, 2058.1000000000004, 2041.7000000000007],
      [8.25154, 61.523727, 1707.7000000000007, 2041.7000000000007],
      [8.250422, 61.524438, 1976.6000000000004, 2015.800000000001],
      [8.250257, 61.527222, 1977.5, 1707.7000000000007],
      [8.252079, 61.530641, 1679.5, 1553.2000000000007],
      [8.250725, 61.53711, 2066.4000000000015, 2066.4000000000015],
      [8.247098, 61.54178, 1436.5, 1679.5],
      [8.245669, 61.543511, 1440, 1452.5],
      [8.245406, 61.545108, 1452.5, 1415.5],
      [8.246643, 61.547402, 1553.2000000000007, 1436.5],
      [8.247251, 61.548544, 1415.5, 1440]
    ]
  }
};
