import {
  CreateFeaturedRegionInput,
  UpdateFeaturedRegionInput,
} from '../generated/models';

function updateRouteData(data) {
  const {
    title,
    description,
    activityType,
    featuredImage,
    isPublished,
    mountainName,
    approach,
  } = data;

  const routeData = {
    activityType,
    approach: approach || undefined,
    description,
    featuredImage: featuredImage ? featuredImage[0] : undefined,
    isPublished: isPublished || false,
    mountainName,
    title,
  };

  return routeData;
}

function createFeaturedRegionData(data) {
  const { name, description, activities, featuredImage, author } = data;
  const featuredRegionData: CreateFeaturedRegionInput = {
    activities:
      activities.length > 0 ? activities.map(activity => activity.id) : [],
    author: { id: author.id },
    description,
    featuredImage: featuredImage ? featuredImage[0] : undefined,
    name,
  };

  return featuredRegionData;
}

function updateFeaturedRegionData(data) {
  const { name, description, activities, featuredImage, author } = data;

  const featuredRegionData: UpdateFeaturedRegionInput = {
    activities: activities.map(activity => activity.id),
    author: { id: author.id },
    description,
    featuredImage: featuredImage ? featuredImage[0] : undefined,
    name,
  };

  return featuredRegionData;
}

export default {
  createFeaturedRegionData,
  updateFeaturedRegionData,
  updateRouteData,
};
