/*** General Variables ***/

export const AppHeights = {
  footer: '8rem',
  navbar: '7.3rem',
  submenu: '4.5rem',
};

export const ZIndex = {
  menu: 20,
  modal: 30,
  sticky: 10,
};

export const Scaling = {
  less: 'scale(0.5)',
  more: 'scale(2)',
  normal: 'scale(1.2)',
};

export const BorderRadius = {
  card: '0.6rem',
};

export const Shadows = {
  boldHover: '0 0 0.1px #9b9b9b, 0 0 0.1px #9b9b9b',
};
