import * as queryString from 'query-string';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import Button from '../../../components/elements/Button';
import { ButtonColor } from '../../../components/elements/Button/types';
import FormInput from '../../../components/elements/Form/FormInput';
import LoadingIcon from '../../../components/elements/LoadingIcon';
import { initialFormState } from '../../../components/modules/Forms/AuthForm/types';
import validation from '../../../components/modules/Forms/AuthForm/validation';
import { ROUTES } from '../../../constants/routes';
import {
  useConfirmUserInvitationMutation,
  useLoginMutation,
  useVerifyInvitationTokenMutation,
} from '../../../generated/models';
import { AuthContext } from '../../../providers/AuthProvider';
import auth from '../../../utils/auth';
import './confirmInvitation.scss';
import { IConfirmInvitation } from './types';

const ConfirmInvitation = (props: IConfirmInvitation) => {
  const { location, history } = props;

  const [token, setToken] = useState(undefined);
  const authContext = useContext(AuthContext);
  const [authForm, setAuthForm] = useState(initialFormState);
  const [errors, setErrors] = useState();
  const [isConfirming, setIsConfirming] = useState(false);

  const [
    verifyToken,
    {
      data: decodedTokenData,
      loading: tokenVerificationLoading,
      error: tokenVerificationError,
    },
  ] = useVerifyInvitationTokenMutation();
  // eslint-disable-next-line no-console
  console.log(tokenVerificationError);
  const [confirmInvitation] = useConfirmUserInvitationMutation({
    variables: {
      data: { name: authForm.name, newPassword: authForm.password, token },
    },
  });

  const [
    logIn,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { data, loading: authLoading, error: logInError },
  ] = useLoginMutation({
    variables: {
      email: authForm.email,
      password: authForm.password,
    },
  });

  useEffect(() => {
    const invitationToken = queryString.parse(location.search).token as string;
    setToken(invitationToken);
    verifyToken({ variables: { token: invitationToken } })
      .then((response: any) =>
        setAuthForm({
          ...authForm,
          email: response.data.verifyInvitationToken.email,
          name: response.data.verifyInvitationToken.name,
        }),
      )
      .catch(() => {
        // history.push('/invitation-expired');
      });
  }, []);

  const onSubmit = async () => {
    const formValidation = validation.validateRegister(authForm);

    if (formValidation.hasNoErrors) {
      setIsConfirming(true);
      setErrors(null);

      try {
        await confirmInvitation();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }

      try {
        const { data: loginTokens } = await logIn();
        // setLoading(true);
        auth.saveAuthTokens(
          loginTokens.login.accessToken,
          loginTokens.login.refreshToken,
        );

        const user = await authContext.fetchUser();

        if (user) {
          return history.push(ROUTES.EXPLORE);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }

      setIsConfirming(false);
    }

    setErrors(formValidation.errors);
  };

  if (tokenVerificationLoading) {
    return <LoadingIcon />;
  }

  return (
    <div className="vx-confirm-invitation">
      {!tokenVerificationLoading && decodedTokenData && (
        <Fragment>
          <h2>You&apos;ve been invited to become a Vertrax Guide!</h2>
          <form className="vx-form ">
            <FormInput
              labelText="Full Name"
              name="name"
              onChange={e => setAuthForm({ ...authForm, name: e.target.value })}
              placeholder="Johnny Otto"
              value={authForm.name}
            />
            <FormInput
              labelText="Password"
              type="password"
              name="password"
              onChange={e =>
                setAuthForm({ ...authForm, password: e.target.value })
              }
              placeholder="password"
              value={authForm.password}
              errors={errors}
            />
            <FormInput
              labelText="Re-enter password"
              type="password"
              name="passwordConfirmation"
              onChange={e =>
                setAuthForm({
                  ...authForm,
                  passwordConfirmation: e.target.value,
                })
              }
              placeholder="re-enter password"
              value={authForm.passwordConfirmation}
              errors={errors}
            />
            <Button
              className="vx-auth-form__action"
              type="button"
              disabled={isConfirming}
              onClick={onSubmit}
              color={ButtonColor.PRIMARY}
            >
              Confirm
            </Button>
          </form>
        </Fragment>
      )}
      {tokenVerificationError && (
        <Fragment>
          <h2>Oops the invitation link has expired..</h2>
          <span>Contact the invitation sender to resend the invitation.</span>
          <Button
            className="vx-confirm-invitation__button"
            type="button"
            onClick={() => history.push('/')}
            color={ButtonColor.PRIMARY}
          >
            Back to Vertrax.io
          </Button>
        </Fragment>
      )}
    </div>
  );
};

export default ConfirmInvitation;
