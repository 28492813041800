import React, { HTMLProps } from 'react';

export interface IRegionInfoProvider {
  country: string;
  county: string;
  onChange?: ({
    country,
    county,
  }: {
    country?: string;
    county?: string;
  }) => void;
}

const defaultValue: IRegionInfoProvider = {
  country: '',
  county: '',
  onChange: () => null,
};

export const RegionInfoContext = React.createContext<IRegionInfoProvider>(
  defaultValue,
);

export const RegionInfoProvider = (
  props: IRegionInfoProvider & HTMLProps<HTMLElement>,
) => {
  const { county, country, onChange, children } = props;

  return (
    <RegionInfoContext.Provider value={{ country, county, onChange }}>
      {children}
    </RegionInfoContext.Provider>
  );
};
