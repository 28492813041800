import React, { useEffect, useState } from 'react';

import mapboxgl from 'mapbox-gl';
import scrollama from 'scrollama';

import data from '../../../assets/storytelling/data';
import { IStory } from '../../../assets/storytelling/stories/types';
import { Chapter } from './chapter';

const layerTypes = {
  circle: ['circle-opacity', 'circle-stroke-opacity'],
  fill: ['fill-opacity'],
  'fill-extrusion': ['fill-extrusion-opacity'],
  line: ['line-opacity'],
  raster: ['raster-opacity'],
  symbol: ['icon-opacity', 'text-opacity'],
};

const alignments = {
  center: 'centered',
  left: 'lefty',
  right: 'righty',
};

const transformRequest = url => {
  const hasQuery = url.indexOf('?') !== -1;
  const suffix = hasQuery
    ? '&pluginName=journalismScrollytelling'
    : '?pluginName=journalismScrollytelling';

  return {
    url: url + suffix,
  };
};

interface IChapter {
  currentChapterID: string;
  description: string;
  id: string;
  image: string;
  location: any;
  onChapterEnter: any;
  onChapterExit: any;
  title: string;
}

export const Story = (props: IStory) => {
  const {
    chapters,
    style,
    accessToken,
    showMarkers,
    theme,
    subtitle,
    alignment,
    byline,
    title,
    footer,
  } = props;

  const [currentChapter, setCurrentChapter] = useState(chapters[0]);
  let mapContainer;

  useEffect(() => {
    const mapStart = chapters[0].location;

    mapboxgl.accessToken = accessToken;

    const map = new mapboxgl.Map({
      bearing: mapStart.bearing,
      center: mapStart.center,
      container: mapContainer,
      pitch: mapStart.pitch,
      style: style,
      transformRequest: transformRequest,
      zoom: mapStart.zoom,
    });

    map.on('load', () => {
      map.addSource('mapbox-dem', {
        maxzoom: 12,
        tileSize: 512,
        type: 'raster-dem',
        url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
      });
      // add the DEM source as a terrain layer with exaggerated height
      // @ts-ignore
      map.setTerrain({ exaggeration: 1.5, source: 'mapbox-dem' });

      // add a sky layer that will show when the map is highly pitched
      map.addLayer({
        id: 'sky',
        paint: {
          // @ts-ignore
          'sky-atmosphere-sun': [0.0, 0.0],
          'sky-atmosphere-sun-intensity': 15,
          'sky-type': 'atmosphere',
        },
        // @ts-ignore
        type: 'sky',
      });
    });

    const marker = new mapboxgl.Marker();
    if (showMarkers) {
      marker.setLngLat(mapStart.center).addTo(map);
    }

    function getLayerPaintType(layer) {
      const layerType = map.getLayer(layer).type;

      return layerTypes[layerType];
    }

    function setLayerOpacity(layer) {
      const paintProps = getLayerPaintType(layer.layer);
      paintProps.forEach(function(prop) {
        map.setPaintProperty(layer.layer, prop, layer.opacity);
      });
    }

    // instantiate the scrollama
    const scroller = scrollama();

    map.on('load', function() {
      Object.keys(data).forEach(key => {
        map.addLayer({
          id: key,
          paint: {
            'line-color': '#000',
            'line-opacity': 0,
            'line-width': 2,
          },
          source: {
            data: data[key],
            type: 'geojson',
          },
          type: 'line',
        });
      });

      // setup the instance, pass callback functions
      scroller
        .setup({
          offset: 0.5,
          progress: true,
          step: '.step',
        })
        .onStepEnter(response => {
          const chapter = chapters.find(
            chap => chap.id === response.element.id,
          );
          setCurrentChapter(chapter);
          map.flyTo(chapter.location);
          if (showMarkers) {
            marker.setLngLat(chapter.location.center);
          }
          if (chapter.onChapterEnter.length > 0) {
            chapter.onChapterEnter.forEach(setLayerOpacity);
          }
        })
        .onStepExit(response => {
          const chapter = chapters.find(
            chap => chap.id === response.element.id,
          );
          if (chapter.onChapterExit.length > 0) {
            chapter.onChapterExit.forEach(setLayerOpacity);
          }
        });
    });

    window.addEventListener('resize', scroller.resize);

    return () => {
      window.removeEventListener('resize', scroller.resize);
    };
  }, []);

  const currentChapterID = currentChapter.id;

  return (
    <>
      <div
        ref={el => (mapContainer = el)}
        className="fixed top right left bottom mapboxgl-map--story"
      />
      <div id="story">
        {title && (
          <div id="header" className={theme}>
            <h1>{title}</h1>
            {subtitle && <h2>{subtitle}</h2>}
            {byline && <p>{byline}</p>}
          </div>
        )}
        <div id="features" className={alignments[alignment]}>
          {chapters.map(chapter => (
            <Chapter
              key={chapter.id}
              theme={theme}
              {...chapter}
              currentChapterID={currentChapterID}
            />
          ))}
        </div>
        {footer && (
          <div id="footer" className={theme}>
            <p>{footer}</p>
          </div>
        )}
      </div>
    </>
  );
};
