import React, { HTMLProps } from 'react';

import { IFormSelectInlineLabelProps } from './types';

import FormErrorLabel from '../../FormErrorLabel';
import baseStyles from './styles';
import InlineLabel from '..';

const FormSelectInlineLabel = (
  props: IFormSelectInlineLabelProps & HTMLProps<HTMLSelectElement>,
) => {
  const {
    name,
    labelText,
    onChange,
    value,
    options,
    error,
    required,
    ...rest
  } = props;
  const { container, selectStyles } = baseStyles;

  return (
    <>
      <div className={container}>
        <select
          className={selectStyles}
          name={name}
          onChange={onChange}
          defaultValue={value ?? ''}
          required={required}
          {...rest}
        >
          {options.map(opt => (
            <option key={opt.label} value={opt.value}>
              {opt.label}
            </option>
          ))}
        </select>
        <InlineLabel name={name} required={required} labelText={labelText} />
      </div>
      {error && <FormErrorLabel title={error} />}
    </>
  );
};

export default FormSelectInlineLabel;
