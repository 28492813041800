import { Location } from 'history';
import * as React from 'react';
import { match, RouteProps, RouterProps } from 'react-router';
import { Link } from 'react-router-dom';

import Button from '../../components/elements/Button';

import { ROUTES } from '../../constants/routes';

import heroImg from '../../assets/images/home_hero-background.jpg';

import HomeArrowDown from '../../assets/icons/icon-home-down-logo.svg';
import HomeExplore from '../../assets/icons/icon-home-explore.svg';
import HomeKnow from '../../assets/icons/icon-home-know.svg';
import HomeTrack from '../../assets/icons/icon-track.svg';
import IosExplore from '../../assets/images/ios-explore.svg';
import IosDetails from '../../assets/images/ios-trip-details.svg';

import './landing.scss';

interface IHomeProps extends RouteProps {
  history?: History;
  location?: Location<any>;
  match?: match<any>;
  router?: RouterProps;
}

const Landing = () => {
  return (
    <div>
      <div className="vx-hero" style={{ backgroundImage: `url('${heroImg}'}` }}>
        <img src={heroImg} />
        <h1 className="vx-hero__title">
          A WORLD OF BACKCOUNTRY SKI CHALLENGES
        </h1>
        <div className="vx-hero__button">
          <div className="vx-hero__button--map">
            <Link to={ROUTES.EXPLORE}>
              <Button size="xl" color="white-line">
                Enter Map
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <div className="vx-home__down-icon--container">
        <HomeArrowDown
          className="vx-home__down-icon"
          height="175px"
          width="150px"
        />
      </div>

      <div className="vx-body">
        <div className="vx-row vx-home__section-header">
          <h1>Vertrax explore new ski tours</h1>
        </div>
        <div className="vx-row vx-home__features">
          <div className="vx-home__feature">
            <HomeTrack height="175px" width="150px" />
            <h3>Track your trips</h3>
          </div>
          <div className="vx-home__feature">
            <HomeExplore height="175px" width="150px" />
            <h3>Explore new Trips</h3>
          </div>
          <div className="vx-home__feature">
            <HomeKnow height="175px" width="150px" />
            <h3>Know the forecast</h3>
            <p className="text-muted">Coming soon</p>
          </div>
        </div>
        <div className="vx-home__information--container">
          <div className="vx-home__information--left">
            <IosDetails height="400px" />
          </div>
          <div className="vx-home__information--right">
            <h2>Track your trips</h2>
            <p className="mv4">
              From GPS watches, to the Vertrax iOS application, import your
              files from your device to gain insight on your last adventure
            </p>
            <div className="vx-home__information--button">
              <Link to={ROUTES.CREATE_ACTIVITY}>
                <Button>Upload Now</Button>
              </Link>
            </div>
          </div>
        </div>
        <div className="vx-home__information--container">
          <div className="vx-home__information--left">
            <IosExplore height="400px" />
          </div>
          <div className="vx-home__information--right">
            <h2>Explore new trips</h2>
            <p className="mv4">
              Let us help you find your next backcountry trip.
            </p>
            <div className="vx-home__information--button">
              <Link to={ROUTES.EXPLORE}>
                <Button>Explore now</Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
