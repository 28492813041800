import React, { useContext } from 'react';

import RouteBuilder from '../../../components/modules/Maps/RouteBuilder';
import {
  CreateRouteInput,
  ExploreViewDocument,
  useCreateRouteMutation,
} from '../../../generated/models';

import { ROUTES } from '../../../constants/routes';

import { AuthContext } from '../../../providers/AuthProvider';
import './create.scss';
import { ICreateRouteProps } from './types';

const RouteBuilderCreate = ({ history }: ICreateRouteProps) => {
  const authContext = useContext(AuthContext);
  const [createRouteMutation, { error }] = useCreateRouteMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ExploreViewDocument, variables: { first: 100 } }],
  });

  if (!authContext.isLoggedIn()) {
    history.push(ROUTES.EXPLORE);
  }

  return (
    <RouteBuilder
      onError={error}
      onSave={async (route: CreateRouteInput) => {
        try {
          await createRouteMutation({
            variables: {
              data: route,
            },
          }).then(data => {
            // Go to New Route after it is created
            return history.push(
              `${ROUTES.EXPLORE}?id=${data.data.createRoute.id}`,
            );
          });
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);
        }
      }}
    />
  );
};

export default RouteBuilderCreate;
