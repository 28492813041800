import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHoc from '@apollo/react-hoc';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type RequireFields<T, K extends keyof T> = { [X in Exclude<keyof T, K>]?: T[X] } & { [P in K]-?: NonNullable<T[P]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  URL: any;
  Upload: any;
};

export enum AccessRole {
  Admin = 'ADMIN',
  User = 'USER',
  Guide = 'GUIDE'
}

export type ActivitiesConnection = {
   __typename?: 'ActivitiesConnection';
  edges: Array<ActivityEdge>;
  totalCount: Scalars['Int'];
};

export type Activity = {
   __typename?: 'Activity';
  activityDate: Scalars['DateTime'];
  activityType: ActivityType;
  athlete: User;
  direction?: Maybe<Direction>;
  city?: Maybe<City>;
  country?: Maybe<Country>;
  county?: Maybe<County>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  featuredImage?: Maybe<Image>;
  id: Scalars['ID'];
  images?: Maybe<Array<Maybe<Image>>>;
  isPrivate: Scalars['Boolean'];
  marker: Marker;
  mountain?: Maybe<Mountain>;
  polyline?: Maybe<Polyline>;
  stats?: Maybe<ActivityStat>;
  streams?: Maybe<DataStream>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ActivityEdge = {
   __typename?: 'ActivityEdge';
  cursor: Scalars['String'];
  node: Activity;
};

export type ActivityFilterInput = {
  activityType?: Maybe<Array<ActivityType>>;
  countryCode?: Maybe<Scalars['String']>;
  countyId?: Maybe<Scalars['String']>;
  location?: Maybe<LocationInput>;
  isExplore?: Maybe<Scalars['Boolean']>;
  isSavedRoute?: Maybe<Scalars['Boolean']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
};

export type ActivityGetOneParams = {
  id?: Maybe<Scalars['ID']>;
};

export type ActivityStat = {
   __typename?: 'ActivityStat';
  elevationMax?: Maybe<Scalars['Float']>;
  elevationMin?: Maybe<Scalars['Float']>;
  totalAscent?: Maybe<Scalars['Float']>;
  totalDescent?: Maybe<Scalars['Float']>;
  totalDistance?: Maybe<Scalars['Float']>;
  totalDuration?: Maybe<Scalars['Float']>;
};

export enum ActivityType {
  BackcountrySkiing = 'BackcountrySkiing',
  Biking = 'Biking',
  Climbing = 'Climbing',
  CrossCountrySkiing = 'CrossCountrySkiing',
  Hiking = 'Hiking',
  Mountaineering = 'Mountaineering',
  Other = 'Other',
  ResortSkiing = 'ResortSkiing',
  Running = 'Running'
}

export type AllTimeStats = {
   __typename?: 'AllTimeStats';
  userId: Scalars['ID'];
  ascent: Scalars['Float'];
  descent: Scalars['Float'];
  distance: Scalars['Float'];
  numberOfTrips: Scalars['Int'];
};

export type AuthResult = {
   __typename?: 'AuthResult';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type AvyNorwayDetail = {
   __typename?: 'AvyNorwayDetail';
  regionId: Scalars['Int'];
  regionName: Scalars['String'];
  dangerLevel: Scalars['String'];
  validFrom: Scalars['DateTime'];
  validTo: Scalars['DateTime'];
  nextWarningTime: Scalars['DateTime'];
  publishTime: Scalars['DateTime'];
  mainText: Scalars['String'];
  previousWarningRegId?: Maybe<Scalars['String']>;
  dangerLevelName?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
  avalancheDanger?: Maybe<Scalars['String']>;
  emergencyWarning?: Maybe<Scalars['String']>;
  snowSurface?: Maybe<Scalars['String']>;
  currentWeaklayers?: Maybe<Scalars['String']>;
  latestAvalancheActivity?: Maybe<Scalars['String']>;
  latestObservations?: Maybe<Scalars['String']>;
  avalancheProblems?: Maybe<Array<Maybe<NorwayAvalancheProblem>>>;
};

export type AvyNorwayRegions = {
   __typename?: 'AvyNorwayRegions';
  regionId: Scalars['Int'];
  name: Scalars['String'];
};

export type AvyNorwaySimple = {
   __typename?: 'AvyNorwaySimple';
  regionId: Scalars['Int'];
  regionName: Scalars['String'];
  dangerLevel: Scalars['String'];
  validFrom: Scalars['DateTime'];
  validTo: Scalars['DateTime'];
  nextWarningTime: Scalars['DateTime'];
  publishTime: Scalars['DateTime'];
  mainText: Scalars['String'];
};

export type City = {
   __typename?: 'City';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type CityGetOneInput = {
  id?: Maybe<Scalars['ID']>;
};

export type ConfirmUserInvitationInput = {
  name: Scalars['String'];
  token: Scalars['String'];
  newPassword: Scalars['String'];
};

export type Continent = {
   __typename?: 'Continent';
  id: Scalars['ID'];
  code: Scalars['String'];
  name: Scalars['String'];
};

export type ContinentGetOneInput = {
  id?: Maybe<Scalars['ID']>;
  code?: Maybe<Scalars['String']>;
};

export type Country = {
   __typename?: 'Country';
  id: Scalars['ID'];
  code: Scalars['String'];
  name: Scalars['String'];
};

export type CountryFilterInput = {
  continent?: Maybe<ContinentGetOneInput>;
  hasActivities?: Maybe<Scalars['Boolean']>;
};

export type CountryGetOneInput = {
  id?: Maybe<Scalars['ID']>;
  code?: Maybe<Scalars['String']>;
};

export type County = {
   __typename?: 'County';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type CountyGetOneInput = {
  id?: Maybe<Scalars['ID']>;
};

export type CreateActivityInput = {
  activityType: ActivityType;
  description?: Maybe<Scalars['String']>;
  gpx?: Maybe<Scalars['String']>;
  gpxFile?: Maybe<Scalars['Upload']>;
  featuredImage?: Maybe<Scalars['Upload']>;
  images?: Maybe<Array<Maybe<Scalars['Upload']>>>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  title: Scalars['String'];
  countryName?: Maybe<Scalars['String']>;
  countyName?: Maybe<Scalars['String']>;
  mountainName?: Maybe<Scalars['String']>;
  approach?: Maybe<Direction>;
};

export type CreateFeaturedRegionInput = {
  activities?: Maybe<Array<Scalars['String']>>;
  author?: Maybe<UserGetOneParams>;
  description?: Maybe<Scalars['String']>;
  featuredImage?: Maybe<Scalars['Upload']>;
  images?: Maybe<Array<Scalars['Upload']>>;
  name: Scalars['String'];
};

export type CreateRouteInput = {
  activityType: ActivityType;
  geoJson: Scalars['String'];
  title: Scalars['String'];
  approach?: Maybe<Direction>;
  countryName?: Maybe<Scalars['String']>;
  countyName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  featuredImage?: Maybe<Scalars['Upload']>;
  isExploreRoute?: Maybe<Scalars['Boolean']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  mountainName?: Maybe<Scalars['String']>;
};

export type DataStream = {
   __typename?: 'DataStream';
  cadence?: Maybe<Array<Scalars['Float']>>;
  distance?: Maybe<Array<Scalars['Float']>>;
  elevation?: Maybe<Array<Scalars['Float']>>;
  heartRate?: Maybe<Array<Scalars['Int']>>;
  slope: Array<Scalars['Float']>;
  speed?: Maybe<Array<Scalars['Float']>>;
  temperature?: Maybe<Array<Scalars['Float']>>;
  time?: Maybe<Array<Scalars['String']>>;
};


export enum Direction {
  North = 'NORTH',
  NorthEast = 'NORTH_EAST',
  East = 'EAST',
  SouthEast = 'SOUTH_EAST',
  South = 'SOUTH',
  SouthWest = 'SOUTH_WEST',
  West = 'WEST',
  NorthWest = 'NORTH_WEST'
}

export type ExploreGeocode = {
   __typename?: 'ExploreGeocode';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
};

export type ExploreGeocodeInput = {
  lat: Scalars['Float'];
  lon: Scalars['Float'];
};

export type FeaturedRegion = {
   __typename?: 'FeaturedRegion';
  id: Scalars['String'];
  activities: Array<Activity>;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  author: User;
  featuredImage?: Maybe<Image>;
  images?: Maybe<Array<Image>>;
};

export type FeaturedRegionFilterInput = {
  countryCode?: Maybe<Scalars['String']>;
};

export type Image = {
   __typename?: 'Image';
  url: Scalars['URL'];
};

export type InviteUserInput = {
  email: Scalars['String'];
  invitedById: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type LocationInput = {
  distance?: Maybe<Scalars['Float']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type Marker = {
   __typename?: 'Marker';
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type Markers = {
   __typename?: 'Markers';
  geoJson: Scalars['String'];
};

export type Mountain = {
   __typename?: 'Mountain';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Mutation = {
   __typename?: 'Mutation';
  createActivity?: Maybe<Activity>;
  updateActivity?: Maybe<Activity>;
  signup: AuthResult;
  login: AuthResult;
  loginWithRefreshToken: AuthResult;
  createFeaturedRegion: FeaturedRegion;
  updateFeaturedRegion: FeaturedRegion;
  createRoute?: Maybe<Route>;
  updateRoute?: Maybe<Route>;
  pinRoute: Route;
  unpinRoute: Route;
  updateUser: User;
  uploadProfileImage: User;
  sendUserInvitation: User;
  confirmUserInvitation: User;
  verifyInvitationToken: User;
};


export type MutationCreateActivityArgs = {
  data: CreateActivityInput;
};


export type MutationUpdateActivityArgs = {
  id: Scalars['ID'];
  data?: Maybe<UpdateActivityInput>;
};


export type MutationSignupArgs = {
  name: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationLoginWithRefreshTokenArgs = {
  token: Scalars['String'];
};


export type MutationCreateFeaturedRegionArgs = {
  data: CreateFeaturedRegionInput;
};


export type MutationUpdateFeaturedRegionArgs = {
  id: Scalars['ID'];
  data?: Maybe<UpdateFeaturedRegionInput>;
};


export type MutationCreateRouteArgs = {
  data: CreateRouteInput;
};


export type MutationUpdateRouteArgs = {
  id: Scalars['ID'];
  data?: Maybe<UpdateRouteInput>;
};


export type MutationPinRouteArgs = {
  id: Scalars['ID'];
};


export type MutationUnpinRouteArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateUserArgs = {
  id: Scalars['ID'];
  input: UpdateUserInput;
};


export type MutationUploadProfileImageArgs = {
  file: Scalars['Upload'];
};


export type MutationSendUserInvitationArgs = {
  data: InviteUserInput;
};


export type MutationConfirmUserInvitationArgs = {
  data: ConfirmUserInvitationInput;
};


export type MutationVerifyInvitationTokenArgs = {
  token: Scalars['String'];
};

export type NorwayAvalancheProblem = {
   __typename?: 'NorwayAvalancheProblem';
  AvalancheProblemId?: Maybe<Scalars['Int']>;
  AvalancheExtId?: Maybe<Scalars['Int']>;
  AvalancheExtName?: Maybe<Scalars['String']>;
  AvalCauseId?: Maybe<Scalars['Int']>;
  AvalCauseName?: Maybe<Scalars['String']>;
  AvalProbabilityId?: Maybe<Scalars['Int']>;
  AvalProbabilityName?: Maybe<Scalars['String']>;
  AvalTriggerSimpleId?: Maybe<Scalars['Int']>;
  AvalTriggerSimpleName?: Maybe<Scalars['String']>;
  DestructiveSizeExtId?: Maybe<Scalars['Int']>;
  DestructiveSizeExtName?: Maybe<Scalars['String']>;
  AvalPropagationId?: Maybe<Scalars['Int']>;
  AvalPropagationName?: Maybe<Scalars['String']>;
  AvalancheAdvice?: Maybe<Scalars['String']>;
  AvalancheTypeId?: Maybe<Scalars['Int']>;
  AvalancheTypeName?: Maybe<Scalars['String']>;
  AvalancheProblemTypeId?: Maybe<Scalars['Int']>;
  AvalancheProblemTypeName?: Maybe<Scalars['String']>;
  ValidExpositions?: Maybe<Scalars['String']>;
  ExposedHeight1?: Maybe<Scalars['Int']>;
  ExposedHeight2?: Maybe<Scalars['Int']>;
  ExposedHeightFill?: Maybe<Scalars['Int']>;
};

export type PaginationInput = {
  first: Scalars['Int'];
  after?: Maybe<Scalars['ID']>;
};

export type Polyline = {
   __typename?: 'Polyline';
  coordinates?: Maybe<Array<Maybe<Array<Scalars['Float']>>>>;
  polyline: Scalars['String'];
  summaryPolyline: Scalars['String'];
};

export type Query = {
   __typename?: 'Query';
  activity?: Maybe<Activity>;
  activities: Array<Activity>;
  me: User;
  featuredRegions: Array<FeaturedRegion>;
  featuredRegion: FeaturedRegion;
  continents: Array<Continent>;
  countries: Array<Country>;
  cities: Array<City>;
  counties: Array<County>;
  exploreGeocode?: Maybe<ExploreGeocode>;
  markers: Markers;
  route?: Maybe<Route>;
  routes: Array<Route>;
  pinnedRoutesByUser: Array<Route>;
  user?: Maybe<User>;
  norwayAvalancheRegions: Array<AvyNorwayRegions>;
  norwayAvalancheByRegionSimple: Array<AvyNorwaySimple>;
  norwayAvalancheByRegionDetail: Array<AvyNorwayDetail>;
};


export type QueryActivityArgs = {
  id: Scalars['ID'];
};


export type QueryActivitiesArgs = {
  filter?: Maybe<ActivityFilterInput>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
};


export type QueryFeaturedRegionsArgs = {
  filter?: Maybe<FeaturedRegionFilterInput>;
  pagination?: Maybe<PaginationInput>;
};


export type QueryFeaturedRegionArgs = {
  id: Scalars['ID'];
};


export type QueryCountriesArgs = {
  filter?: Maybe<CountryFilterInput>;
};


export type QueryCitiesArgs = {
  country?: Maybe<CountryGetOneInput>;
};


export type QueryCountiesArgs = {
  country?: Maybe<CountryGetOneInput>;
};


export type QueryExploreGeocodeArgs = {
  data?: Maybe<ExploreGeocodeInput>;
};


export type QueryMarkersArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
};


export type QueryRouteArgs = {
  id: Scalars['ID'];
};


export type QueryRoutesArgs = {
  filter?: Maybe<RoutesFilterInput>;
  pagination?: Maybe<PaginationInput>;
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryNorwayAvalancheByRegionSimpleArgs = {
  regionId: Scalars['Int'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
};


export type QueryNorwayAvalancheByRegionDetailArgs = {
  regionId: Scalars['Int'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
};

export type Route = {
   __typename?: 'Route';
  id: Scalars['ID'];
  activityType: ActivityType;
  geoJson: Scalars['String'];
  title: Scalars['String'];
  marker: Marker;
  approach?: Maybe<Direction>;
  mountains?: Maybe<Array<Maybe<Mountain>>>;
  country?: Maybe<Country>;
  county?: Maybe<County>;
  city?: Maybe<City>;
  featuredImage?: Maybe<Image>;
  images?: Maybe<Array<Maybe<Image>>>;
  previewText?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isPublished: Scalars['Boolean'];
  createdBy: User;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isPinnedBy: Scalars['Boolean'];
  distance?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  elevation?: Maybe<Scalars['Float']>;
  difficulty?: Maybe<Scalars['Int']>;
};

export type RouteEdge = {
   __typename?: 'RouteEdge';
  cursor: Scalars['String'];
  node: Route;
};

export type RoutesConnection = {
   __typename?: 'RoutesConnection';
  edges: Array<RouteEdge>;
  totalCount: Scalars['Int'];
};

export type RoutesFilterInput = {
  location?: Maybe<LocationInput>;
};

export type TripStats = {
   __typename?: 'TripStats';
  mostAscent: Scalars['Float'];
  mostDescent: Scalars['Float'];
  longestDistance: Scalars['Float'];
  mostRuns?: Maybe<Scalars['Float']>;
};

export type UpdateActivityInput = {
  activityType?: Maybe<ActivityType>;
  approach?: Maybe<Direction>;
  countryName?: Maybe<Scalars['String']>;
  countyName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  featuredImage?: Maybe<Scalars['Upload']>;
  images?: Maybe<Array<Maybe<Scalars['Upload']>>>;
  isExploreRoute?: Maybe<Scalars['Boolean']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  mountainName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type UpdateFeaturedRegionInput = {
  activities?: Maybe<Array<Scalars['String']>>;
  author?: Maybe<UserGetOneParams>;
  description?: Maybe<Scalars['String']>;
  featuredImage?: Maybe<Scalars['Upload']>;
  images?: Maybe<Array<Scalars['Upload']>>;
  name?: Maybe<Scalars['String']>;
};

export type UpdateRouteInput = {
  activityType?: Maybe<ActivityType>;
  geoJson?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  approach?: Maybe<Direction>;
  countryName?: Maybe<Scalars['String']>;
  countyName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  featuredImage?: Maybe<Scalars['Upload']>;
  isExploreRoute?: Maybe<Scalars['Boolean']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  mountainName?: Maybe<Scalars['String']>;
};

export type UpdateUserInput = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  profileImageFilename?: Maybe<Scalars['String']>;
  invitedUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  remainingInvitations?: Maybe<Scalars['Int']>;
};



export type User = {
   __typename?: 'User';
  /** @deprecated Use paginate for pagination */
  activitiesConnection: ActivitiesConnection;
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  id: Scalars['ID'];
  invitationConfirmedAt?: Maybe<Scalars['DateTime']>;
  invitationSentAt?: Maybe<Scalars['DateTime']>;
  isPrivate: Scalars['Boolean'];
  name: Scalars['String'];
  profileImage: Image;
  remainingInvitations?: Maybe<Scalars['Int']>;
  savedRoutesConnection: RoutesConnection;
  createdRoutesConnection: RoutesConnection;
  stats?: Maybe<UserStats>;
  type: AccessRole;
  unconfirmedEmail?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  invitedUsers?: Maybe<Array<Maybe<User>>>;
  invitedByUser?: Maybe<User>;
};


export type UserActivitiesConnectionArgs = {
  filter?: Maybe<UserActivityFilterInput>;
  paginate?: Maybe<PaginationInput>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
};


export type UserSavedRoutesConnectionArgs = {
  paginate?: Maybe<PaginationInput>;
};


export type UserCreatedRoutesConnectionArgs = {
  paginate?: Maybe<PaginationInput>;
};

export type UserActivityFilterInput = {
  isRoute?: Maybe<Scalars['Boolean']>;
  isCompleted?: Maybe<Scalars['Boolean']>;
};

export type UserGetOneParams = {
  id?: Maybe<Scalars['ID']>;
};

export type UserStats = {
   __typename?: 'UserStats';
  allTimeStats: AllTimeStats;
  tripStats: TripStats;
};

export type CreateActivityMutationVariables = {
  data: CreateActivityInput;
};


export type CreateActivityMutation = (
  { __typename?: 'Mutation' }
  & { createActivity?: Maybe<(
    { __typename?: 'Activity' }
    & Pick<Activity, 'id'>
  )> }
);

export type UpdateActivityMutationVariables = {
  id: Scalars['ID'];
  data: UpdateActivityInput;
};


export type UpdateActivityMutation = (
  { __typename?: 'Mutation' }
  & { updateActivity?: Maybe<(
    { __typename?: 'Activity' }
    & Pick<Activity, 'id' | 'title' | 'activityType' | 'isPrivate'>
  )> }
);

export type CurrentUserQueryVariables = {};


export type CurrentUserQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & CurrentUserFragment
  ) }
);

export type CurrentUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'email' | 'name' | 'type'>
  & { profileImage: (
    { __typename?: 'Image' }
    & Pick<Image, 'url'>
  ) }
);

export type UserWithStatsQueryVariables = {};


export type UserWithStatsQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { stats?: Maybe<(
      { __typename?: 'UserStats' }
      & { allTimeStats: (
        { __typename?: 'AllTimeStats' }
        & Pick<AllTimeStats, 'ascent' | 'descent' | 'distance' | 'numberOfTrips'>
      ), tripStats: (
        { __typename?: 'TripStats' }
        & Pick<TripStats, 'mostAscent' | 'mostDescent' | 'longestDistance'>
      ) }
    )> }
  ) }
);

export type MarkersQueryVariables = {};


export type MarkersQuery = (
  { __typename?: 'Query' }
  & { markers: (
    { __typename?: 'Markers' }
    & Pick<Markers, 'geoJson'>
  ) }
);

export type ActivityCardDataFragment = (
  { __typename?: 'Activity' }
  & Pick<Activity, 'id' | 'title' | 'activityType'>
  & { featuredImage?: Maybe<(
    { __typename?: 'Image' }
    & Pick<Image, 'url'>
  )>, county?: Maybe<(
    { __typename?: 'County' }
    & Pick<County, 'id' | 'name'>
  )>, country?: Maybe<(
    { __typename?: 'Country' }
    & Pick<Country, 'id' | 'name'>
  )>, polyline?: Maybe<(
    { __typename?: 'Polyline' }
    & Pick<Polyline, 'coordinates'>
  )>, stats?: Maybe<(
    { __typename?: 'ActivityStat' }
    & Pick<ActivityStat, 'totalDuration' | 'totalAscent' | 'totalDistance'>
  )> }
);

export type ExploreCardFragment = (
  { __typename?: 'Route' }
  & Pick<Route, 'id' | 'title' | 'description' | 'activityType' | 'approach' | 'geoJson' | 'distance' | 'elevation' | 'duration' | 'isPinnedBy'>
  & { featuredImage?: Maybe<(
    { __typename?: 'Image' }
    & Pick<Image, 'url'>
  )>, country?: Maybe<(
    { __typename?: 'Country' }
    & Pick<Country, 'name'>
  )>, county?: Maybe<(
    { __typename?: 'County' }
    & Pick<County, 'name'>
  )>, mountains?: Maybe<Array<Maybe<(
    { __typename?: 'Mountain' }
    & Pick<Mountain, 'name'>
  )>>> }
);

export type PinRouteMutationVariables = {
  id: Scalars['ID'];
};


export type PinRouteMutation = (
  { __typename?: 'Mutation' }
  & { pinRoute: (
    { __typename?: 'Route' }
    & ExploreCardFragment
  ) }
);

export type UnPinRouteMutationVariables = {
  id: Scalars['ID'];
};


export type UnPinRouteMutation = (
  { __typename?: 'Mutation' }
  & { unpinRoute: (
    { __typename?: 'Route' }
    & ExploreCardFragment
  ) }
);

export type FeaturedRegionFormDataFragment = (
  { __typename?: 'FeaturedRegion' }
  & Pick<FeaturedRegion, 'name' | 'description'>
  & { featuredImage?: Maybe<(
    { __typename?: 'Image' }
    & Pick<Image, 'url'>
  )>, author: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ), activities: Array<(
    { __typename?: 'Activity' }
    & Pick<Activity, 'id' | 'title'>
  )> }
);

export type ExploreGeocodeQueryVariables = {
  lat: Scalars['Float'];
  lon: Scalars['Float'];
};


export type ExploreGeocodeQuery = (
  { __typename?: 'Query' }
  & { exploreGeocode?: Maybe<(
    { __typename?: 'ExploreGeocode' }
    & Pick<ExploreGeocode, 'country' | 'county' | 'city'>
  )> }
);

export type ActivityDetailsViewQueryVariables = {
  id: Scalars['ID'];
};


export type ActivityDetailsViewQuery = (
  { __typename?: 'Query' }
  & { activity?: Maybe<(
    { __typename?: 'Activity' }
    & Pick<Activity, 'id' | 'title' | 'activityType' | 'description' | 'isPrivate'>
    & { featuredImage?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'url'>
    )>, polyline?: Maybe<(
      { __typename?: 'Polyline' }
      & Pick<Polyline, 'coordinates' | 'polyline'>
    )>, streams?: Maybe<(
      { __typename?: 'DataStream' }
      & Pick<DataStream, 'elevation' | 'temperature' | 'time' | 'distance'>
    )>, stats?: Maybe<(
      { __typename?: 'ActivityStat' }
      & Pick<ActivityStat, 'totalDuration' | 'totalAscent' | 'totalDistance'>
    )>, marker: (
      { __typename?: 'Marker' }
      & Pick<Marker, 'lat' | 'lng'>
    ) }
  )> }
);

export type ActivitiesIndexViewQueryVariables = {
  filter?: Maybe<ActivityFilterInput>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
};


export type ActivitiesIndexViewQuery = (
  { __typename?: 'Query' }
  & { activities: Array<(
    { __typename?: 'Activity' }
    & ActivityCardDataFragment
  )> }
);

export type VerifyInvitationTokenMutationVariables = {
  token: Scalars['String'];
};


export type VerifyInvitationTokenMutation = (
  { __typename?: 'Mutation' }
  & { verifyInvitationToken: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'email'>
  ) }
);

export type ConfirmUserInvitationMutationVariables = {
  data: ConfirmUserInvitationInput;
};


export type ConfirmUserInvitationMutation = (
  { __typename?: 'Mutation' }
  & { confirmUserInvitation: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'email'>
  ) }
);

export type LoginMutationVariables = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'AuthResult' }
    & Pick<AuthResult, 'accessToken' | 'refreshToken'>
  ) }
);

export type LoginWithRefreshTokenMutationVariables = {
  token: Scalars['String'];
};


export type LoginWithRefreshTokenMutation = (
  { __typename?: 'Mutation' }
  & { loginWithRefreshToken: (
    { __typename?: 'AuthResult' }
    & Pick<AuthResult, 'accessToken' | 'refreshToken'>
  ) }
);

export type SignupMutationVariables = {
  name: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
};


export type SignupMutation = (
  { __typename?: 'Mutation' }
  & { signup: (
    { __typename?: 'AuthResult' }
    & Pick<AuthResult, 'accessToken' | 'refreshToken'>
  ) }
);

export type ExploreViewQueryVariables = {
  first: Scalars['Int'];
  after?: Maybe<Scalars['ID']>;
};


export type ExploreViewQuery = (
  { __typename?: 'Query' }
  & { routes: Array<(
    { __typename?: 'Route' }
    & ExploreRouteFragment
  )> }
);

export type FilterExploreRoutesQueryVariables = {
  first: Scalars['Int'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};


export type FilterExploreRoutesQuery = (
  { __typename?: 'Query' }
  & { routes: Array<(
    { __typename?: 'Route' }
    & ExploreRouteFragment
  )> }
);

export type ExploreRouteFragment = (
  { __typename?: 'Route' }
  & { marker: (
    { __typename?: 'Marker' }
    & Pick<Marker, 'lat' | 'lng'>
  ) }
  & ExploreCardFragment
);

export type UserCompletedTripsQueryVariables = {
  first: Scalars['Int'];
  after?: Maybe<Scalars['ID']>;
};


export type UserCompletedTripsQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { activitiesConnection: (
      { __typename?: 'ActivitiesConnection' }
      & { edges: Array<(
        { __typename?: 'ActivityEdge' }
        & { node: (
          { __typename?: 'Activity' }
          & ActivityCardDataFragment
        ) }
      )> }
    ) }
  ) }
);

export type UserCreatedRoutesQueryVariables = {
  first: Scalars['Int'];
  after?: Maybe<Scalars['ID']>;
};


export type UserCreatedRoutesQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { createdRoutesConnection: (
      { __typename?: 'RoutesConnection' }
      & { edges: Array<(
        { __typename?: 'RouteEdge' }
        & { node: (
          { __typename?: 'Route' }
          & ExploreCardFragment
        ) }
      )> }
    ) }
  ) }
);

export type UserSavedRoutesQueryVariables = {};


export type UserSavedRoutesQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { savedRoutesConnection: (
      { __typename?: 'RoutesConnection' }
      & { edges: Array<(
        { __typename?: 'RouteEdge' }
        & { node: (
          { __typename?: 'Route' }
          & ExploreCardFragment
        ) }
      )> }
    ) }
  ) }
);

export type InviteUserMutationVariables = {
  data: InviteUserInput;
};


export type InviteUserMutation = (
  { __typename?: 'Mutation' }
  & { sendUserInvitation: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name'>
  ) }
);

export type InvitedUsersQueryVariables = {};


export type InvitedUsersQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { invitedUsers?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email'>
    )>>> }
  ) }
);

export type CreateRouteMutationVariables = {
  data: CreateRouteInput;
};


export type CreateRouteMutation = (
  { __typename?: 'Mutation' }
  & { createRoute?: Maybe<(
    { __typename?: 'Route' }
    & Pick<Route, 'id'>
  )> }
);

export type GetRouteQueryVariables = {
  id: Scalars['ID'];
};


export type GetRouteQuery = (
  { __typename?: 'Query' }
  & { route?: Maybe<(
    { __typename?: 'Route' }
    & Pick<Route, 'id' | 'title' | 'description' | 'activityType' | 'geoJson' | 'isPublished' | 'approach'>
    & { featuredImage?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'url'>
    )>, marker: (
      { __typename?: 'Marker' }
      & Pick<Marker, 'lat' | 'lng'>
    ), mountains?: Maybe<Array<Maybe<(
      { __typename?: 'Mountain' }
      & Pick<Mountain, 'name'>
    )>>> }
  )> }
);

export type UpdateRouteMutationVariables = {
  id: Scalars['ID'];
  data: UpdateRouteInput;
};


export type UpdateRouteMutation = (
  { __typename?: 'Mutation' }
  & { updateRoute?: Maybe<(
    { __typename?: 'Route' }
    & Pick<Route, 'id'>
  )> }
);

export type RouteDetailsViewQueryVariables = {
  id: Scalars['ID'];
};


export type RouteDetailsViewQuery = (
  { __typename?: 'Query' }
  & { route?: Maybe<(
    { __typename?: 'Route' }
    & Pick<Route, 'id' | 'title' | 'activityType' | 'description' | 'geoJson' | 'approach' | 'isPublished' | 'distance' | 'duration' | 'elevation' | 'difficulty'>
    & { marker: (
      { __typename?: 'Marker' }
      & Pick<Marker, 'lat' | 'lng'>
    ), mountains?: Maybe<Array<Maybe<(
      { __typename?: 'Mountain' }
      & Pick<Mountain, 'name'>
    )>>>, country?: Maybe<(
      { __typename?: 'Country' }
      & Pick<Country, 'id' | 'name'>
    )>, county?: Maybe<(
      { __typename?: 'County' }
      & Pick<County, 'id' | 'name'>
    )>, city?: Maybe<(
      { __typename?: 'City' }
      & Pick<City, 'id' | 'name'>
    )>, featuredImage?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'url'>
    )> }
  )> }
);



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type StitchingResolver<TResult, TParent, TContext, TArgs> = {
  fragment: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | StitchingResolver<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterator<TResult> | Promise<AsyncIterator<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type isTypeOfResolverFn<T = {}> = (obj: T, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  Query: ResolverTypeWrapper<{}>,
  ID: ResolverTypeWrapper<Scalars['ID']>,
  Activity: ResolverTypeWrapper<Activity>,
  DateTime: ResolverTypeWrapper<Scalars['DateTime']>,
  ActivityType: ActivityType,
  User: ResolverTypeWrapper<User>,
  UserActivityFilterInput: UserActivityFilterInput,
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>,
  PaginationInput: PaginationInput,
  Int: ResolverTypeWrapper<Scalars['Int']>,
  String: ResolverTypeWrapper<Scalars['String']>,
  ActivitiesConnection: ResolverTypeWrapper<ActivitiesConnection>,
  ActivityEdge: ResolverTypeWrapper<ActivityEdge>,
  Image: ResolverTypeWrapper<Image>,
  URL: ResolverTypeWrapper<Scalars['URL']>,
  RoutesConnection: ResolverTypeWrapper<RoutesConnection>,
  RouteEdge: ResolverTypeWrapper<RouteEdge>,
  Route: ResolverTypeWrapper<Route>,
  Marker: ResolverTypeWrapper<Marker>,
  Float: ResolverTypeWrapper<Scalars['Float']>,
  Direction: Direction,
  Mountain: ResolverTypeWrapper<Mountain>,
  Country: ResolverTypeWrapper<Country>,
  County: ResolverTypeWrapper<County>,
  City: ResolverTypeWrapper<City>,
  UserStats: ResolverTypeWrapper<UserStats>,
  AllTimeStats: ResolverTypeWrapper<AllTimeStats>,
  TripStats: ResolverTypeWrapper<TripStats>,
  AccessRole: AccessRole,
  Polyline: ResolverTypeWrapper<Polyline>,
  ActivityStat: ResolverTypeWrapper<ActivityStat>,
  DataStream: ResolverTypeWrapper<DataStream>,
  ActivityFilterInput: ActivityFilterInput,
  LocationInput: LocationInput,
  FeaturedRegionFilterInput: FeaturedRegionFilterInput,
  FeaturedRegion: ResolverTypeWrapper<FeaturedRegion>,
  Continent: ResolverTypeWrapper<Continent>,
  CountryFilterInput: CountryFilterInput,
  ContinentGetOneInput: ContinentGetOneInput,
  CountryGetOneInput: CountryGetOneInput,
  ExploreGeocodeInput: ExploreGeocodeInput,
  ExploreGeocode: ResolverTypeWrapper<ExploreGeocode>,
  Markers: ResolverTypeWrapper<Markers>,
  RoutesFilterInput: RoutesFilterInput,
  AvyNorwayRegions: ResolverTypeWrapper<AvyNorwayRegions>,
  AvyNorwaySimple: ResolverTypeWrapper<AvyNorwaySimple>,
  AvyNorwayDetail: ResolverTypeWrapper<AvyNorwayDetail>,
  NorwayAvalancheProblem: ResolverTypeWrapper<NorwayAvalancheProblem>,
  Mutation: ResolverTypeWrapper<{}>,
  CreateActivityInput: CreateActivityInput,
  Upload: ResolverTypeWrapper<Scalars['Upload']>,
  UpdateActivityInput: UpdateActivityInput,
  AuthResult: ResolverTypeWrapper<AuthResult>,
  CreateFeaturedRegionInput: CreateFeaturedRegionInput,
  UserGetOneParams: UserGetOneParams,
  UpdateFeaturedRegionInput: UpdateFeaturedRegionInput,
  CreateRouteInput: CreateRouteInput,
  UpdateRouteInput: UpdateRouteInput,
  UpdateUserInput: UpdateUserInput,
  InviteUserInput: InviteUserInput,
  ConfirmUserInvitationInput: ConfirmUserInvitationInput,
  ActivityGetOneParams: ActivityGetOneParams,
  CityGetOneInput: CityGetOneInput,
  CountyGetOneInput: CountyGetOneInput,
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  Query: {},
  ID: Scalars['ID'],
  Activity: Activity,
  DateTime: Scalars['DateTime'],
  ActivityType: ActivityType,
  User: User,
  UserActivityFilterInput: UserActivityFilterInput,
  Boolean: Scalars['Boolean'],
  PaginationInput: PaginationInput,
  Int: Scalars['Int'],
  String: Scalars['String'],
  ActivitiesConnection: ActivitiesConnection,
  ActivityEdge: ActivityEdge,
  Image: Image,
  URL: Scalars['URL'],
  RoutesConnection: RoutesConnection,
  RouteEdge: RouteEdge,
  Route: Route,
  Marker: Marker,
  Float: Scalars['Float'],
  Direction: Direction,
  Mountain: Mountain,
  Country: Country,
  County: County,
  City: City,
  UserStats: UserStats,
  AllTimeStats: AllTimeStats,
  TripStats: TripStats,
  AccessRole: AccessRole,
  Polyline: Polyline,
  ActivityStat: ActivityStat,
  DataStream: DataStream,
  ActivityFilterInput: ActivityFilterInput,
  LocationInput: LocationInput,
  FeaturedRegionFilterInput: FeaturedRegionFilterInput,
  FeaturedRegion: FeaturedRegion,
  Continent: Continent,
  CountryFilterInput: CountryFilterInput,
  ContinentGetOneInput: ContinentGetOneInput,
  CountryGetOneInput: CountryGetOneInput,
  ExploreGeocodeInput: ExploreGeocodeInput,
  ExploreGeocode: ExploreGeocode,
  Markers: Markers,
  RoutesFilterInput: RoutesFilterInput,
  AvyNorwayRegions: AvyNorwayRegions,
  AvyNorwaySimple: AvyNorwaySimple,
  AvyNorwayDetail: AvyNorwayDetail,
  NorwayAvalancheProblem: NorwayAvalancheProblem,
  Mutation: {},
  CreateActivityInput: CreateActivityInput,
  Upload: Scalars['Upload'],
  UpdateActivityInput: UpdateActivityInput,
  AuthResult: AuthResult,
  CreateFeaturedRegionInput: CreateFeaturedRegionInput,
  UserGetOneParams: UserGetOneParams,
  UpdateFeaturedRegionInput: UpdateFeaturedRegionInput,
  CreateRouteInput: CreateRouteInput,
  UpdateRouteInput: UpdateRouteInput,
  UpdateUserInput: UpdateUserInput,
  InviteUserInput: InviteUserInput,
  ConfirmUserInvitationInput: ConfirmUserInvitationInput,
  ActivityGetOneParams: ActivityGetOneParams,
  CityGetOneInput: CityGetOneInput,
  CountyGetOneInput: CountyGetOneInput,
};

export type ActivitiesConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActivitiesConnection'] = ResolversParentTypes['ActivitiesConnection']> = {
  edges?: Resolver<Array<ResolversTypes['ActivityEdge']>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  __isTypeOf?: isTypeOfResolverFn<ParentType>,
};

export type ActivityResolvers<ContextType = any, ParentType extends ResolversParentTypes['Activity'] = ResolversParentTypes['Activity']> = {
  activityDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>,
  activityType?: Resolver<ResolversTypes['ActivityType'], ParentType, ContextType>,
  athlete?: Resolver<ResolversTypes['User'], ParentType, ContextType>,
  direction?: Resolver<Maybe<ResolversTypes['Direction']>, ParentType, ContextType>,
  city?: Resolver<Maybe<ResolversTypes['City']>, ParentType, ContextType>,
  country?: Resolver<Maybe<ResolversTypes['Country']>, ParentType, ContextType>,
  county?: Resolver<Maybe<ResolversTypes['County']>, ParentType, ContextType>,
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>,
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  featuredImage?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>,
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  images?: Resolver<Maybe<Array<Maybe<ResolversTypes['Image']>>>, ParentType, ContextType>,
  isPrivate?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
  marker?: Resolver<ResolversTypes['Marker'], ParentType, ContextType>,
  mountain?: Resolver<Maybe<ResolversTypes['Mountain']>, ParentType, ContextType>,
  polyline?: Resolver<Maybe<ResolversTypes['Polyline']>, ParentType, ContextType>,
  stats?: Resolver<Maybe<ResolversTypes['ActivityStat']>, ParentType, ContextType>,
  streams?: Resolver<Maybe<ResolversTypes['DataStream']>, ParentType, ContextType>,
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>,
  __isTypeOf?: isTypeOfResolverFn<ParentType>,
};

export type ActivityEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActivityEdge'] = ResolversParentTypes['ActivityEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  node?: Resolver<ResolversTypes['Activity'], ParentType, ContextType>,
  __isTypeOf?: isTypeOfResolverFn<ParentType>,
};

export type ActivityStatResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActivityStat'] = ResolversParentTypes['ActivityStat']> = {
  elevationMax?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>,
  elevationMin?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>,
  totalAscent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>,
  totalDescent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>,
  totalDistance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>,
  totalDuration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>,
  __isTypeOf?: isTypeOfResolverFn<ParentType>,
};

export type AllTimeStatsResolvers<ContextType = any, ParentType extends ResolversParentTypes['AllTimeStats'] = ResolversParentTypes['AllTimeStats']> = {
  userId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  ascent?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
  descent?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
  distance?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
  numberOfTrips?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  __isTypeOf?: isTypeOfResolverFn<ParentType>,
};

export type AuthResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['AuthResult'] = ResolversParentTypes['AuthResult']> = {
  accessToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  refreshToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  __isTypeOf?: isTypeOfResolverFn<ParentType>,
};

export type AvyNorwayDetailResolvers<ContextType = any, ParentType extends ResolversParentTypes['AvyNorwayDetail'] = ResolversParentTypes['AvyNorwayDetail']> = {
  regionId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  regionName?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  dangerLevel?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  validFrom?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>,
  validTo?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>,
  nextWarningTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>,
  publishTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>,
  mainText?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  previousWarningRegId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  dangerLevelName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  author?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  avalancheDanger?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  emergencyWarning?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  snowSurface?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  currentWeaklayers?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  latestAvalancheActivity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  latestObservations?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  avalancheProblems?: Resolver<Maybe<Array<Maybe<ResolversTypes['NorwayAvalancheProblem']>>>, ParentType, ContextType>,
  __isTypeOf?: isTypeOfResolverFn<ParentType>,
};

export type AvyNorwayRegionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['AvyNorwayRegions'] = ResolversParentTypes['AvyNorwayRegions']> = {
  regionId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  __isTypeOf?: isTypeOfResolverFn<ParentType>,
};

export type AvyNorwaySimpleResolvers<ContextType = any, ParentType extends ResolversParentTypes['AvyNorwaySimple'] = ResolversParentTypes['AvyNorwaySimple']> = {
  regionId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  regionName?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  dangerLevel?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  validFrom?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>,
  validTo?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>,
  nextWarningTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>,
  publishTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>,
  mainText?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  __isTypeOf?: isTypeOfResolverFn<ParentType>,
};

export type CityResolvers<ContextType = any, ParentType extends ResolversParentTypes['City'] = ResolversParentTypes['City']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  __isTypeOf?: isTypeOfResolverFn<ParentType>,
};

export type ContinentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Continent'] = ResolversParentTypes['Continent']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  __isTypeOf?: isTypeOfResolverFn<ParentType>,
};

export type CountryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Country'] = ResolversParentTypes['Country']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  __isTypeOf?: isTypeOfResolverFn<ParentType>,
};

export type CountyResolvers<ContextType = any, ParentType extends ResolversParentTypes['County'] = ResolversParentTypes['County']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  __isTypeOf?: isTypeOfResolverFn<ParentType>,
};

export type DataStreamResolvers<ContextType = any, ParentType extends ResolversParentTypes['DataStream'] = ResolversParentTypes['DataStream']> = {
  cadence?: Resolver<Maybe<Array<ResolversTypes['Float']>>, ParentType, ContextType>,
  distance?: Resolver<Maybe<Array<ResolversTypes['Float']>>, ParentType, ContextType>,
  elevation?: Resolver<Maybe<Array<ResolversTypes['Float']>>, ParentType, ContextType>,
  heartRate?: Resolver<Maybe<Array<ResolversTypes['Int']>>, ParentType, ContextType>,
  slope?: Resolver<Array<ResolversTypes['Float']>, ParentType, ContextType>,
  speed?: Resolver<Maybe<Array<ResolversTypes['Float']>>, ParentType, ContextType>,
  temperature?: Resolver<Maybe<Array<ResolversTypes['Float']>>, ParentType, ContextType>,
  time?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>,
  __isTypeOf?: isTypeOfResolverFn<ParentType>,
};

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime'
}

export type ExploreGeocodeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExploreGeocode'] = ResolversParentTypes['ExploreGeocode']> = {
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  county?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  __isTypeOf?: isTypeOfResolverFn<ParentType>,
};

export type FeaturedRegionResolvers<ContextType = any, ParentType extends ResolversParentTypes['FeaturedRegion'] = ResolversParentTypes['FeaturedRegion']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  activities?: Resolver<Array<ResolversTypes['Activity']>, ParentType, ContextType>,
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  author?: Resolver<ResolversTypes['User'], ParentType, ContextType>,
  featuredImage?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>,
  images?: Resolver<Maybe<Array<ResolversTypes['Image']>>, ParentType, ContextType>,
  __isTypeOf?: isTypeOfResolverFn<ParentType>,
};

export type ImageResolvers<ContextType = any, ParentType extends ResolversParentTypes['Image'] = ResolversParentTypes['Image']> = {
  url?: Resolver<ResolversTypes['URL'], ParentType, ContextType>,
  __isTypeOf?: isTypeOfResolverFn<ParentType>,
};

export type MarkerResolvers<ContextType = any, ParentType extends ResolversParentTypes['Marker'] = ResolversParentTypes['Marker']> = {
  lat?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
  lng?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
  __isTypeOf?: isTypeOfResolverFn<ParentType>,
};

export type MarkersResolvers<ContextType = any, ParentType extends ResolversParentTypes['Markers'] = ResolversParentTypes['Markers']> = {
  geoJson?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  __isTypeOf?: isTypeOfResolverFn<ParentType>,
};

export type MountainResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mountain'] = ResolversParentTypes['Mountain']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  __isTypeOf?: isTypeOfResolverFn<ParentType>,
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  createActivity?: Resolver<Maybe<ResolversTypes['Activity']>, ParentType, ContextType, RequireFields<MutationCreateActivityArgs, 'data'>>,
  updateActivity?: Resolver<Maybe<ResolversTypes['Activity']>, ParentType, ContextType, RequireFields<MutationUpdateActivityArgs, 'id'>>,
  signup?: Resolver<ResolversTypes['AuthResult'], ParentType, ContextType, RequireFields<MutationSignupArgs, 'name' | 'email' | 'password'>>,
  login?: Resolver<ResolversTypes['AuthResult'], ParentType, ContextType, RequireFields<MutationLoginArgs, 'email' | 'password'>>,
  loginWithRefreshToken?: Resolver<ResolversTypes['AuthResult'], ParentType, ContextType, RequireFields<MutationLoginWithRefreshTokenArgs, 'token'>>,
  createFeaturedRegion?: Resolver<ResolversTypes['FeaturedRegion'], ParentType, ContextType, RequireFields<MutationCreateFeaturedRegionArgs, 'data'>>,
  updateFeaturedRegion?: Resolver<ResolversTypes['FeaturedRegion'], ParentType, ContextType, RequireFields<MutationUpdateFeaturedRegionArgs, 'id'>>,
  createRoute?: Resolver<Maybe<ResolversTypes['Route']>, ParentType, ContextType, RequireFields<MutationCreateRouteArgs, 'data'>>,
  updateRoute?: Resolver<Maybe<ResolversTypes['Route']>, ParentType, ContextType, RequireFields<MutationUpdateRouteArgs, 'id'>>,
  pinRoute?: Resolver<ResolversTypes['Route'], ParentType, ContextType, RequireFields<MutationPinRouteArgs, 'id'>>,
  unpinRoute?: Resolver<ResolversTypes['Route'], ParentType, ContextType, RequireFields<MutationUnpinRouteArgs, 'id'>>,
  updateUser?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationUpdateUserArgs, 'id' | 'input'>>,
  uploadProfileImage?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationUploadProfileImageArgs, 'file'>>,
  sendUserInvitation?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationSendUserInvitationArgs, 'data'>>,
  confirmUserInvitation?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationConfirmUserInvitationArgs, 'data'>>,
  verifyInvitationToken?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationVerifyInvitationTokenArgs, 'token'>>,
};

export type NorwayAvalancheProblemResolvers<ContextType = any, ParentType extends ResolversParentTypes['NorwayAvalancheProblem'] = ResolversParentTypes['NorwayAvalancheProblem']> = {
  AvalancheProblemId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>,
  AvalancheExtId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>,
  AvalancheExtName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  AvalCauseId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>,
  AvalCauseName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  AvalProbabilityId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>,
  AvalProbabilityName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  AvalTriggerSimpleId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>,
  AvalTriggerSimpleName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  DestructiveSizeExtId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>,
  DestructiveSizeExtName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  AvalPropagationId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>,
  AvalPropagationName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  AvalancheAdvice?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  AvalancheTypeId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>,
  AvalancheTypeName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  AvalancheProblemTypeId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>,
  AvalancheProblemTypeName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  ValidExpositions?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  ExposedHeight1?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>,
  ExposedHeight2?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>,
  ExposedHeightFill?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>,
  __isTypeOf?: isTypeOfResolverFn<ParentType>,
};

export type PolylineResolvers<ContextType = any, ParentType extends ResolversParentTypes['Polyline'] = ResolversParentTypes['Polyline']> = {
  coordinates?: Resolver<Maybe<Array<Maybe<Array<ResolversTypes['Float']>>>>, ParentType, ContextType>,
  polyline?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  summaryPolyline?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  __isTypeOf?: isTypeOfResolverFn<ParentType>,
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  activity?: Resolver<Maybe<ResolversTypes['Activity']>, ParentType, ContextType, RequireFields<QueryActivityArgs, 'id'>>,
  activities?: Resolver<Array<ResolversTypes['Activity']>, ParentType, ContextType, RequireFields<QueryActivitiesArgs, never>>,
  me?: Resolver<ResolversTypes['User'], ParentType, ContextType>,
  featuredRegions?: Resolver<Array<ResolversTypes['FeaturedRegion']>, ParentType, ContextType, RequireFields<QueryFeaturedRegionsArgs, never>>,
  featuredRegion?: Resolver<ResolversTypes['FeaturedRegion'], ParentType, ContextType, RequireFields<QueryFeaturedRegionArgs, 'id'>>,
  continents?: Resolver<Array<ResolversTypes['Continent']>, ParentType, ContextType>,
  countries?: Resolver<Array<ResolversTypes['Country']>, ParentType, ContextType, RequireFields<QueryCountriesArgs, never>>,
  cities?: Resolver<Array<ResolversTypes['City']>, ParentType, ContextType, RequireFields<QueryCitiesArgs, never>>,
  counties?: Resolver<Array<ResolversTypes['County']>, ParentType, ContextType, RequireFields<QueryCountiesArgs, never>>,
  exploreGeocode?: Resolver<Maybe<ResolversTypes['ExploreGeocode']>, ParentType, ContextType, RequireFields<QueryExploreGeocodeArgs, never>>,
  markers?: Resolver<ResolversTypes['Markers'], ParentType, ContextType, RequireFields<QueryMarkersArgs, never>>,
  route?: Resolver<Maybe<ResolversTypes['Route']>, ParentType, ContextType, RequireFields<QueryRouteArgs, 'id'>>,
  routes?: Resolver<Array<ResolversTypes['Route']>, ParentType, ContextType, RequireFields<QueryRoutesArgs, never>>,
  pinnedRoutesByUser?: Resolver<Array<ResolversTypes['Route']>, ParentType, ContextType>,
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<QueryUserArgs, 'id'>>,
  norwayAvalancheRegions?: Resolver<Array<ResolversTypes['AvyNorwayRegions']>, ParentType, ContextType>,
  norwayAvalancheByRegionSimple?: Resolver<Array<ResolversTypes['AvyNorwaySimple']>, ParentType, ContextType, RequireFields<QueryNorwayAvalancheByRegionSimpleArgs, 'regionId' | 'startDate' | 'endDate'>>,
  norwayAvalancheByRegionDetail?: Resolver<Array<ResolversTypes['AvyNorwayDetail']>, ParentType, ContextType, RequireFields<QueryNorwayAvalancheByRegionDetailArgs, 'regionId' | 'startDate' | 'endDate'>>,
};

export type RouteResolvers<ContextType = any, ParentType extends ResolversParentTypes['Route'] = ResolversParentTypes['Route']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  activityType?: Resolver<ResolversTypes['ActivityType'], ParentType, ContextType>,
  geoJson?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  marker?: Resolver<ResolversTypes['Marker'], ParentType, ContextType>,
  approach?: Resolver<Maybe<ResolversTypes['Direction']>, ParentType, ContextType>,
  mountains?: Resolver<Maybe<Array<Maybe<ResolversTypes['Mountain']>>>, ParentType, ContextType>,
  country?: Resolver<Maybe<ResolversTypes['Country']>, ParentType, ContextType>,
  county?: Resolver<Maybe<ResolversTypes['County']>, ParentType, ContextType>,
  city?: Resolver<Maybe<ResolversTypes['City']>, ParentType, ContextType>,
  featuredImage?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>,
  images?: Resolver<Maybe<Array<Maybe<ResolversTypes['Image']>>>, ParentType, ContextType>,
  previewText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  isPublished?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
  createdBy?: Resolver<ResolversTypes['User'], ParentType, ContextType>,
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>,
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>,
  isPinnedBy?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
  distance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>,
  duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>,
  elevation?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>,
  difficulty?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>,
  __isTypeOf?: isTypeOfResolverFn<ParentType>,
};

export type RouteEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['RouteEdge'] = ResolversParentTypes['RouteEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  node?: Resolver<ResolversTypes['Route'], ParentType, ContextType>,
  __isTypeOf?: isTypeOfResolverFn<ParentType>,
};

export type RoutesConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['RoutesConnection'] = ResolversParentTypes['RoutesConnection']> = {
  edges?: Resolver<Array<ResolversTypes['RouteEdge']>, ParentType, ContextType>,
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>,
  __isTypeOf?: isTypeOfResolverFn<ParentType>,
};

export type TripStatsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TripStats'] = ResolversParentTypes['TripStats']> = {
  mostAscent?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
  mostDescent?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
  longestDistance?: Resolver<ResolversTypes['Float'], ParentType, ContextType>,
  mostRuns?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>,
  __isTypeOf?: isTypeOfResolverFn<ParentType>,
};

export interface UploadScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Upload'], any> {
  name: 'Upload'
}

export interface UrlScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['URL'], any> {
  name: 'URL'
}

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = {
  activitiesConnection?: Resolver<ResolversTypes['ActivitiesConnection'], ParentType, ContextType, RequireFields<UserActivitiesConnectionArgs, never>>,
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>,
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>,
  invitationConfirmedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>,
  invitationSentAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>,
  isPrivate?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>,
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>,
  profileImage?: Resolver<ResolversTypes['Image'], ParentType, ContextType>,
  remainingInvitations?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>,
  savedRoutesConnection?: Resolver<ResolversTypes['RoutesConnection'], ParentType, ContextType, RequireFields<UserSavedRoutesConnectionArgs, never>>,
  createdRoutesConnection?: Resolver<ResolversTypes['RoutesConnection'], ParentType, ContextType, RequireFields<UserCreatedRoutesConnectionArgs, never>>,
  stats?: Resolver<Maybe<ResolversTypes['UserStats']>, ParentType, ContextType>,
  type?: Resolver<ResolversTypes['AccessRole'], ParentType, ContextType>,
  unconfirmedEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>,
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>,
  invitedUsers?: Resolver<Maybe<Array<Maybe<ResolversTypes['User']>>>, ParentType, ContextType>,
  invitedByUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>,
  __isTypeOf?: isTypeOfResolverFn<ParentType>,
};

export type UserStatsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserStats'] = ResolversParentTypes['UserStats']> = {
  allTimeStats?: Resolver<ResolversTypes['AllTimeStats'], ParentType, ContextType>,
  tripStats?: Resolver<ResolversTypes['TripStats'], ParentType, ContextType>,
  __isTypeOf?: isTypeOfResolverFn<ParentType>,
};

export type Resolvers<ContextType = any> = {
  ActivitiesConnection?: ActivitiesConnectionResolvers<ContextType>,
  Activity?: ActivityResolvers<ContextType>,
  ActivityEdge?: ActivityEdgeResolvers<ContextType>,
  ActivityStat?: ActivityStatResolvers<ContextType>,
  AllTimeStats?: AllTimeStatsResolvers<ContextType>,
  AuthResult?: AuthResultResolvers<ContextType>,
  AvyNorwayDetail?: AvyNorwayDetailResolvers<ContextType>,
  AvyNorwayRegions?: AvyNorwayRegionsResolvers<ContextType>,
  AvyNorwaySimple?: AvyNorwaySimpleResolvers<ContextType>,
  City?: CityResolvers<ContextType>,
  Continent?: ContinentResolvers<ContextType>,
  Country?: CountryResolvers<ContextType>,
  County?: CountyResolvers<ContextType>,
  DataStream?: DataStreamResolvers<ContextType>,
  DateTime?: GraphQLScalarType,
  ExploreGeocode?: ExploreGeocodeResolvers<ContextType>,
  FeaturedRegion?: FeaturedRegionResolvers<ContextType>,
  Image?: ImageResolvers<ContextType>,
  Marker?: MarkerResolvers<ContextType>,
  Markers?: MarkersResolvers<ContextType>,
  Mountain?: MountainResolvers<ContextType>,
  Mutation?: MutationResolvers<ContextType>,
  NorwayAvalancheProblem?: NorwayAvalancheProblemResolvers<ContextType>,
  Polyline?: PolylineResolvers<ContextType>,
  Query?: QueryResolvers<ContextType>,
  Route?: RouteResolvers<ContextType>,
  RouteEdge?: RouteEdgeResolvers<ContextType>,
  RoutesConnection?: RoutesConnectionResolvers<ContextType>,
  TripStats?: TripStatsResolvers<ContextType>,
  Upload?: GraphQLScalarType,
  URL?: GraphQLScalarType,
  User?: UserResolvers<ContextType>,
  UserStats?: UserStatsResolvers<ContextType>,
};


/**
 * @deprecated
 * Use "Resolvers" root object instead. If you wish to get "IResolvers", add "typesPrefix: I" to your config.
*/
export type IResolvers<ContextType = any> = Resolvers<ContextType>;

export const CurrentUserFragmentDoc = gql`
    fragment CurrentUser on User {
  id
  email
  name
  profileImage {
    url
  }
  type
}
    `;
export const ActivityCardDataFragmentDoc = gql`
    fragment ActivityCardData on Activity {
  id
  featuredImage {
    url
  }
  county {
    id
    name
  }
  country {
    id
    name
  }
  title
  activityType
  polyline {
    coordinates
  }
  stats {
    totalDuration
    totalAscent
    totalDistance
  }
}
    `;
export const FeaturedRegionFormDataFragmentDoc = gql`
    fragment FeaturedRegionFormData on FeaturedRegion {
  name
  description
  featuredImage {
    url
  }
  author {
    id
  }
  activities {
    id
    title
  }
}
    `;
export const ExploreCardFragmentDoc = gql`
    fragment ExploreCard on Route {
  id
  title
  description
  activityType
  featuredImage {
    url
  }
  country {
    name
  }
  county {
    name
  }
  mountains {
    name
  }
  approach
  geoJson
  distance
  elevation
  duration
  isPinnedBy
}
    `;
export const ExploreRouteFragmentDoc = gql`
    fragment ExploreRoute on Route {
  ...ExploreCard
  marker {
    lat
    lng
  }
}
    ${ExploreCardFragmentDoc}`;
export const CreateActivityDocument = gql`
    mutation createActivity($data: CreateActivityInput!) {
  createActivity(data: $data) {
    id
  }
}
    `;
export type CreateActivityMutationFn = ApolloReactCommon.MutationFunction<CreateActivityMutation, CreateActivityMutationVariables>;
export type CreateActivityComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateActivityMutation, CreateActivityMutationVariables>, 'mutation'>;

    export const CreateActivityComponent = (props: CreateActivityComponentProps) => (
      <ApolloReactComponents.Mutation<CreateActivityMutation, CreateActivityMutationVariables> mutation={CreateActivityDocument} {...props} />
    );
    
export type CreateActivityProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateActivityMutation, CreateActivityMutationVariables> & TChildProps;
export function withCreateActivity<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateActivityMutation,
  CreateActivityMutationVariables,
  CreateActivityProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateActivityMutation, CreateActivityMutationVariables, CreateActivityProps<TChildProps>>(CreateActivityDocument, {
      alias: 'createActivity',
      ...operationOptions
    });
};

/**
 * __useCreateActivityMutation__
 *
 * To run a mutation, you first call `useCreateActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createActivityMutation, { data, loading, error }] = useCreateActivityMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateActivityMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateActivityMutation, CreateActivityMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateActivityMutation, CreateActivityMutationVariables>(CreateActivityDocument, baseOptions);
      }
export type CreateActivityMutationHookResult = ReturnType<typeof useCreateActivityMutation>;
export type CreateActivityMutationResult = ApolloReactCommon.MutationResult<CreateActivityMutation>;
export type CreateActivityMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateActivityMutation, CreateActivityMutationVariables>;
export const UpdateActivityDocument = gql`
    mutation updateActivity($id: ID!, $data: UpdateActivityInput!) {
  updateActivity(id: $id, data: $data) {
    id
    title
    activityType
    isPrivate
  }
}
    `;
export type UpdateActivityMutationFn = ApolloReactCommon.MutationFunction<UpdateActivityMutation, UpdateActivityMutationVariables>;
export type UpdateActivityComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateActivityMutation, UpdateActivityMutationVariables>, 'mutation'>;

    export const UpdateActivityComponent = (props: UpdateActivityComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateActivityMutation, UpdateActivityMutationVariables> mutation={UpdateActivityDocument} {...props} />
    );
    
export type UpdateActivityProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateActivityMutation, UpdateActivityMutationVariables> & TChildProps;
export function withUpdateActivity<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateActivityMutation,
  UpdateActivityMutationVariables,
  UpdateActivityProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateActivityMutation, UpdateActivityMutationVariables, UpdateActivityProps<TChildProps>>(UpdateActivityDocument, {
      alias: 'updateActivity',
      ...operationOptions
    });
};

/**
 * __useUpdateActivityMutation__
 *
 * To run a mutation, you first call `useUpdateActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActivityMutation, { data, loading, error }] = useUpdateActivityMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateActivityMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateActivityMutation, UpdateActivityMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateActivityMutation, UpdateActivityMutationVariables>(UpdateActivityDocument, baseOptions);
      }
export type UpdateActivityMutationHookResult = ReturnType<typeof useUpdateActivityMutation>;
export type UpdateActivityMutationResult = ApolloReactCommon.MutationResult<UpdateActivityMutation>;
export type UpdateActivityMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateActivityMutation, UpdateActivityMutationVariables>;
export const CurrentUserDocument = gql`
    query currentUser {
  me {
    ...CurrentUser
  }
}
    ${CurrentUserFragmentDoc}`;
export type CurrentUserComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CurrentUserQuery, CurrentUserQueryVariables>, 'query'>;

    export const CurrentUserComponent = (props: CurrentUserComponentProps) => (
      <ApolloReactComponents.Query<CurrentUserQuery, CurrentUserQueryVariables> query={CurrentUserDocument} {...props} />
    );
    
export type CurrentUserProps<TChildProps = {}> = ApolloReactHoc.DataProps<CurrentUserQuery, CurrentUserQueryVariables> & TChildProps;
export function withCurrentUser<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CurrentUserQuery,
  CurrentUserQueryVariables,
  CurrentUserProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, CurrentUserQuery, CurrentUserQueryVariables, CurrentUserProps<TChildProps>>(CurrentUserDocument, {
      alias: 'currentUser',
      ...operationOptions
    });
};

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        return ApolloReactHooks.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, baseOptions);
      }
export function useCurrentUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, baseOptions);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = ApolloReactCommon.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export const UserWithStatsDocument = gql`
    query userWithStats {
  me {
    id
    stats {
      allTimeStats {
        ascent
        descent
        distance
        numberOfTrips
      }
      tripStats {
        mostAscent
        mostDescent
        longestDistance
      }
    }
  }
}
    `;
export type UserWithStatsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserWithStatsQuery, UserWithStatsQueryVariables>, 'query'>;

    export const UserWithStatsComponent = (props: UserWithStatsComponentProps) => (
      <ApolloReactComponents.Query<UserWithStatsQuery, UserWithStatsQueryVariables> query={UserWithStatsDocument} {...props} />
    );
    
export type UserWithStatsProps<TChildProps = {}> = ApolloReactHoc.DataProps<UserWithStatsQuery, UserWithStatsQueryVariables> & TChildProps;
export function withUserWithStats<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UserWithStatsQuery,
  UserWithStatsQueryVariables,
  UserWithStatsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, UserWithStatsQuery, UserWithStatsQueryVariables, UserWithStatsProps<TChildProps>>(UserWithStatsDocument, {
      alias: 'userWithStats',
      ...operationOptions
    });
};

/**
 * __useUserWithStatsQuery__
 *
 * To run a query within a React component, call `useUserWithStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserWithStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserWithStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserWithStatsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserWithStatsQuery, UserWithStatsQueryVariables>) {
        return ApolloReactHooks.useQuery<UserWithStatsQuery, UserWithStatsQueryVariables>(UserWithStatsDocument, baseOptions);
      }
export function useUserWithStatsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserWithStatsQuery, UserWithStatsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserWithStatsQuery, UserWithStatsQueryVariables>(UserWithStatsDocument, baseOptions);
        }
export type UserWithStatsQueryHookResult = ReturnType<typeof useUserWithStatsQuery>;
export type UserWithStatsLazyQueryHookResult = ReturnType<typeof useUserWithStatsLazyQuery>;
export type UserWithStatsQueryResult = ApolloReactCommon.QueryResult<UserWithStatsQuery, UserWithStatsQueryVariables>;
export const MarkersDocument = gql`
    query markers {
  markers {
    geoJson
  }
}
    `;
export type MarkersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MarkersQuery, MarkersQueryVariables>, 'query'>;

    export const MarkersComponent = (props: MarkersComponentProps) => (
      <ApolloReactComponents.Query<MarkersQuery, MarkersQueryVariables> query={MarkersDocument} {...props} />
    );
    
export type MarkersProps<TChildProps = {}> = ApolloReactHoc.DataProps<MarkersQuery, MarkersQueryVariables> & TChildProps;
export function withMarkers<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  MarkersQuery,
  MarkersQueryVariables,
  MarkersProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, MarkersQuery, MarkersQueryVariables, MarkersProps<TChildProps>>(MarkersDocument, {
      alias: 'markers',
      ...operationOptions
    });
};

/**
 * __useMarkersQuery__
 *
 * To run a query within a React component, call `useMarkersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarkersQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarkersQuery({
 *   variables: {
 *   },
 * });
 */
export function useMarkersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MarkersQuery, MarkersQueryVariables>) {
        return ApolloReactHooks.useQuery<MarkersQuery, MarkersQueryVariables>(MarkersDocument, baseOptions);
      }
export function useMarkersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MarkersQuery, MarkersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MarkersQuery, MarkersQueryVariables>(MarkersDocument, baseOptions);
        }
export type MarkersQueryHookResult = ReturnType<typeof useMarkersQuery>;
export type MarkersLazyQueryHookResult = ReturnType<typeof useMarkersLazyQuery>;
export type MarkersQueryResult = ApolloReactCommon.QueryResult<MarkersQuery, MarkersQueryVariables>;
export const PinRouteDocument = gql`
    mutation PinRoute($id: ID!) {
  pinRoute(id: $id) {
    ...ExploreCard
  }
}
    ${ExploreCardFragmentDoc}`;
export type PinRouteMutationFn = ApolloReactCommon.MutationFunction<PinRouteMutation, PinRouteMutationVariables>;
export type PinRouteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<PinRouteMutation, PinRouteMutationVariables>, 'mutation'>;

    export const PinRouteComponent = (props: PinRouteComponentProps) => (
      <ApolloReactComponents.Mutation<PinRouteMutation, PinRouteMutationVariables> mutation={PinRouteDocument} {...props} />
    );
    
export type PinRouteProps<TChildProps = {}> = ApolloReactHoc.MutateProps<PinRouteMutation, PinRouteMutationVariables> & TChildProps;
export function withPinRoute<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PinRouteMutation,
  PinRouteMutationVariables,
  PinRouteProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, PinRouteMutation, PinRouteMutationVariables, PinRouteProps<TChildProps>>(PinRouteDocument, {
      alias: 'pinRoute',
      ...operationOptions
    });
};

/**
 * __usePinRouteMutation__
 *
 * To run a mutation, you first call `usePinRouteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePinRouteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pinRouteMutation, { data, loading, error }] = usePinRouteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePinRouteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PinRouteMutation, PinRouteMutationVariables>) {
        return ApolloReactHooks.useMutation<PinRouteMutation, PinRouteMutationVariables>(PinRouteDocument, baseOptions);
      }
export type PinRouteMutationHookResult = ReturnType<typeof usePinRouteMutation>;
export type PinRouteMutationResult = ApolloReactCommon.MutationResult<PinRouteMutation>;
export type PinRouteMutationOptions = ApolloReactCommon.BaseMutationOptions<PinRouteMutation, PinRouteMutationVariables>;
export const UnPinRouteDocument = gql`
    mutation UnPinRoute($id: ID!) {
  unpinRoute(id: $id) {
    ...ExploreCard
  }
}
    ${ExploreCardFragmentDoc}`;
export type UnPinRouteMutationFn = ApolloReactCommon.MutationFunction<UnPinRouteMutation, UnPinRouteMutationVariables>;
export type UnPinRouteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UnPinRouteMutation, UnPinRouteMutationVariables>, 'mutation'>;

    export const UnPinRouteComponent = (props: UnPinRouteComponentProps) => (
      <ApolloReactComponents.Mutation<UnPinRouteMutation, UnPinRouteMutationVariables> mutation={UnPinRouteDocument} {...props} />
    );
    
export type UnPinRouteProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UnPinRouteMutation, UnPinRouteMutationVariables> & TChildProps;
export function withUnPinRoute<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UnPinRouteMutation,
  UnPinRouteMutationVariables,
  UnPinRouteProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UnPinRouteMutation, UnPinRouteMutationVariables, UnPinRouteProps<TChildProps>>(UnPinRouteDocument, {
      alias: 'unPinRoute',
      ...operationOptions
    });
};

/**
 * __useUnPinRouteMutation__
 *
 * To run a mutation, you first call `useUnPinRouteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnPinRouteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unPinRouteMutation, { data, loading, error }] = useUnPinRouteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnPinRouteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnPinRouteMutation, UnPinRouteMutationVariables>) {
        return ApolloReactHooks.useMutation<UnPinRouteMutation, UnPinRouteMutationVariables>(UnPinRouteDocument, baseOptions);
      }
export type UnPinRouteMutationHookResult = ReturnType<typeof useUnPinRouteMutation>;
export type UnPinRouteMutationResult = ApolloReactCommon.MutationResult<UnPinRouteMutation>;
export type UnPinRouteMutationOptions = ApolloReactCommon.BaseMutationOptions<UnPinRouteMutation, UnPinRouteMutationVariables>;
export const ExploreGeocodeDocument = gql`
    query ExploreGeocode($lat: Float!, $lon: Float!) {
  exploreGeocode(data: {lat: $lat, lon: $lon}) {
    country
    county
    city
  }
}
    `;
export type ExploreGeocodeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ExploreGeocodeQuery, ExploreGeocodeQueryVariables>, 'query'> & ({ variables: ExploreGeocodeQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ExploreGeocodeComponent = (props: ExploreGeocodeComponentProps) => (
      <ApolloReactComponents.Query<ExploreGeocodeQuery, ExploreGeocodeQueryVariables> query={ExploreGeocodeDocument} {...props} />
    );
    
export type ExploreGeocodeProps<TChildProps = {}> = ApolloReactHoc.DataProps<ExploreGeocodeQuery, ExploreGeocodeQueryVariables> & TChildProps;
export function withExploreGeocode<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ExploreGeocodeQuery,
  ExploreGeocodeQueryVariables,
  ExploreGeocodeProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ExploreGeocodeQuery, ExploreGeocodeQueryVariables, ExploreGeocodeProps<TChildProps>>(ExploreGeocodeDocument, {
      alias: 'exploreGeocode',
      ...operationOptions
    });
};

/**
 * __useExploreGeocodeQuery__
 *
 * To run a query within a React component, call `useExploreGeocodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useExploreGeocodeQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExploreGeocodeQuery({
 *   variables: {
 *      lat: // value for 'lat'
 *      lon: // value for 'lon'
 *   },
 * });
 */
export function useExploreGeocodeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ExploreGeocodeQuery, ExploreGeocodeQueryVariables>) {
        return ApolloReactHooks.useQuery<ExploreGeocodeQuery, ExploreGeocodeQueryVariables>(ExploreGeocodeDocument, baseOptions);
      }
export function useExploreGeocodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ExploreGeocodeQuery, ExploreGeocodeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ExploreGeocodeQuery, ExploreGeocodeQueryVariables>(ExploreGeocodeDocument, baseOptions);
        }
export type ExploreGeocodeQueryHookResult = ReturnType<typeof useExploreGeocodeQuery>;
export type ExploreGeocodeLazyQueryHookResult = ReturnType<typeof useExploreGeocodeLazyQuery>;
export type ExploreGeocodeQueryResult = ApolloReactCommon.QueryResult<ExploreGeocodeQuery, ExploreGeocodeQueryVariables>;
export const ActivityDetailsViewDocument = gql`
    query ActivityDetailsView($id: ID!) {
  activity(id: $id) {
    id
    title
    activityType
    description
    isPrivate
    featuredImage {
      url
    }
    polyline {
      coordinates
      polyline
    }
    streams {
      elevation
      temperature
      time
      distance
    }
    stats {
      totalDuration
      totalAscent
      totalDistance
    }
    marker {
      lat
      lng
    }
  }
}
    `;
export type ActivityDetailsViewComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ActivityDetailsViewQuery, ActivityDetailsViewQueryVariables>, 'query'> & ({ variables: ActivityDetailsViewQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ActivityDetailsViewComponent = (props: ActivityDetailsViewComponentProps) => (
      <ApolloReactComponents.Query<ActivityDetailsViewQuery, ActivityDetailsViewQueryVariables> query={ActivityDetailsViewDocument} {...props} />
    );
    
export type ActivityDetailsViewProps<TChildProps = {}> = ApolloReactHoc.DataProps<ActivityDetailsViewQuery, ActivityDetailsViewQueryVariables> & TChildProps;
export function withActivityDetailsView<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ActivityDetailsViewQuery,
  ActivityDetailsViewQueryVariables,
  ActivityDetailsViewProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ActivityDetailsViewQuery, ActivityDetailsViewQueryVariables, ActivityDetailsViewProps<TChildProps>>(ActivityDetailsViewDocument, {
      alias: 'activityDetailsView',
      ...operationOptions
    });
};

/**
 * __useActivityDetailsViewQuery__
 *
 * To run a query within a React component, call `useActivityDetailsViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityDetailsViewQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityDetailsViewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActivityDetailsViewQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ActivityDetailsViewQuery, ActivityDetailsViewQueryVariables>) {
        return ApolloReactHooks.useQuery<ActivityDetailsViewQuery, ActivityDetailsViewQueryVariables>(ActivityDetailsViewDocument, baseOptions);
      }
export function useActivityDetailsViewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ActivityDetailsViewQuery, ActivityDetailsViewQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ActivityDetailsViewQuery, ActivityDetailsViewQueryVariables>(ActivityDetailsViewDocument, baseOptions);
        }
export type ActivityDetailsViewQueryHookResult = ReturnType<typeof useActivityDetailsViewQuery>;
export type ActivityDetailsViewLazyQueryHookResult = ReturnType<typeof useActivityDetailsViewLazyQuery>;
export type ActivityDetailsViewQueryResult = ApolloReactCommon.QueryResult<ActivityDetailsViewQuery, ActivityDetailsViewQueryVariables>;
export const ActivitiesIndexViewDocument = gql`
    query ActivitiesIndexView($filter: ActivityFilterInput, $first: Int, $after: String) {
  activities(filter: $filter, first: $first, after: $after) {
    ...ActivityCardData
  }
}
    ${ActivityCardDataFragmentDoc}`;
export type ActivitiesIndexViewComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ActivitiesIndexViewQuery, ActivitiesIndexViewQueryVariables>, 'query'>;

    export const ActivitiesIndexViewComponent = (props: ActivitiesIndexViewComponentProps) => (
      <ApolloReactComponents.Query<ActivitiesIndexViewQuery, ActivitiesIndexViewQueryVariables> query={ActivitiesIndexViewDocument} {...props} />
    );
    
export type ActivitiesIndexViewProps<TChildProps = {}> = ApolloReactHoc.DataProps<ActivitiesIndexViewQuery, ActivitiesIndexViewQueryVariables> & TChildProps;
export function withActivitiesIndexView<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ActivitiesIndexViewQuery,
  ActivitiesIndexViewQueryVariables,
  ActivitiesIndexViewProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ActivitiesIndexViewQuery, ActivitiesIndexViewQueryVariables, ActivitiesIndexViewProps<TChildProps>>(ActivitiesIndexViewDocument, {
      alias: 'activitiesIndexView',
      ...operationOptions
    });
};

/**
 * __useActivitiesIndexViewQuery__
 *
 * To run a query within a React component, call `useActivitiesIndexViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivitiesIndexViewQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivitiesIndexViewQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useActivitiesIndexViewQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ActivitiesIndexViewQuery, ActivitiesIndexViewQueryVariables>) {
        return ApolloReactHooks.useQuery<ActivitiesIndexViewQuery, ActivitiesIndexViewQueryVariables>(ActivitiesIndexViewDocument, baseOptions);
      }
export function useActivitiesIndexViewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ActivitiesIndexViewQuery, ActivitiesIndexViewQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ActivitiesIndexViewQuery, ActivitiesIndexViewQueryVariables>(ActivitiesIndexViewDocument, baseOptions);
        }
export type ActivitiesIndexViewQueryHookResult = ReturnType<typeof useActivitiesIndexViewQuery>;
export type ActivitiesIndexViewLazyQueryHookResult = ReturnType<typeof useActivitiesIndexViewLazyQuery>;
export type ActivitiesIndexViewQueryResult = ApolloReactCommon.QueryResult<ActivitiesIndexViewQuery, ActivitiesIndexViewQueryVariables>;
export const VerifyInvitationTokenDocument = gql`
    mutation verifyInvitationToken($token: String!) {
  verifyInvitationToken(token: $token) {
    id
    name
    email
  }
}
    `;
export type VerifyInvitationTokenMutationFn = ApolloReactCommon.MutationFunction<VerifyInvitationTokenMutation, VerifyInvitationTokenMutationVariables>;
export type VerifyInvitationTokenComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<VerifyInvitationTokenMutation, VerifyInvitationTokenMutationVariables>, 'mutation'>;

    export const VerifyInvitationTokenComponent = (props: VerifyInvitationTokenComponentProps) => (
      <ApolloReactComponents.Mutation<VerifyInvitationTokenMutation, VerifyInvitationTokenMutationVariables> mutation={VerifyInvitationTokenDocument} {...props} />
    );
    
export type VerifyInvitationTokenProps<TChildProps = {}> = ApolloReactHoc.MutateProps<VerifyInvitationTokenMutation, VerifyInvitationTokenMutationVariables> & TChildProps;
export function withVerifyInvitationToken<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  VerifyInvitationTokenMutation,
  VerifyInvitationTokenMutationVariables,
  VerifyInvitationTokenProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, VerifyInvitationTokenMutation, VerifyInvitationTokenMutationVariables, VerifyInvitationTokenProps<TChildProps>>(VerifyInvitationTokenDocument, {
      alias: 'verifyInvitationToken',
      ...operationOptions
    });
};

/**
 * __useVerifyInvitationTokenMutation__
 *
 * To run a mutation, you first call `useVerifyInvitationTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyInvitationTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyInvitationTokenMutation, { data, loading, error }] = useVerifyInvitationTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVerifyInvitationTokenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<VerifyInvitationTokenMutation, VerifyInvitationTokenMutationVariables>) {
        return ApolloReactHooks.useMutation<VerifyInvitationTokenMutation, VerifyInvitationTokenMutationVariables>(VerifyInvitationTokenDocument, baseOptions);
      }
export type VerifyInvitationTokenMutationHookResult = ReturnType<typeof useVerifyInvitationTokenMutation>;
export type VerifyInvitationTokenMutationResult = ApolloReactCommon.MutationResult<VerifyInvitationTokenMutation>;
export type VerifyInvitationTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<VerifyInvitationTokenMutation, VerifyInvitationTokenMutationVariables>;
export const ConfirmUserInvitationDocument = gql`
    mutation confirmUserInvitation($data: ConfirmUserInvitationInput!) {
  confirmUserInvitation(data: $data) {
    id
    name
    email
  }
}
    `;
export type ConfirmUserInvitationMutationFn = ApolloReactCommon.MutationFunction<ConfirmUserInvitationMutation, ConfirmUserInvitationMutationVariables>;
export type ConfirmUserInvitationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ConfirmUserInvitationMutation, ConfirmUserInvitationMutationVariables>, 'mutation'>;

    export const ConfirmUserInvitationComponent = (props: ConfirmUserInvitationComponentProps) => (
      <ApolloReactComponents.Mutation<ConfirmUserInvitationMutation, ConfirmUserInvitationMutationVariables> mutation={ConfirmUserInvitationDocument} {...props} />
    );
    
export type ConfirmUserInvitationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ConfirmUserInvitationMutation, ConfirmUserInvitationMutationVariables> & TChildProps;
export function withConfirmUserInvitation<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ConfirmUserInvitationMutation,
  ConfirmUserInvitationMutationVariables,
  ConfirmUserInvitationProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ConfirmUserInvitationMutation, ConfirmUserInvitationMutationVariables, ConfirmUserInvitationProps<TChildProps>>(ConfirmUserInvitationDocument, {
      alias: 'confirmUserInvitation',
      ...operationOptions
    });
};

/**
 * __useConfirmUserInvitationMutation__
 *
 * To run a mutation, you first call `useConfirmUserInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmUserInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmUserInvitationMutation, { data, loading, error }] = useConfirmUserInvitationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useConfirmUserInvitationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ConfirmUserInvitationMutation, ConfirmUserInvitationMutationVariables>) {
        return ApolloReactHooks.useMutation<ConfirmUserInvitationMutation, ConfirmUserInvitationMutationVariables>(ConfirmUserInvitationDocument, baseOptions);
      }
export type ConfirmUserInvitationMutationHookResult = ReturnType<typeof useConfirmUserInvitationMutation>;
export type ConfirmUserInvitationMutationResult = ApolloReactCommon.MutationResult<ConfirmUserInvitationMutation>;
export type ConfirmUserInvitationMutationOptions = ApolloReactCommon.BaseMutationOptions<ConfirmUserInvitationMutation, ConfirmUserInvitationMutationVariables>;
export const LoginDocument = gql`
    mutation login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    accessToken
    refreshToken
  }
}
    `;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>;
export type LoginComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<LoginMutation, LoginMutationVariables>, 'mutation'>;

    export const LoginComponent = (props: LoginComponentProps) => (
      <ApolloReactComponents.Mutation<LoginMutation, LoginMutationVariables> mutation={LoginDocument} {...props} />
    );
    
export type LoginProps<TChildProps = {}> = ApolloReactHoc.MutateProps<LoginMutation, LoginMutationVariables> & TChildProps;
export function withLogin<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  LoginMutation,
  LoginMutationVariables,
  LoginProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, LoginMutation, LoginMutationVariables, LoginProps<TChildProps>>(LoginDocument, {
      alias: 'login',
      ...operationOptions
    });
};

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LoginWithRefreshTokenDocument = gql`
    mutation loginWithRefreshToken($token: String!) {
  loginWithRefreshToken(token: $token) {
    accessToken
    refreshToken
  }
}
    `;
export type LoginWithRefreshTokenMutationFn = ApolloReactCommon.MutationFunction<LoginWithRefreshTokenMutation, LoginWithRefreshTokenMutationVariables>;
export type LoginWithRefreshTokenComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<LoginWithRefreshTokenMutation, LoginWithRefreshTokenMutationVariables>, 'mutation'>;

    export const LoginWithRefreshTokenComponent = (props: LoginWithRefreshTokenComponentProps) => (
      <ApolloReactComponents.Mutation<LoginWithRefreshTokenMutation, LoginWithRefreshTokenMutationVariables> mutation={LoginWithRefreshTokenDocument} {...props} />
    );
    
export type LoginWithRefreshTokenProps<TChildProps = {}> = ApolloReactHoc.MutateProps<LoginWithRefreshTokenMutation, LoginWithRefreshTokenMutationVariables> & TChildProps;
export function withLoginWithRefreshToken<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  LoginWithRefreshTokenMutation,
  LoginWithRefreshTokenMutationVariables,
  LoginWithRefreshTokenProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, LoginWithRefreshTokenMutation, LoginWithRefreshTokenMutationVariables, LoginWithRefreshTokenProps<TChildProps>>(LoginWithRefreshTokenDocument, {
      alias: 'loginWithRefreshToken',
      ...operationOptions
    });
};

/**
 * __useLoginWithRefreshTokenMutation__
 *
 * To run a mutation, you first call `useLoginWithRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginWithRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginWithRefreshTokenMutation, { data, loading, error }] = useLoginWithRefreshTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useLoginWithRefreshTokenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginWithRefreshTokenMutation, LoginWithRefreshTokenMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginWithRefreshTokenMutation, LoginWithRefreshTokenMutationVariables>(LoginWithRefreshTokenDocument, baseOptions);
      }
export type LoginWithRefreshTokenMutationHookResult = ReturnType<typeof useLoginWithRefreshTokenMutation>;
export type LoginWithRefreshTokenMutationResult = ApolloReactCommon.MutationResult<LoginWithRefreshTokenMutation>;
export type LoginWithRefreshTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginWithRefreshTokenMutation, LoginWithRefreshTokenMutationVariables>;
export const SignupDocument = gql`
    mutation signup($name: String!, $email: String!, $password: String!) {
  signup(name: $name, email: $email, password: $password) {
    accessToken
    refreshToken
  }
}
    `;
export type SignupMutationFn = ApolloReactCommon.MutationFunction<SignupMutation, SignupMutationVariables>;
export type SignupComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SignupMutation, SignupMutationVariables>, 'mutation'>;

    export const SignupComponent = (props: SignupComponentProps) => (
      <ApolloReactComponents.Mutation<SignupMutation, SignupMutationVariables> mutation={SignupDocument} {...props} />
    );
    
export type SignupProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SignupMutation, SignupMutationVariables> & TChildProps;
export function withSignup<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SignupMutation,
  SignupMutationVariables,
  SignupProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SignupMutation, SignupMutationVariables, SignupProps<TChildProps>>(SignupDocument, {
      alias: 'signup',
      ...operationOptions
    });
};

/**
 * __useSignupMutation__
 *
 * To run a mutation, you first call `useSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupMutation, { data, loading, error }] = useSignupMutation({
 *   variables: {
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSignupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SignupMutation, SignupMutationVariables>) {
        return ApolloReactHooks.useMutation<SignupMutation, SignupMutationVariables>(SignupDocument, baseOptions);
      }
export type SignupMutationHookResult = ReturnType<typeof useSignupMutation>;
export type SignupMutationResult = ApolloReactCommon.MutationResult<SignupMutation>;
export type SignupMutationOptions = ApolloReactCommon.BaseMutationOptions<SignupMutation, SignupMutationVariables>;
export const ExploreViewDocument = gql`
    query ExploreView($first: Int!, $after: ID) {
  routes(pagination: {first: $first, after: $after}) {
    ...ExploreRoute
  }
}
    ${ExploreRouteFragmentDoc}`;
export type ExploreViewComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ExploreViewQuery, ExploreViewQueryVariables>, 'query'> & ({ variables: ExploreViewQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ExploreViewComponent = (props: ExploreViewComponentProps) => (
      <ApolloReactComponents.Query<ExploreViewQuery, ExploreViewQueryVariables> query={ExploreViewDocument} {...props} />
    );
    
export type ExploreViewProps<TChildProps = {}> = ApolloReactHoc.DataProps<ExploreViewQuery, ExploreViewQueryVariables> & TChildProps;
export function withExploreView<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ExploreViewQuery,
  ExploreViewQueryVariables,
  ExploreViewProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ExploreViewQuery, ExploreViewQueryVariables, ExploreViewProps<TChildProps>>(ExploreViewDocument, {
      alias: 'exploreView',
      ...operationOptions
    });
};

/**
 * __useExploreViewQuery__
 *
 * To run a query within a React component, call `useExploreViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useExploreViewQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExploreViewQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useExploreViewQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ExploreViewQuery, ExploreViewQueryVariables>) {
        return ApolloReactHooks.useQuery<ExploreViewQuery, ExploreViewQueryVariables>(ExploreViewDocument, baseOptions);
      }
export function useExploreViewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ExploreViewQuery, ExploreViewQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ExploreViewQuery, ExploreViewQueryVariables>(ExploreViewDocument, baseOptions);
        }
export type ExploreViewQueryHookResult = ReturnType<typeof useExploreViewQuery>;
export type ExploreViewLazyQueryHookResult = ReturnType<typeof useExploreViewLazyQuery>;
export type ExploreViewQueryResult = ApolloReactCommon.QueryResult<ExploreViewQuery, ExploreViewQueryVariables>;
export const FilterExploreRoutesDocument = gql`
    query FilterExploreRoutes($first: Int!, $latitude: Float!, $longitude: Float!) {
  routes(pagination: {first: $first}, filter: {location: {distance: 20, longitude: $longitude, latitude: $latitude}}) {
    ...ExploreRoute
  }
}
    ${ExploreRouteFragmentDoc}`;
export type FilterExploreRoutesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FilterExploreRoutesQuery, FilterExploreRoutesQueryVariables>, 'query'> & ({ variables: FilterExploreRoutesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FilterExploreRoutesComponent = (props: FilterExploreRoutesComponentProps) => (
      <ApolloReactComponents.Query<FilterExploreRoutesQuery, FilterExploreRoutesQueryVariables> query={FilterExploreRoutesDocument} {...props} />
    );
    
export type FilterExploreRoutesProps<TChildProps = {}> = ApolloReactHoc.DataProps<FilterExploreRoutesQuery, FilterExploreRoutesQueryVariables> & TChildProps;
export function withFilterExploreRoutes<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  FilterExploreRoutesQuery,
  FilterExploreRoutesQueryVariables,
  FilterExploreRoutesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, FilterExploreRoutesQuery, FilterExploreRoutesQueryVariables, FilterExploreRoutesProps<TChildProps>>(FilterExploreRoutesDocument, {
      alias: 'filterExploreRoutes',
      ...operationOptions
    });
};

/**
 * __useFilterExploreRoutesQuery__
 *
 * To run a query within a React component, call `useFilterExploreRoutesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilterExploreRoutesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilterExploreRoutesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      latitude: // value for 'latitude'
 *      longitude: // value for 'longitude'
 *   },
 * });
 */
export function useFilterExploreRoutesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FilterExploreRoutesQuery, FilterExploreRoutesQueryVariables>) {
        return ApolloReactHooks.useQuery<FilterExploreRoutesQuery, FilterExploreRoutesQueryVariables>(FilterExploreRoutesDocument, baseOptions);
      }
export function useFilterExploreRoutesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FilterExploreRoutesQuery, FilterExploreRoutesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FilterExploreRoutesQuery, FilterExploreRoutesQueryVariables>(FilterExploreRoutesDocument, baseOptions);
        }
export type FilterExploreRoutesQueryHookResult = ReturnType<typeof useFilterExploreRoutesQuery>;
export type FilterExploreRoutesLazyQueryHookResult = ReturnType<typeof useFilterExploreRoutesLazyQuery>;
export type FilterExploreRoutesQueryResult = ApolloReactCommon.QueryResult<FilterExploreRoutesQuery, FilterExploreRoutesQueryVariables>;
export const UserCompletedTripsDocument = gql`
    query UserCompletedTrips($first: Int!, $after: ID) {
  me {
    id
    activitiesConnection(paginate: {first: $first, after: $after}) {
      edges {
        node {
          ...ActivityCardData
        }
      }
    }
  }
}
    ${ActivityCardDataFragmentDoc}`;
export type UserCompletedTripsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserCompletedTripsQuery, UserCompletedTripsQueryVariables>, 'query'> & ({ variables: UserCompletedTripsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const UserCompletedTripsComponent = (props: UserCompletedTripsComponentProps) => (
      <ApolloReactComponents.Query<UserCompletedTripsQuery, UserCompletedTripsQueryVariables> query={UserCompletedTripsDocument} {...props} />
    );
    
export type UserCompletedTripsProps<TChildProps = {}> = ApolloReactHoc.DataProps<UserCompletedTripsQuery, UserCompletedTripsQueryVariables> & TChildProps;
export function withUserCompletedTrips<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UserCompletedTripsQuery,
  UserCompletedTripsQueryVariables,
  UserCompletedTripsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, UserCompletedTripsQuery, UserCompletedTripsQueryVariables, UserCompletedTripsProps<TChildProps>>(UserCompletedTripsDocument, {
      alias: 'userCompletedTrips',
      ...operationOptions
    });
};

/**
 * __useUserCompletedTripsQuery__
 *
 * To run a query within a React component, call `useUserCompletedTripsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCompletedTripsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCompletedTripsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useUserCompletedTripsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserCompletedTripsQuery, UserCompletedTripsQueryVariables>) {
        return ApolloReactHooks.useQuery<UserCompletedTripsQuery, UserCompletedTripsQueryVariables>(UserCompletedTripsDocument, baseOptions);
      }
export function useUserCompletedTripsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserCompletedTripsQuery, UserCompletedTripsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserCompletedTripsQuery, UserCompletedTripsQueryVariables>(UserCompletedTripsDocument, baseOptions);
        }
export type UserCompletedTripsQueryHookResult = ReturnType<typeof useUserCompletedTripsQuery>;
export type UserCompletedTripsLazyQueryHookResult = ReturnType<typeof useUserCompletedTripsLazyQuery>;
export type UserCompletedTripsQueryResult = ApolloReactCommon.QueryResult<UserCompletedTripsQuery, UserCompletedTripsQueryVariables>;
export const UserCreatedRoutesDocument = gql`
    query UserCreatedRoutes($first: Int!, $after: ID) {
  me {
    id
    createdRoutesConnection(paginate: {first: $first, after: $after}) {
      edges {
        node {
          ...ExploreCard
        }
      }
    }
  }
}
    ${ExploreCardFragmentDoc}`;
export type UserCreatedRoutesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserCreatedRoutesQuery, UserCreatedRoutesQueryVariables>, 'query'> & ({ variables: UserCreatedRoutesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const UserCreatedRoutesComponent = (props: UserCreatedRoutesComponentProps) => (
      <ApolloReactComponents.Query<UserCreatedRoutesQuery, UserCreatedRoutesQueryVariables> query={UserCreatedRoutesDocument} {...props} />
    );
    
export type UserCreatedRoutesProps<TChildProps = {}> = ApolloReactHoc.DataProps<UserCreatedRoutesQuery, UserCreatedRoutesQueryVariables> & TChildProps;
export function withUserCreatedRoutes<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UserCreatedRoutesQuery,
  UserCreatedRoutesQueryVariables,
  UserCreatedRoutesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, UserCreatedRoutesQuery, UserCreatedRoutesQueryVariables, UserCreatedRoutesProps<TChildProps>>(UserCreatedRoutesDocument, {
      alias: 'userCreatedRoutes',
      ...operationOptions
    });
};

/**
 * __useUserCreatedRoutesQuery__
 *
 * To run a query within a React component, call `useUserCreatedRoutesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCreatedRoutesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCreatedRoutesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useUserCreatedRoutesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserCreatedRoutesQuery, UserCreatedRoutesQueryVariables>) {
        return ApolloReactHooks.useQuery<UserCreatedRoutesQuery, UserCreatedRoutesQueryVariables>(UserCreatedRoutesDocument, baseOptions);
      }
export function useUserCreatedRoutesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserCreatedRoutesQuery, UserCreatedRoutesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserCreatedRoutesQuery, UserCreatedRoutesQueryVariables>(UserCreatedRoutesDocument, baseOptions);
        }
export type UserCreatedRoutesQueryHookResult = ReturnType<typeof useUserCreatedRoutesQuery>;
export type UserCreatedRoutesLazyQueryHookResult = ReturnType<typeof useUserCreatedRoutesLazyQuery>;
export type UserCreatedRoutesQueryResult = ApolloReactCommon.QueryResult<UserCreatedRoutesQuery, UserCreatedRoutesQueryVariables>;
export const UserSavedRoutesDocument = gql`
    query UserSavedRoutes {
  me {
    id
    savedRoutesConnection {
      edges {
        node {
          ...ExploreCard
        }
      }
    }
  }
}
    ${ExploreCardFragmentDoc}`;
export type UserSavedRoutesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserSavedRoutesQuery, UserSavedRoutesQueryVariables>, 'query'>;

    export const UserSavedRoutesComponent = (props: UserSavedRoutesComponentProps) => (
      <ApolloReactComponents.Query<UserSavedRoutesQuery, UserSavedRoutesQueryVariables> query={UserSavedRoutesDocument} {...props} />
    );
    
export type UserSavedRoutesProps<TChildProps = {}> = ApolloReactHoc.DataProps<UserSavedRoutesQuery, UserSavedRoutesQueryVariables> & TChildProps;
export function withUserSavedRoutes<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UserSavedRoutesQuery,
  UserSavedRoutesQueryVariables,
  UserSavedRoutesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, UserSavedRoutesQuery, UserSavedRoutesQueryVariables, UserSavedRoutesProps<TChildProps>>(UserSavedRoutesDocument, {
      alias: 'userSavedRoutes',
      ...operationOptions
    });
};

/**
 * __useUserSavedRoutesQuery__
 *
 * To run a query within a React component, call `useUserSavedRoutesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSavedRoutesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSavedRoutesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserSavedRoutesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserSavedRoutesQuery, UserSavedRoutesQueryVariables>) {
        return ApolloReactHooks.useQuery<UserSavedRoutesQuery, UserSavedRoutesQueryVariables>(UserSavedRoutesDocument, baseOptions);
      }
export function useUserSavedRoutesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserSavedRoutesQuery, UserSavedRoutesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserSavedRoutesQuery, UserSavedRoutesQueryVariables>(UserSavedRoutesDocument, baseOptions);
        }
export type UserSavedRoutesQueryHookResult = ReturnType<typeof useUserSavedRoutesQuery>;
export type UserSavedRoutesLazyQueryHookResult = ReturnType<typeof useUserSavedRoutesLazyQuery>;
export type UserSavedRoutesQueryResult = ApolloReactCommon.QueryResult<UserSavedRoutesQuery, UserSavedRoutesQueryVariables>;
export const InviteUserDocument = gql`
    mutation InviteUser($data: InviteUserInput!) {
  sendUserInvitation(data: $data) {
    id
    name
  }
}
    `;
export type InviteUserMutationFn = ApolloReactCommon.MutationFunction<InviteUserMutation, InviteUserMutationVariables>;
export type InviteUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InviteUserMutation, InviteUserMutationVariables>, 'mutation'>;

    export const InviteUserComponent = (props: InviteUserComponentProps) => (
      <ApolloReactComponents.Mutation<InviteUserMutation, InviteUserMutationVariables> mutation={InviteUserDocument} {...props} />
    );
    
export type InviteUserProps<TChildProps = {}> = ApolloReactHoc.MutateProps<InviteUserMutation, InviteUserMutationVariables> & TChildProps;
export function withInviteUser<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  InviteUserMutation,
  InviteUserMutationVariables,
  InviteUserProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, InviteUserMutation, InviteUserMutationVariables, InviteUserProps<TChildProps>>(InviteUserDocument, {
      alias: 'inviteUser',
      ...operationOptions
    });
};

/**
 * __useInviteUserMutation__
 *
 * To run a mutation, you first call `useInviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserMutation, { data, loading, error }] = useInviteUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInviteUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InviteUserMutation, InviteUserMutationVariables>) {
        return ApolloReactHooks.useMutation<InviteUserMutation, InviteUserMutationVariables>(InviteUserDocument, baseOptions);
      }
export type InviteUserMutationHookResult = ReturnType<typeof useInviteUserMutation>;
export type InviteUserMutationResult = ApolloReactCommon.MutationResult<InviteUserMutation>;
export type InviteUserMutationOptions = ApolloReactCommon.BaseMutationOptions<InviteUserMutation, InviteUserMutationVariables>;
export const InvitedUsersDocument = gql`
    query InvitedUsers {
  me {
    id
    invitedUsers {
      id
      email
    }
  }
}
    `;
export type InvitedUsersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<InvitedUsersQuery, InvitedUsersQueryVariables>, 'query'>;

    export const InvitedUsersComponent = (props: InvitedUsersComponentProps) => (
      <ApolloReactComponents.Query<InvitedUsersQuery, InvitedUsersQueryVariables> query={InvitedUsersDocument} {...props} />
    );
    
export type InvitedUsersProps<TChildProps = {}> = ApolloReactHoc.DataProps<InvitedUsersQuery, InvitedUsersQueryVariables> & TChildProps;
export function withInvitedUsers<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  InvitedUsersQuery,
  InvitedUsersQueryVariables,
  InvitedUsersProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, InvitedUsersQuery, InvitedUsersQueryVariables, InvitedUsersProps<TChildProps>>(InvitedUsersDocument, {
      alias: 'invitedUsers',
      ...operationOptions
    });
};

/**
 * __useInvitedUsersQuery__
 *
 * To run a query within a React component, call `useInvitedUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvitedUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvitedUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useInvitedUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<InvitedUsersQuery, InvitedUsersQueryVariables>) {
        return ApolloReactHooks.useQuery<InvitedUsersQuery, InvitedUsersQueryVariables>(InvitedUsersDocument, baseOptions);
      }
export function useInvitedUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InvitedUsersQuery, InvitedUsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<InvitedUsersQuery, InvitedUsersQueryVariables>(InvitedUsersDocument, baseOptions);
        }
export type InvitedUsersQueryHookResult = ReturnType<typeof useInvitedUsersQuery>;
export type InvitedUsersLazyQueryHookResult = ReturnType<typeof useInvitedUsersLazyQuery>;
export type InvitedUsersQueryResult = ApolloReactCommon.QueryResult<InvitedUsersQuery, InvitedUsersQueryVariables>;
export const CreateRouteDocument = gql`
    mutation CreateRoute($data: CreateRouteInput!) {
  createRoute(data: $data) {
    id
  }
}
    `;
export type CreateRouteMutationFn = ApolloReactCommon.MutationFunction<CreateRouteMutation, CreateRouteMutationVariables>;
export type CreateRouteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateRouteMutation, CreateRouteMutationVariables>, 'mutation'>;

    export const CreateRouteComponent = (props: CreateRouteComponentProps) => (
      <ApolloReactComponents.Mutation<CreateRouteMutation, CreateRouteMutationVariables> mutation={CreateRouteDocument} {...props} />
    );
    
export type CreateRouteProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateRouteMutation, CreateRouteMutationVariables> & TChildProps;
export function withCreateRoute<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateRouteMutation,
  CreateRouteMutationVariables,
  CreateRouteProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateRouteMutation, CreateRouteMutationVariables, CreateRouteProps<TChildProps>>(CreateRouteDocument, {
      alias: 'createRoute',
      ...operationOptions
    });
};

/**
 * __useCreateRouteMutation__
 *
 * To run a mutation, you first call `useCreateRouteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRouteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRouteMutation, { data, loading, error }] = useCreateRouteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateRouteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateRouteMutation, CreateRouteMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateRouteMutation, CreateRouteMutationVariables>(CreateRouteDocument, baseOptions);
      }
export type CreateRouteMutationHookResult = ReturnType<typeof useCreateRouteMutation>;
export type CreateRouteMutationResult = ApolloReactCommon.MutationResult<CreateRouteMutation>;
export type CreateRouteMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateRouteMutation, CreateRouteMutationVariables>;
export const GetRouteDocument = gql`
    query GetRoute($id: ID!) {
  route(id: $id) {
    id
    title
    description
    activityType
    geoJson
    featuredImage {
      url
    }
    marker {
      lat
      lng
    }
    mountains {
      name
    }
    isPublished
    approach
  }
}
    `;
export type GetRouteComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetRouteQuery, GetRouteQueryVariables>, 'query'> & ({ variables: GetRouteQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetRouteComponent = (props: GetRouteComponentProps) => (
      <ApolloReactComponents.Query<GetRouteQuery, GetRouteQueryVariables> query={GetRouteDocument} {...props} />
    );
    
export type GetRouteProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetRouteQuery, GetRouteQueryVariables> & TChildProps;
export function withGetRoute<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetRouteQuery,
  GetRouteQueryVariables,
  GetRouteProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetRouteQuery, GetRouteQueryVariables, GetRouteProps<TChildProps>>(GetRouteDocument, {
      alias: 'getRoute',
      ...operationOptions
    });
};

/**
 * __useGetRouteQuery__
 *
 * To run a query within a React component, call `useGetRouteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRouteQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRouteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRouteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRouteQuery, GetRouteQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRouteQuery, GetRouteQueryVariables>(GetRouteDocument, baseOptions);
      }
export function useGetRouteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRouteQuery, GetRouteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRouteQuery, GetRouteQueryVariables>(GetRouteDocument, baseOptions);
        }
export type GetRouteQueryHookResult = ReturnType<typeof useGetRouteQuery>;
export type GetRouteLazyQueryHookResult = ReturnType<typeof useGetRouteLazyQuery>;
export type GetRouteQueryResult = ApolloReactCommon.QueryResult<GetRouteQuery, GetRouteQueryVariables>;
export const UpdateRouteDocument = gql`
    mutation UpdateRoute($id: ID!, $data: UpdateRouteInput!) {
  updateRoute(id: $id, data: $data) {
    id
  }
}
    `;
export type UpdateRouteMutationFn = ApolloReactCommon.MutationFunction<UpdateRouteMutation, UpdateRouteMutationVariables>;
export type UpdateRouteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateRouteMutation, UpdateRouteMutationVariables>, 'mutation'>;

    export const UpdateRouteComponent = (props: UpdateRouteComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateRouteMutation, UpdateRouteMutationVariables> mutation={UpdateRouteDocument} {...props} />
    );
    
export type UpdateRouteProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateRouteMutation, UpdateRouteMutationVariables> & TChildProps;
export function withUpdateRoute<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateRouteMutation,
  UpdateRouteMutationVariables,
  UpdateRouteProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateRouteMutation, UpdateRouteMutationVariables, UpdateRouteProps<TChildProps>>(UpdateRouteDocument, {
      alias: 'updateRoute',
      ...operationOptions
    });
};

/**
 * __useUpdateRouteMutation__
 *
 * To run a mutation, you first call `useUpdateRouteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRouteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRouteMutation, { data, loading, error }] = useUpdateRouteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateRouteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateRouteMutation, UpdateRouteMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateRouteMutation, UpdateRouteMutationVariables>(UpdateRouteDocument, baseOptions);
      }
export type UpdateRouteMutationHookResult = ReturnType<typeof useUpdateRouteMutation>;
export type UpdateRouteMutationResult = ApolloReactCommon.MutationResult<UpdateRouteMutation>;
export type UpdateRouteMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateRouteMutation, UpdateRouteMutationVariables>;
export const RouteDetailsViewDocument = gql`
    query RouteDetailsView($id: ID!) {
  route(id: $id) {
    id
    title
    activityType
    description
    geoJson
    marker {
      lat
      lng
    }
    mountains {
      name
    }
    country {
      id
      name
    }
    county {
      id
      name
    }
    city {
      id
      name
    }
    featuredImage {
      url
    }
    approach
    isPublished
    distance
    duration
    elevation
    difficulty
  }
}
    `;
export type RouteDetailsViewComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RouteDetailsViewQuery, RouteDetailsViewQueryVariables>, 'query'> & ({ variables: RouteDetailsViewQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RouteDetailsViewComponent = (props: RouteDetailsViewComponentProps) => (
      <ApolloReactComponents.Query<RouteDetailsViewQuery, RouteDetailsViewQueryVariables> query={RouteDetailsViewDocument} {...props} />
    );
    
export type RouteDetailsViewProps<TChildProps = {}> = ApolloReactHoc.DataProps<RouteDetailsViewQuery, RouteDetailsViewQueryVariables> & TChildProps;
export function withRouteDetailsView<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RouteDetailsViewQuery,
  RouteDetailsViewQueryVariables,
  RouteDetailsViewProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, RouteDetailsViewQuery, RouteDetailsViewQueryVariables, RouteDetailsViewProps<TChildProps>>(RouteDetailsViewDocument, {
      alias: 'routeDetailsView',
      ...operationOptions
    });
};

/**
 * __useRouteDetailsViewQuery__
 *
 * To run a query within a React component, call `useRouteDetailsViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useRouteDetailsViewQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRouteDetailsViewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRouteDetailsViewQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RouteDetailsViewQuery, RouteDetailsViewQueryVariables>) {
        return ApolloReactHooks.useQuery<RouteDetailsViewQuery, RouteDetailsViewQueryVariables>(RouteDetailsViewDocument, baseOptions);
      }
export function useRouteDetailsViewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RouteDetailsViewQuery, RouteDetailsViewQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RouteDetailsViewQuery, RouteDetailsViewQueryVariables>(RouteDetailsViewDocument, baseOptions);
        }
export type RouteDetailsViewQueryHookResult = ReturnType<typeof useRouteDetailsViewQuery>;
export type RouteDetailsViewLazyQueryHookResult = ReturnType<typeof useRouteDetailsViewLazyQuery>;
export type RouteDetailsViewQueryResult = ApolloReactCommon.QueryResult<RouteDetailsViewQuery, RouteDetailsViewQueryVariables>;