import { IAuthProvider } from '../providers/AuthProvider';

export interface INavLinkType {
  classSuffix?: string;
  href: string;
  openInNewTab?: boolean;
  title: string;
}

export enum ROUTES {
  ACTIVITIES = '/activities',
  BLOG = 'https://blog.vertrax.io',
  CONFIRM_INVITATION = '/confirm-invitation',
  CREATE_ACTIVITY = '/activities/create',
  CREATE_FEATURED_REGION = '/featured-regions/create',
  CREATE_ROUTE = '/routes/create',
  EXPLORE = '/explore',
  FEATURED_REGIONS = '/featured-regions',
  GUIDES = '/guides',
  HOME = '/',
  INVITATION_EXPIRED = '/invitation-expired',
  LOGIN = '/login',
  LOGOUT = '/',
  PROFILE = '/profile',
  REGISTER = '/register',
  // eslint-disable-next-line no-shadow
  ROUTES = '/routes',
}

export const NAV_LINKS: { [name: string]: INavLinkType } = {
  achieved: {
    href: '/routes/my-routes',
    title: 'Created',
  },
  activities: {
    href: '/activities',
    title: 'Activities',
  },
  blog: {
    href: ROUTES.BLOG,
    openInNewTab: true,
    title: 'Blog',
  },
  explore: {
    href: '/explore',
    title: 'World Map',
  },
  facebook: {
    href: 'https://facebook.com',
    openInNewTab: true,
    title: '',
  },
  guides: {
    href: '/guides/leirvassbu',
    title: 'Guides',
  },
  instagram: {
    href: 'https://instagram.com',
    openInNewTab: true,
    title: '',
  },
  register: {
    href: ROUTES.REGISTER,
    title: 'Register',
  },
  saved: {
    href: '/routes/saved',
    title: 'Saved',
  },
  signin: {
    href: ROUTES.LOGIN,
    title: 'Login',
  },
  twitter: {
    href: 'https://twitter.com',
    openInNewTab: true,
    title: '',
  },
};

export const userLinks = (authContext: IAuthProvider): INavLinkType[] => [
  ...((authContext.isAdmin() && [
    { href: '/featured-regions/create', title: 'Create Guidebook' },
    { href: '/activities/create', title: 'Create Activity' },
  ]) || [{ href: '/activities/create', title: 'Create Activity' }]),
];

export const COMMON_LINKS = [
  {
    href: ROUTES.EXPLORE,
    title: 'Explore',
  },
  {
    href: ROUTES.ACTIVITIES,
    title: 'Activities',
  },
  {
    href: ROUTES.FEATURED_REGIONS,
    title: 'Guidebook',
  },
  {
    href: ROUTES.BLOG,
    target: '_blank',
    title: 'Blog',
  },
];
