import { css } from 'emotion';
import { EColors } from '../../../../styles/theme/v2/colors';

const iconStyles = css`
  color: white;
  height: 3.2rem;
  width: 3.2rem;
`;

const iconOutline = css`
  border: 1px solid white;
  border-radius: 50px;
  width: 6.4rem;
  height: 6.4rem;

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: white;
  }

  &:hover {
    border: 1px solid ${EColors.grey_A04};

    svg {
      color: ${EColors.grey_A04};
    }
  }
`;

const imageUpload = css`
  position: relative;
  height: 25rem;
`;

const imageUploadBackground = css`
  height: 25rem;
  width: 100%;
  object-fit: cover;
`;

const imageUploadContainer = css`
  cursor: pointer;

  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
`;

const imagePreviewOverlay = css`
  cursor: pointer;

  position: absolute;
  left: 0;
  opacity: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: ${EColors.darkGrey_A06};

  &:hover {
    opacity: 1;
  }
`;

const imagePreview = css`
  height: 25rem;
  width: 100%;
  object-fit: cover;
`;

const imagePreviewContainer = css`
  position: relative;
  height: 25rem;
`;

export const baseStyles = {
  iconOutline,
  iconStyles,
  imagePreview,
  imagePreviewContainer,
  imagePreviewOverlay,
  imageUpload,
  imageUploadBackground,
  imageUploadContainer,
};
