import React, { Fragment } from 'react';
import { css, cx } from 'emotion';

import { IDividerProps } from './types';

import { EColors } from '../../../styles/theme/v2/colors';

const Divider = (props: IDividerProps) => {
  const { className } = props;

  return (
    <Fragment>
      <hr
        className={cx(
          css`
            display: block;
            width: 100%;
            margin-bottom: 2rem;
            border-top: solid 0.03rem ${EColors.grey_300};
            opacity: 0.5;

            hr {
              display: none;
            }
          `,
          className,
        )}
      />
    </Fragment>
  );
};

export default Divider;
