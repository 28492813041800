import isEmpty from 'lodash/isEmpty';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../../constants/routes';
import Button from '../../../elements/Button';
import { ButtonColor } from '../../../elements/Button/types';
import FormInput from '../../../elements/Form/FormInput';

import { IAuthFormProps, IAuthFormState, initialFormState } from './types';

import stylesheet from './AuthForm.scss';
import validation from './validation';

const AuthForm = (props: IAuthFormProps) => {
  const { onSave, isRegister = false, serverErrors } = props;
  const [authForm, setAuthForm] = useState(initialFormState);
  const [errors, setErrors] = useState<boolean | string | {}>();
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    console.log({ serverErrors });

    if (errors && serverErrors) {
      setErrors(errors);
    } else if (serverErrors) {
      setErrors(true);
    }
  }, [serverErrors, errors]);

  const onSubmit = async () => {
    const formValidation = isRegister
      ? validation.validateRegister(authForm)
      : validation.validateSignIn(authForm);

    if (formValidation.hasNoErrors) {
      setIsSaving(true);
      setErrors(null);
      await onSave(authForm);

      setIsSaving(false);
    }

    setErrors(formValidation.errors);
  };

  const buttonText = isRegister ? 'Register' : 'Login';

  const onChangeAuthForm = (obj: Partial<IAuthFormState>) => {
    return setAuthForm(Object.assign({}, authForm, obj));
  };

  return (
    <div className="vx-auth-form vx-auth-form__mobile">
      <style dangerouslySetInnerHTML={{ __html: stylesheet }} />
      <form className="vx-form">
        {isRegister && (
          <FormInput
            labelText="Full Name"
            name="name"
            onChange={e => onChangeAuthForm({ name: e.target.value })}
            placeholder="Johnny Otto"
            value={authForm.name}
            errors={errors}
          />
        )}
        <FormInput
          labelText="Email"
          type="email"
          name="email"
          onChange={e => onChangeAuthForm({ email: e.target.value })}
          placeholder="user@example.com"
          value={authForm.email}
          errors={errors}
        />
        <FormInput
          labelText="Password"
          type="password"
          name="password"
          onChange={e => onChangeAuthForm({ password: e.target.value })}
          placeholder="password"
          value={authForm.password}
          errors={errors}
        />
        {isRegister && (
          <FormInput
            labelText="Re-enter password"
            type="password"
            name="passwordConfirmation"
            onChange={e =>
              onChangeAuthForm({ passwordConfirmation: e.target.value })
            }
            placeholder="re-enter password"
            value={authForm.passwordConfirmation}
            errors={errors}
          />
        )}
        {(errors || !isEmpty(errors)) && (
          <span className="vx-form__label--error">
            {isRegister ? '' : 'Invalid email or password'}
          </span>
        )}
        <Button
          className="vx-auth-form__action"
          type="button"
          disabled={isSaving}
          onClick={onSubmit}
          color={ButtonColor.PRIMARY}
        >
          {buttonText}
        </Button>
        {isRegister ? (
          <p className="vx-auth-form__info-text">
            Already have an account?{' '}
            <Link className="vx-auth-form__info-text" to={ROUTES.LOGIN}>
              login
            </Link>{' '}
          </p>
        ) : (
          <p className="vx-auth-form__info-text">
            Don&apos;t have an account?{' '}
            <Link className="vx-auth-form__info-text" to={ROUTES.REGISTER}>
              Sign up
            </Link>{' '}
          </p>
        )}
      </form>
    </div>
  );
};

export default AuthForm;
