import { css } from 'emotion';
import * as React from 'react';

interface IProps {
  message: string;
}

const ErrorMessage = ({ message }: IProps) => (
  <aside
    data-test="apollo-error"
    className={css`
      text-align: center;
      padding: 1.5em;
      font-size: 14px;
      color: black;
      background-color: rgba(grey, 0.2);
    `}
  >
    {message}
  </aside>
);

export default ErrorMessage;
