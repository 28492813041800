import gql from 'graphql-tag';
import React, { useContext, useState } from 'react';
import { useQuery } from 'react-apollo-hooks';
import Button from '../../../../components/elements/Button';
import ErrorMessage from '../../../../components/elements/ErrorMessage';
import FormInput from '../../../../components/elements/Form/FormInput';
import { ROUTES } from '../../../../constants/routes';
import { useInviteUserMutation } from '../../../../generated/models';
import auth from '../../../../utils/auth';
import { AuthContext } from '../../../../providers/AuthProvider';
import SettingsDropdown from './settingsDropdown';

import './settings.scss';

const INVITED_USERS = gql`
  query InvitedUsers {
    me {
      id
      invitedUsers {
        id
        email
      }
    }
  }
`;

const Settings = () => {
  const initialState = {
    inviteeOne: undefined,
    inviteeThree: undefined,
    inviteeTwo: undefined,
  };
  const [formData, setFormData] = useState(initialState);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState(undefined);
  const authContext = useContext(AuthContext);
  const currentUser = authContext.user;

  // TODO convert to generated useInvitedUsersQuery
  const {
    error: invitedUsersError,
    data: invitedUsers,
    refetch: refetchInvitedUsers,
  } = useQuery(INVITED_USERS);

  if (invitedUsersError) {
    setErrorMessage('Failed to fetch invited users');
  }

  const [sendInvitation] = useInviteUserMutation();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const emails: string[] = Object.keys(formData)
      .filter(key => formData[key])
      .map(key => formData[key]);

    const promises = emails.map(email =>
      sendInvitation({
        variables: {
          data: {
            email,
            invitedById: currentUser.id,
          },
        },
      }),
    );
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const data = await Promise.all(promises);

      // Cache changed, update it
      refetchInvitedUsers();
      // Reset form data. Data is now flowing from backend
      setFormData(initialState);
      // Display success message
      setSuccessMessage('Invitations sent!');
    } catch (error) {
      setErrorMessage(error);
    }
  };

  const logOut = () => {
    auth.removeAuthTokens();
    authContext.removeUser();

    window.location.pathname = ROUTES.HOME;
  };

  return (
    <div className="vx-settings">
      {currentUser.type === 'GUIDE' && (
        <>
          <SettingsDropdown title="Invite Guides">
            <form
              className="vx-profile__content-row vx-invite-guides"
              autoComplete="off"
            >
              <FormInput
                inputClassName="vx-invite-guides__input"
                name="inviteeOne"
                placeholder="E-mail"
                type="email"
                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                  setFormData({
                    ...formData,
                    inviteeOne: e.currentTarget.value,
                  })
                }
                value={
                  invitedUsers?.me.invitedUsers[0]?.email || formData.inviteeOne
                }
                disabled={invitedUsers?.me.invitedUsers[0]?.email}
                autoComplete="off"
              />
              <FormInput
                inputClassName="vx-invite-guides__input"
                name="inviteeTwo"
                placeholder="E-mail"
                type="email"
                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                  setFormData({
                    ...formData,
                    inviteeTwo: e.currentTarget.value,
                  })
                }
                value={
                  invitedUsers?.me.invitedUsers[1]?.email || formData.inviteeTwo
                }
                disabled={invitedUsers?.me.invitedUsers[1]?.email}
                autoComplete="off"
              />
              <FormInput
                inputClassName="vx-invite-guides__input"
                name="inviteeThree"
                placeholder="E-mail"
                type="email"
                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                  setFormData({
                    ...formData,
                    inviteeThree: e.currentTarget.value,
                  })
                }
                value={
                  invitedUsers?.me.invitedUsers[2]?.email ||
                  formData.inviteeThree
                }
                disabled={invitedUsers?.me.invitedUsers[2]?.email}
                autoComplete="off"
              />
              <Button
                className="vx-invite-guides__submit-button"
                onClick={handleSubmit}
              >
                Send Invitations
              </Button>
              {successMessage && (
                <span className="vx-invite-guides__success-message">
                  {successMessage}
                </span>
              )}
              {errorMessage && <ErrorMessage message={errorMessage} />}
            </form>
          </SettingsDropdown>
          <hr />
        </>
      )}

      <Button className="vx-settings__logout-button" onClick={logOut}>
        Logout
      </Button>
    </div>
  );
};

export default Settings;
