import * as React from 'react';

import classNames from 'classnames';
import gql from 'graphql-tag';
import { createRef, Fragment, useState } from 'react';
import { Mutation } from 'react-apollo';
import { List } from 'react-content-loader';

import { Link } from 'react-router-dom';
import { useUserWithStatsQuery } from '../../generated/models';
import { IProfileProps } from './types';

import EditIcon from '../../assets/icons/edit.svg';

import { ROUTES } from '../../constants/routes';
import { AuthContext } from '../../providers/AuthProvider';

import LoadingIcon from '../../components/elements/LoadingIcon';
import CreatedRoutes from './sub-views/createdRoutes/createdRoutes';
import Saved from './sub-views/saved/saved';
import Settings from './sub-views/settings/settings';
import Stats from './sub-views/stats/stats';

import BackgroundLabel from '../../components/elements/BackgroundLabel';
import './profile.scss';

const UPLOAD_MUTATION = gql`
  mutation uploadProfileImage($file: Upload!) {
    uploadProfileImage(file: $file) {
      profileImage {
        url
      }
    }
  }
`;

const Profile = (props: IProfileProps) => {
  const { isModal, onClose } = props;
  const [activeTab, setActiveTab] = useState(0);
  const inputOpenFileRef: React.RefObject<HTMLInputElement> = createRef();
  const userStats = useUserWithStatsQuery();
  const authContext = React.useContext(AuthContext);

  if (userStats.loading) {
    return <LoadingIcon message="Loading profile data" />;
  }

  if (!userStats || !userStats.data || !userStats.data.me) {
    return null;
  }

  const {
    data: { me },
  } = userStats;

  const currentUser = authContext.user;

  const showOpenFileDlg = () => {
    inputOpenFileRef.current.click();
  };

  const uploadImage = (event, uploadMutation) => {
    event.preventDefault();

    const files = event.target.files;
    const file = files[0];

    uploadMutation({
      variables: {
        file,
      },
    }).catch(error => {
      // eslint-disable-next-line no-console
      console.log(error);
    });
  };

  return (
    <div className={classNames('vx-profile', { 'vx-profile--modal': isModal })}>
      <Fragment>
        <div className="vx-profile__name">
          <Link to={ROUTES.PROFILE} onClick={onClose}>
            <BackgroundLabel
              title={(currentUser && currentUser.name) || 'User'}
            />
          </Link>
        </div>
        {activeTab === 3 ? (
          <div className="vx-profile-header">
            <Mutation
              mutation={UPLOAD_MUTATION}
              onCompleted={() => {
                window.location.reload();
              }}
            >
              {uploadFile => (
                <Fragment>
                  <input
                    ref={inputOpenFileRef}
                    type="file"
                    style={{ display: 'none' }}
                    accept="image/*"
                    onChange={event => uploadImage(event, uploadFile)}
                  />
                  <div style={{ marginBottom: '5rem', marginTop: '-70px' }}>
                    <button
                      className="vx-profile-header__image-container"
                      onClick={showOpenFileDlg}
                    >
                      <img
                        className="vx-profile-header__image"
                        src={currentUser.profileImage.url}
                        height="15px"
                        width="auto"
                        alt="logo"
                      />
                      <div
                        className={classNames('vx-profile-header__overlay', {
                          'vx-profile-header__overlay--modal': isModal,
                        })}
                      >
                        <EditIcon className="vx-profile-header__icon" />
                      </div>
                    </button>
                  </div>
                </Fragment>
              )}
            </Mutation>
          </div>
        ) : (
          <div className="vx-profile-header">
            <div style={{ marginBottom: '5rem', marginTop: '-70px' }}>
              <div className="vx-profile-header__image-container">
                <img
                  className="vx-profile-header__image"
                  src={currentUser.profileImage.url}
                  height="15px"
                  width="auto"
                  alt="logo"
                />
              </div>
            </div>
          </div>
        )}
        <hr />
        <div>
          {me ? (
            <>
              <div className="vx-profile__tab-bar">
                <div
                  className={classNames('vx-profile__tab', {
                    'vx-profile__tab--active': activeTab === 0,
                  })}
                  onClick={() => setActiveTab(0)}
                >
                  <span>Stats</span>
                  <hr />
                </div>
                <div
                  className={classNames('vx-profile__tab', {
                    'vx-profile__tab--active': activeTab === 1,
                  })}
                  onClick={() => setActiveTab(1)}
                >
                  <span>Saved</span>
                  <hr />
                </div>
                <div
                  className={classNames('vx-profile__tab', {
                    'vx-profile__tab--active': activeTab === 2,
                  })}
                  onClick={() => setActiveTab(2)}
                >
                  <span>Created</span>
                  <hr />
                </div>
                <div
                  className={classNames('vx-profile__tab', {
                    'vx-profile__tab--active': activeTab === 3,
                  })}
                  onClick={() => setActiveTab(3)}
                >
                  <span>Settings</span>
                  <hr />
                </div>
              </div>

              {activeTab === 0 && <Stats stats={me.stats} />}
              {activeTab === 1 && <Saved onClose={onClose} />}
              {activeTab === 2 && <CreatedRoutes onClose={onClose} />}
              {activeTab === 3 && <Settings />}
            </>
          ) : (
            <Fragment>
              <div className="vx-profile__title">All Time Stats</div>
              <div className="vx-profile__stats">
                <div className="vx-row center">
                  {[0, 1].map(i => (
                    <div key={`row1-${i}`} className="vx-column">
                      <List height={160} />
                    </div>
                  ))}
                </div>
                <div className="vx-row center">
                  {[2, 3].map(i => (
                    <div key={`row1-${i}`} className="vx-column">
                      <List animate height={160} />
                    </div>
                  ))}
                </div>
              </div>
              <div className="vx-profile__title">Trip Stats</div>
              <div className="vx-profile__stats">
                <div className="vx-row">
                  {[0, 1].map(i => (
                    <div key={`row2-${i}`} className="vx-column">
                      <List animate height={160} />
                    </div>
                  ))}
                </div>
                <div className="vx-row">
                  {[3, 4].map(i => (
                    <div key={`row2-${i}`} className="vx-column">
                      <List animate height={160} />
                    </div>
                  ))}
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </Fragment>
    </div>
  );
};

export default Profile;
