import React from 'react';

import Switch from 'react-switch';
import FormErrorLabel from '../../FormErrorLabel';
import { baseStyles } from './styles';
import { IFormToggleInlineLabelProps } from './types';
import InlineLabel from '..';

const FormToggleInlineLabel = (props: IFormToggleInlineLabelProps) => {
  const { container, labelStyles } = baseStyles;
  const {
    value = true,
    disabled,
    name,
    onChange,
    labelText,
    error,
    required,
  } = props;

  return (
    <>
      <div className={container}>
        <InlineLabel
          className={labelStyles}
          name={name}
          required={required}
          labelText={labelText}
        />
        <Switch
          checked={value}
          checkedIcon={false}
          disabled={disabled}
          name={name}
          onChange={onChange}
          onColor="#ffc494"
          offColor="#9b9b9b"
          uncheckedIcon={false}
        />
      </div>
      {error && <FormErrorLabel title={error} />}
    </>
  );
};

export default FormToggleInlineLabel;
