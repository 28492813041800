import validations from '../../../../utils/validations';

export interface IValidation {
  errors: any;
  hasNoErrors: boolean;
}

function validateSignIn(formData) {
  const validation: IValidation = {
    errors: {},
    hasNoErrors: true,
  };

  if (!formData) {
    validation.errors = 'Cannot submit empty form';
  }

  const keys = Object.keys(formData);

  keys.forEach(key => {
    if (!formData[key]) {
      if (key === 'name' || key === 'passwordConfirmation') {
        return;
      }
      validation.errors[key] = 'Field cannot be empty';
    }
  });

  if (Object.keys(validation.errors).length > 0) {
    validation.hasNoErrors = false;
  }

  return validation;
}

function validateRegister(formData) {
  const { email } = formData;

  const validation: IValidation = {
    errors: {},
    hasNoErrors: true,
  };

  if (!formData) {
    validation.errors = 'Cannot submit empty form';
  }

  const keys = Object.keys(formData);

  keys.forEach(key => {
    if (!formData[key]) {
      validation.errors[key] = 'Field cannot be empty';
    }
  });

  if (formData.password !== formData.passwordConfirmation) {
    validation.errors.passwordConfirmation = 'Passwords do not match';
  }

  if (!validations.validateEmail(email)) {
    validation.errors.email = 'Invalid email address';
  }

  if (Object.keys(validation.errors).length > 0) {
    validation.hasNoErrors = false;
  }

  return validation;
}

export default {
  validateRegister,
  validateSignIn,
};
