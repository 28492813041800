import React, { HTMLProps } from 'react';

import { IFormInputInlineLabelProps } from './types';

import FormErrorLabel from '../FormErrorLabel';
import baseStyles from './styles';
const FormInputInlineLabel = (
  props: IFormInputInlineLabelProps & HTMLProps<HTMLInputElement>,
) => {
  const {
    name,
    labelText,
    onChange,
    type = 'text',
    value,
    error,
    required,
    ...rest
  } = props;
  const { container, labelStyles, inputStyles } = baseStyles;

  return (
    <>
      <div className={container}>
        <input
          className={inputStyles}
          type={type}
          name={name}
          onChange={onChange}
          value={value || ''}
          required={required}
          {...rest}
        ></input>
        <label className={labelStyles} htmlFor={name}>
          {required ? `${labelText} *` : labelText}
        </label>
      </div>
      {error && <FormErrorLabel title={error} />}
    </>
  );
};

export default FormInputInlineLabel;
