import * as React from 'react';
import { useDropzone } from 'react-dropzone';

import CloseIcon from '../../../../assets/icons/icon-close.svg';
import EditIcon from '../../../../assets/icons/icon-edit.svg';
import PlusIcon from '../../../../assets/icons/icon-plus.svg';
import BackgroundImage from '../../../../assets/images/hero-vertrax.jpg';

import { baseStyles } from './styles';

const FormImageHeader = (props: any) => {
  const { images, onChange } = props;

  const {
    iconOutline,
    iconStyles,
    imagePreview,
    imagePreviewContainer,
    imagePreviewOverlay,
    imageUpload,
    imageUploadBackground,
    imageUploadContainer,
  } = baseStyles;

  const [featuredImage, setFeaturedImage] = React.useState(images ?? []);
  const [isCancelEvent, setIsCancelEvent] = React.useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setFeaturedImage(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
  });

  React.useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      featuredImage.forEach(file => URL.revokeObjectURL(file?.preview));
    },
    [featuredImage],
  );

  const thumbs = featuredImage.map((file, index) => (
    <div key={file?.name ?? index}>
      <div className={imagePreviewContainer}>
        <img
          className={imagePreview}
          width={'100%'}
          src={file ? file?.preview ?? file?.url : null}
        />
        <div className={imagePreviewOverlay}>
          <div className={iconOutline}>
            <CloseIcon
              className={iconStyles}
              onClick={e => {
                setIsCancelEvent(true);
                setFeaturedImage([]);
                e.stopPropagation();
              }}
            />
          </div>
          <div className={iconOutline}>
            <EditIcon className={iconStyles} />
          </div>
        </div>
      </div>
    </div>
  ));

  return (
    <div
      {...getRootProps({
        className: 'dropzone',
        onClick: () => {
          if (isCancelEvent) {
            setIsCancelEvent(false);
          }
        },
      })}
    >
      <input
        {...getInputProps({
          onChange,
        })}
      />
      {featuredImage.length > 0 ? (
        <div>{thumbs}</div>
      ) : (
        <div className={imageUpload}>
          <img className={imageUploadBackground} src={BackgroundImage} />
          <div className={imageUploadContainer}>
            <div className={iconOutline}>
              <PlusIcon />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FormImageHeader;
