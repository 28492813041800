import React from 'react';
import Modal from '../../../elements/Modal';
import RouteForm from '../../Forms/RouteForm';

import { baseStyles } from './styles';
import { ISaveRouteModalProps } from './types';

const SaveRouteModal = (props: ISaveRouteModalProps) => {
  const { isOpen, onClose, route, onCancel, onSave, onError } = props;
  const { modalOverrides } = baseStyles;

  return (
    <Modal isOpen={isOpen} onClose={onClose} className={modalOverrides}>
      <RouteForm
        route={route}
        onCancel={onCancel}
        onSave={onSave}
        onError={onError}
      />
    </Modal>
  );
};

export default SaveRouteModal;
