import * as React from 'react';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { isDev } from '../../../constants';
import { ROUTES } from '../../../constants/routes';
import Login from '../login';
import SignUp from '../signup';
import { IAuthProps, IAuthState } from './types';

class Auth extends Component<IAuthProps, IAuthState> {
  public state = {
    isRegister: false,
  };

  public static getDerivedStateFromProps(props) {
    const { isRegister, currentUser, history } = props;

    if (currentUser && currentUser.id) {
      const prevUrl = isDev
        ? document.referrer.replace('http://localhost:5000', '')
        : document.referrer.replace('https://vertrax.io', '');

      history.replace(prevUrl ?? ROUTES.EXPLORE);
    }

    return { isRegister };
  }

  public showForm() {
    const { isRegister } = this.state;

    if (isRegister) {
      return <SignUp />;
    }

    return <Login />;
  }

  public render() {
    return <div>{this.showForm()}</div>;
  }
}

export default withRouter(Auth as any);
