import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import Divider from '../../../elements/Divider';
import { Paragraph } from '../../../elements/Paragraph';

import { AuthContext } from '../../../../providers/AuthProvider';
import Button from '../../../elements/Button';

import IconNavigation from '../../../../assets/icons/icon-navigation.svg';
import IconSaveFilled from '../../../../assets/icons/icon-save-filled.svg';
import IconSave from '../../../../assets/icons/icon-save.svg';
import IconShare from '../../../../assets/icons/icon-share.svg';
import IconView from '../../../../assets/icons/icon-show.svg';
import { ROUTES } from '../../../../constants/routes';
import RouteDetailModal from '../../Modals/RouteDetailModal';

import {
  usePinRouteMutation,
  useUnPinRouteMutation,
} from '../../../../generated/models';

interface IExploreCardExpansionProps {
  description?: string;
  route: any;
}

const ExploreCardExpansion = (props: IExploreCardExpansionProps) => {
  const { route, description } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isPinned, setIsPinned] = useState(route.isPinnedBy);

  const [pinRouteMutation] = usePinRouteMutation({
    variables: { id: route.id },
  });
  const [unPinRouteMutation] = useUnPinRouteMutation({
    variables: { id: route.id },
  });

  const authContext = useContext(AuthContext);

  const content = description
    ? description
    : 'A description for this activity has not been provided';

  const handleSave = () => {
    if (!authContext.isLoggedIn()) {
      // Maybe to prevent shitty route props...
      window.location.pathname = ROUTES.LOGIN;
      // return history.push(ROUTES.LOGIN);
    }

    if (route.isPinnedBy) {
      unPinRouteMutation().then(() => {
        setIsPinned(false);
      });
    } else {
      pinRouteMutation().then(() => {
        setIsPinned(true);
      });
    }
  };

  return (
    <div className="vx-explore-expansion">
      <RouteDetailModal
        route={route}
        isOpen={isModalVisible}
        onClose={() => setIsModalVisible(false)}
      />
      <div className="flex justify-between mb4">
        <Link to={`/routes/${route.id}`}>
          <Button className="expansion-mini--btn">
            <IconView className="expansion-mini--svg" />
          </Button>
        </Link>
        <Button className="expansion-mini--btn">
          <IconNavigation className="expansion-mini--svg" />
        </Button>
        <Button className="expansion-mini--btn" onClick={handleSave}>
          {isPinned ? (
            <IconSaveFilled className="expansion-mini--svg" />
          ) : (
            <IconSave className="expansion-mini--svg" />
          )}
        </Button>
        <Button className="expansion-mini--btn">
          <IconShare className="expansion-mini--svg" />
        </Button>
      </div>
      <h4 className="mb3">Description</h4>
      <Divider />
      <Paragraph content={content} />

      {authContext.isAdmin() && (
        <Link to={`/routes/${route.id}/edit`}>
          <h4 className="vx-explore-expansion__update-route">Edit Route</h4>
        </Link>
      )}

      <Link to={`/routes/${route.id}`}>
        <h4 className="vx-explore-expansion__update-route">View More</h4>
      </Link>
    </div>
  );
};

export default ExploreCardExpansion;
