import React from 'react';
import styles from './styles';

const FormErrorLabel = ({ title }: { title: string }) => {
  const { errorLabel } = styles;

  return <span className={errorLabel}>{title}</span>;
};

export default FormErrorLabel;
