import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { StoryContainer } from '../components/modules/Story';

import PrivateRoute from '../components/PrivateRoute';
import { ROUTES } from '../constants/routes';
import Activity from './activities/details';
import Activities from './activities/index/index';
import Auth from './auth/Auth';
import ConfirmInvitation from './auth/confirmInvitation';
import Explore from './explore';

import LandingPage from './landing';
import PrivacyPolicy from './PrivacyPolicy';
import Profile from './profile';
import RouteBuilderCreate from './routeBuilder/create';
import RouteBuilderEdit from './routeBuilder/edit';
import CreatedRoutes from './routes/created';
import RouteDetails from './routes/details';
import SavedRoutes from './routes/saved';

const Main = ({ user }: any) => (
  <main className="vx-main">
    <Switch>
      <Route exact path="/" component={LandingPage} />
      <PrivateRoute
        path={ROUTES.PROFILE}
        currentUser={user}
        component={Profile}
      />
      <PrivateRoute
        path="/routes/new"
        currentUser={user}
        component={RouteBuilderCreate}
      />
      <PrivateRoute
        path="/routes/:id/edit"
        currentUser={user}
        component={RouteBuilderEdit}
      />
      <Route path={'/routes/saved'} component={SavedRoutes} />
      <Route path={'/routes/my-routes'} component={CreatedRoutes} />
      <Route path={`${ROUTES.ROUTES}/:id`} component={RouteDetails} />
      <Route path={`${ROUTES.ACTIVITIES}/:id`} component={Activity} />
      <Route path={ROUTES.ACTIVITIES} component={Activities} />
      <Route path={`${ROUTES.GUIDES}/:id`} component={StoryContainer} />
      <Route
        path="/blog"
        component={() => {
          window.location.href = 'https://blog.vertrax.io';

          return null;
        }}
      />{' '}
      <Route path="/explore" component={Explore} />
      <Route
        path={ROUTES.LOGIN}
        render={() => {
          // @ts-ignore
          return <Auth currentUser={user} />;
        }}
      />
      <Route
        path="/register"
        // @ts-ignore
        render={() => <Auth currentUser={user} isRegister />}
      />
      <Route
        path={ROUTES.CONFIRM_INVITATION}
        // @ts-ignore
        component={ConfirmInvitation}
      />
      {/* <Route
        path={ROUTES.INVITATION_EXPIRED}
        // @ts-ignore
        component={InvitationExpired}
      /> */}
      <Route path="/privacy-policy" component={PrivacyPolicy} />
      <Route
        path={ROUTES.CONFIRM_INVITATION}
        // @ts-ignore
        component={ConfirmInvitation}
      />
    </Switch>
  </main>
);

export default Main;
