import cookie from 'cookie';
import Cookies from 'js-cookie';

const removeAuthTokens = () => {
  document.cookie = cookie.serialize('accessToken', '', {
    maxAge: -1, // Expire the cookie immediately
    path: '/',
  });
  document.cookie = cookie.serialize('refreshToken', '', {
    maxAge: -1, // Expire the cookie immediately
    path: '/',
  });
};

const saveAuthTokens = (accessToken: string, refreshToken: string) => {
  Cookies.set('accessToken', accessToken, {
    expires: 0.1,
  });
  Cookies.set('refreshToken', refreshToken, { expires: 30 });
};

const getAccessToken = () => {
  return Cookies.get('accessToken');
};

const getRefreshToken = () => {
  return Cookies.get('refreshToken');
};

export default {
  getAccessToken,
  getRefreshToken,
  removeAuthTokens,
  saveAuthTokens,
};
