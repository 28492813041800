import * as React from 'react';
import { Component, Fragment } from 'react';

import { StaticMap } from 'react-map-gl';

import PolylineOverlay from '../../../components/Maps/components/PolylineOverlay/PolylineOverlay';
import { MAPBOX_TOKEN } from '../../../constants';

import LoadingIcon from '../../../components/elements/LoadingIcon';
import { Paragraph } from '../../../components/elements/Paragraph';
import ActivityDetailCharts from '../../../components/modules/Charts/ActivityDetailCharts';
import { convertToHoursAndMins } from '../../../utils/time';

import { ActivityDetailsViewComponent } from '../../../generated/models';

import './details.scss';

interface IActivityProps {
  location: string;
}

interface IActivityState {
  id: string;
}

class Activity extends Component<IActivityProps, IActivityState> {
  public state = {
    id: undefined,
  };

  public static getDerivedStateFromProps(props) {
    const id = props.location.pathname.replace('/activities/', '');

    return { id };
  }

  public render() {
    const { id } = this.state;

    return (
      <div>
        <div className="vx-activity">
          <ActivityDetailsViewComponent
            variables={{
              id,
            }}
          >
            {({ data, loading, error, networkStatus }) => {
              if (loading && networkStatus !== 3) {
                return <LoadingIcon />;
              }

              // Show Error View
              if (error) {
                return <div>Something went wrong</div>;
              }

              const { activity } = data;
              const { streams } = activity;

              return (
                <Fragment>
                  <div
                    className="map-overlay"
                    style={{ height: '75vh', width: '100%' }}
                  >
                    {activity.featuredImage ? (
                      <img
                        src={activity.featuredImage.url}
                        height={'100%'}
                        width={'100%'}
                      />
                    ) : (
                      <StaticMap
                        {...{
                          bearing: 0,
                          latitude: activity.polyline.coordinates[0][1],
                          longitude: activity.polyline.coordinates[0][0],
                          pitch: 60,
                          zoom: 11,
                        }}
                        width="100%"
                        height="100%"
                        attributionControl={false}
                        mapStyle="mapbox://styles/vertrax/ck433h4vb1hfq1do08ybntxza"
                        mapboxApiAccessToken={MAPBOX_TOKEN}
                      >
                        <PolylineOverlay
                          points={activity.polyline.coordinates}
                        />
                      </StaticMap>
                    )}
                  </div>
                  <div className="vx-activity__hero-container">
                    <h1>{activity.title}</h1>
                    <div>{activity.activityType}</div>
                  </div>
                  <hr />

                  <div className="vx-activity__container">
                    {activity.description && (
                      <div className="vx-activity__description">
                        <h3>Trip Notes</h3>
                        <Paragraph
                          content={
                            activity.description ? activity.description : ''
                          }
                        />
                      </div>
                    )}
                    <div className="vx-activity__stats-container">
                      <h3>Trip Stats</h3>
                      <div className="vx-activity__stats">
                        <div className="vx-activity__stat">
                          <h2>
                            {convertToHoursAndMins(
                              activity.stats.totalDuration,
                            )}
                          </h2>
                          <p>Total Duration</p>
                        </div>
                        <div className="vx-activity__stat">
                          <h2>{activity.stats.totalAscent} m</h2>
                          <p>Total ascent</p>
                        </div>
                        <div className="vx-activity__stat">
                          <h2>{activity.stats.totalDistance} km</h2>
                          <p>Total distance</p>
                        </div>
                      </div>
                      <div className="vx-activity__graph-container">
                        <ActivityDetailCharts streams={streams} />
                      </div>
                    </div>
                  </div>
                </Fragment>
              );
            }}
          </ActivityDetailsViewComponent>
        </div>
      </div>
    );
  }
}

export default Activity;
