import kyrkja from "./kyrkja";
import midtreHogvalgtiden from "./mh";
import semelholstiden from "./semelholstiden";
import stetind from "./stetind";
import storeBjorn from "./store-bjorn";
import surtningstiden from "./surtningstiden";

export default {
  kyrkja,
  midtreHogvalgtiden,
  semelholstiden,
  stetind,
  storeBjorn,
  surtningstiden
};
