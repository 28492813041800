import React from 'react';

import isEmpty from 'lodash/isEmpty';
import { ActivityTypes } from '../../../../assets/data/activityTypes';
import { ApproachOptions } from '../../../../assets/data/approachOptions';

import Button from '../../../elements/Button';
import FormImageHeader from '../../../elements/Form/FormImageHeader';
import FormInputInlineLabel from '../../../elements/Form/FormInputInlineLabel';
import FormTextArea from '../../../elements/Form/FormTextArea';
import FormSelectInlineLabel from '../../../elements/Form/InlineLabel/Select';
import FormToggleInlineLabel from '../../../elements/Form/InlineLabel/Toggle';

import { CreateRouteInput, Route } from '../../../../generated/models';

import { AuthContext } from '../../../../providers/AuthProvider';
import { RegionInfoContext } from '../../../../providers/RegionInfoProvider';
import parseData from '../../../../utils/parseData';
import { formStyles } from './styles';
import { validateRouteForm } from './utils';
import { IRouteFormProps } from './types';

const RouteForm = (props: IRouteFormProps) => {
  const { onCancel, onSave, route } = props;
  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const [formErrors, setFormErrors] = React.useState<any>({});
  const [routeData, setRouteData] = React.useState<CreateRouteInput>(
    route ?? {},
  );

  const [isPublished, setIsPublished] = React.useState<boolean>(
    route?.isPublished ?? false,
  );

  const routeInfoContext = React.useContext(RegionInfoContext);
  const authContext = React.useContext(AuthContext);
  const { container, form, sectionLabel, formFooter, textArea } = formStyles;

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [!isEmpty(formErrors)]);

  const onFormChange = (obj: Partial<Route>) =>
    setRouteData(Object.assign({}, routeData, obj));

  const handleSubmit = async e => {
    e.preventDefault();
    setIsSaving(true);

    const newRoute = parseData.updateRouteData({ ...routeData, isPublished });
    const validations = validateRouteForm(newRoute);

    if (isEmpty(validations)) {
      await onSave(newRoute);
    } else {
      setFormErrors(validations);
    }
  };

  return (
    <div className={container}>
      <form className={`${form}`} onSubmit={handleSubmit}>
        <FormImageHeader
          name="featuredImage"
          images={routeData?.featuredImage ? [routeData.featuredImage] : null}
          onChange={e => onFormChange({ featuredImage: e.target.files })}
        />

        <div className="mh3">
          <p className={sectionLabel}>Activity Type</p>
          <FormSelectInlineLabel
            required
            name="activityType"
            options={ActivityTypes}
            labelText="Activity Type"
            value={routeData?.activityType}
            onChange={e => onFormChange({ activityType: e.target.value })}
            error={formErrors.activityType}
          />
          <p className={sectionLabel}>Route Description</p>
          <FormInputInlineLabel
            required
            name="title"
            labelText="Route Title"
            onChange={e => onFormChange({ title: e.target.value })}
            value={routeData?.title}
            error={formErrors.title}
          />
          <FormSelectInlineLabel
            name="approach"
            options={ApproachOptions}
            labelText="Route Approach"
            value={routeData?.approach}
            onChange={e => onFormChange({ approach: e.target.value })}
          />

          <FormTextArea
            textAreaClassName={textArea}
            name="description"
            onChange={e => onFormChange({ description: e.target.value })}
            value={routeData?.description}
            placeholder="Start by describing where the exact starting point for the route, and any visual markers that may help. Followed by the best time in the year to come and try to attempt it."
          />

          <p className={sectionLabel}>Route Location</p>
          <FormInputInlineLabel
            readOnly
            name="regionName"
            labelText="Region"
            value={routeInfoContext.county}
            onChange={e => onFormChange({ [e.target.name]: e.target.value })}
          />
          <FormInputInlineLabel
            readOnly
            name="countryName"
            labelText="Country"
            value={routeInfoContext.country}
            onChange={e => onFormChange({ [e.target.name]: e.target.value })}
          />
          <FormInputInlineLabel
            name="mountainName"
            labelText="Mountain"
            value={routeData?.mountainName}
            onChange={e => onFormChange({ [e.target.name]: e.target.value })}
          />

          <p className={sectionLabel}>Publishing Options</p>
          <FormToggleInlineLabel
            name="isPublished"
            labelText="Publish to site"
            labelPosition="left"
            value={isPublished}
            onChange={() => setIsPublished(!isPublished)}
          />
          <FormInputInlineLabel
            name="createdBy"
            labelText="Created By"
            value={authContext?.user?.name ?? ''}
            onChange={e => onFormChange({ [e.target.name]: e.target.value })}
          />
          <FormInputInlineLabel
            name="updatedBy"
            labelText="Updated By"
            value={authContext?.user?.name ?? ''}
            onChange={e => onFormChange({ [e.target.name]: e.target.value })}
          />

          <div className={formFooter}>
            <Button color="white-line" onClick={onCancel} type="button">
              Cancel
            </Button>
            <Button disabled={isSaving} color="white" type="submit">
              Save
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default RouteForm;
