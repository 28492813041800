import { css } from 'emotion';
import React from 'react';
import { IStoryChapter } from '../../../assets/storytelling/stories/types';

export const Chapter = ({
  id,
  theme,
  title,
  image,
  description,
  currentChapterID,
}: IStoryChapter & { currentChapterID: string; theme: 'light' | 'dark' }) => {
  const classList = id === currentChapterID ? 'step active' : 'step';

  return (
    <div id={id} className={classList}>
      <div className={theme}>
        {title && (
          <h3
            className={css`
              margin: 2rem 0;
            `}
          >
            {title}
          </h3>
        )}
        {image && <img src={image} alt={title}></img>}
        {description && <p className="description">{description}</p>}
      </div>
    </div>
  );
};
