import React, { createContext, useEffect, useState } from 'react';
import { useApolloClient } from 'react-apollo-hooks';
import LoadingIcon from '../components/elements/LoadingIcon';
import {
  AccessRole,
  CurrentUserDocument,
  CurrentUserFragment,
  CurrentUserQuery,
} from '../generated/models';

export interface IAuthProvider {
  fetchUser: () => Promise<CurrentUserFragment>;
  isAdmin: () => boolean;
  isLoggedIn: () => boolean;
  isUser: () => boolean;
  removeUser: () => void;
  user: CurrentUserFragment;
}

const defaultValue: IAuthProvider = {
  fetchUser: async () => new Promise(res => res()),
  isAdmin: () => false,
  isLoggedIn: () => false,
  isUser: () => false,
  removeUser: () => null,
  user: null,
};

export const AuthContext = createContext<IAuthProvider>(defaultValue);

export const AuthProvider = (props: any) => {
  const client = useApolloClient();
  const [user, setUser] = useState<CurrentUserFragment>();

  const removeUser = () => setUser(null);
  const fetchUser = () =>
    client
      .query<CurrentUserQuery>({ query: CurrentUserDocument })
      .then(({ data }) => {
        setUser(data.me);

        return data.me;
      });

  const isLoggedIn = () => !!user;
  const isAdmin = () => !!user && user.type === AccessRole.Admin;
  const isUser = () => !!user && user.type === AccessRole.User;

  const [initializing, setInitializing] = useState(true);

  useEffect(() => {
    fetchUser()
      .then(() => setInitializing(false))
      .catch(() => setInitializing(false));
  }, []);

  return (
    <AuthContext.Provider
      value={{ fetchUser, isAdmin, isLoggedIn, isUser, removeUser, user }}
    >
      {initializing ? <LoadingIcon /> : props.children}
    </AuthContext.Provider>
  );
};
