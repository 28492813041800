import classNames from 'classnames';
import { isEmpty } from 'lodash';
import React, { Component, Fragment } from 'react';
import Select from 'react-select';
import Async from 'react-select/async';
import { StylesConfig } from 'react-select/src/styles';
import { ThemeConfig } from 'react-select/src/theme';
// eslint-disable-next-line
import { Theme } from 'react-select/src/types';
import { CommonProps, IFormSelectProps } from './types';

export const formSelectTheme: ThemeConfig = (theme: Theme) => ({
  ...theme,
  borderRadius: 2,
  colors: {
    ...theme.colors,
    danger: 'black',
    dangerLight: 'darkgray',
    neutral20: 'black',
    neutral30: 'black',
    primary: 'black',
    primary25: 'lightgray',
    primary50: 'darkgray',
  },
});

export const formSelectStyles: StylesConfig = {
  control: provided => ({
    ...provided,
    borderRadius: '4px',
    height: '32px',
    minHeight: '32px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: '32px',
  }),
  input: provided => ({
    ...provided,
    height: '32px',
    lineHeight: '18px',
    margin: 0,
    padding: 0,
  }),
  menu: provided => ({
    ...provided,
    zIndex: 800,
  }),
  multiValue: provided => ({
    ...provided,
    margin: '3px',
  }),
  multiValueLabel: provided => ({
    ...provided,
    fontSize: '100%',
  }),
  placeholder: provided => ({
    ...provided,
    top: '50%',
  }),
  singleValue: provided => ({
    ...provided,
    color: 'inherit',
    fontSize: '18px',
    height: '32px',
    marginTop: '32px',
    position: 'relative',
    top: '0',
  }),
  valueContainer: provided => ({
    ...provided,
    height: '32px',
  }),
};

class FormSelect extends Component<IFormSelectProps> {
  public render() {
    const {
      closeMenuOnSelect,
      isClearable,
      errors,
      isMulti,
      isSearchable,
      label,
      labelClassName,
      loadOptions,
      name,
      onChange,
      options,
      placeholder,
      required,
      selectClassName,
      styles = formSelectStyles,
      theme = formSelectTheme,
      value,
    } = this.props;

    const labelClasses = classNames('vx-form__label', labelClassName);
    const selectClasses = classNames('vx-form__select', selectClassName);

    const commonProps: CommonProps = {
      className: selectClasses,
      closeMenuOnSelect,
      isClearable,
      isMulti,
      isSearchable,
      name,
      onChange,
      placeholder,
      styles,
      theme,
    };

    return (
      <Fragment>
        {label && (
          <p className={labelClasses}>{`${label}${required ? '*' : ''}`}</p>
        )}
        {loadOptions ? (
          <Async
            defaultValue={value}
            defaultOptions={options}
            loadOptions={loadOptions}
            {...commonProps}
          />
        ) : (
          <Select value={value} options={options} {...commonProps} />
        )}
        {!isEmpty(errors) && errors[name] && (
          <span className="vx-form__label--error">{errors[name]}</span>
        )}
      </Fragment>
    );
  }
}

export default FormSelect;
