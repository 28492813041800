export interface IAuthFormProps {
  isRegister?: boolean;
  onSave: (authData: IAuthFormState) => Promise<any>;
  serverErrors?: any;
}

export interface IAuthFormState {
  email: string;
  name?: string;
  password: string;
  passwordConfirmation?: string;
}

export const initialFormState: IAuthFormState = {
  email: undefined,
  name: undefined,
  password: undefined,
  passwordConfirmation: undefined,
};
