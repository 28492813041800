import React from 'react';
import { Link } from 'react-router-dom';

import LoadingIcon from '../../../../components/elements/LoadingIcon';
import ExploreCard from '../../../../components/modules/Cards/ExploreCard';
import { ROUTES } from '../../../../constants/routes';
import { useUserSavedRoutesQuery } from '../../../../generated/models';
import { IProfileSavedTabProps } from './types';

const Saved = ({ onClose }: IProfileSavedTabProps) => {
  const trips = useUserSavedRoutesQuery();

  const { data, loading } = trips;

  if (loading) {
    return <LoadingIcon />;
  }

  if (!data || !data.me) {
    return null;
  }

  const routes = data?.me?.savedRoutesConnection?.edges.map(node => node.node);

  if (routes.length > 0) {
    return (
      <div className="vx-profile__scroll-container">
        <div className="vx-row">
          {routes.map(route => (
            <div key={route.id}>
              <Link to={`${ROUTES.EXPLORE}?id=${route.id}`} onClick={onClose}>
                <ExploreCard route={route} size="sm" />
              </Link>
            </div>
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <h3>
          Click <Link to={ROUTES.EXPLORE}>here</Link> to begin planning
        </h3>
      </div>
    );
  }
};

export default Saved;
