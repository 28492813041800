import React, { useEffect, useState } from 'react';

import { find } from 'lodash';
import { useDropzone } from 'react-dropzone';
import { IRouteBuilderBottomMenuProps } from './types';

import Button from '../../../elements/Button';

import DrawPathIcon from '../../../../assets/icons/icon-add-path.svg';
import DistanceIcon from '../../../../assets/icons/icon-arrow-right.svg';
import ElevationIcon from '../../../../assets/icons/icon-ascent.svg';
import ChallengeIcon from '../../../../assets/icons/icon-challenges.svg';
import TimeIcon from '../../../../assets/icons/icon-clock.svg';
import DownloadIcon from '../../../../assets/icons/icon-download.svg';
import SaveIcon from '../../../../assets/icons/icon-save.svg';
import SummerIcon from '../../../../assets/icons/icon-weather-sun.svg';
import WinterIcon from '../../../../assets/icons/icon-weather-wind.svg';

import { ActivityType } from '../../../../generated/models';
import { calculateDuration } from '../../../../utils/time';
import FormSelect from '../../../elements/Form/FormSelect';

import {
  SummerActivityTypes,
  WinterActivityTypes,
} from '../../../../assets/data/activityTypes';

import extractTracks from '../../../../utils/geo-json/file-upload';

import './RouteBuilderBottomMenu.scss';

const RouteBuilderBottomMenu = (props: IRouteBuilderBottomMenuProps) => {
  const {
    activityType,
    onFileUpload,
    onSave,
    routeDistance,
    routeElevation,
    setActivityType,
  } = props;
  const [isUploadRouteActive, setIsUploadRouteActive] = useState(false);
  const [isWinterSportActive, setIsWinterSportActive] = useState(true);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    calculateMovingTime();

    return () => null;
  }, [activityType]);

  const calculateDifficultyScore = () => {
    if (routeDistance > 25 || routeElevation > 4000) {
      return 5;
    }
    if (
      (routeDistance < 25 && routeDistance > 15) ||
      (routeElevation <= 4000 && routeElevation > 2500)
    ) {
      return 4;
    }
    if (
      (routeDistance < 15 && routeDistance > 10) ||
      (routeElevation < 2500 && routeElevation > 1500)
    ) {
      return 3;
    }
    if (
      (routeDistance < 10 && routeDistance > 8) ||
      (routeElevation < 1500 && routeElevation > 500)
    ) {
      return 2;
    }
    if (routeDistance < 8 || routeElevation < 500) {
      return 1;
    }
  };

  const calculateMovingTime = () => {
    // Rough implementation of Naismith's Rule
    // https://en.wikipedia.org/wiki/Naismith%27s_rule

    if (!routeDistance && !routeElevation) {
      return '0:00';
    }

    const dist = routeDistance + (routeElevation / 1000) * 8;

    let avgSpeed; // km per hour
    switch (activityType) {
      case ActivityType.BackcountrySkiing:
      case ActivityType.CrossCountrySkiing:
        avgSpeed = 5;
        break;
      case ActivityType.ResortSkiing:
        avgSpeed = 10;
        break;
      case ActivityType.Biking:
        avgSpeed = 20;
        break;
      case ActivityType.Biking:
      default:
        avgSpeed = 3;
    }

    const hours = dist / avgSpeed;

    return calculateDuration(hours);
  };

  const onFileChange = file => {
    extractTracks(file[0]).then(data => {
      onFileUpload(data[0]);
    });
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    // accept: 'gpx, tcx',
    multiple: false,
    onDrop: onFileChange,
  });

  return (
    <div className="vx-route-builder-menu">
      <div className="vx-route-builder-menu__top" />
      <div className="vx-route-builder-menu__top--row ">
        <span className="vx-route-builder-menu__label">Route Builder</span>
        <div className="flex items-center">
          <span className="vx-route-builder-menu__label">Method</span>
          <div className="vx-route-builder-menu__button-border">
            <Button
              size="sm"
              color={isUploadRouteActive ? 'white-line' : 'primary'}
              onClick={() => setIsUploadRouteActive(false)}
            >
              <DrawPathIcon className="mr2" height={24} width={24} />
              Route Builder
            </Button>
            <span className="mh2 vx-route-builder-menu__label"> or </span>
            <div className="flex">
              <div
                {...getRootProps({
                  className: 'dropzone vx-route-builder-menu__file-upload',
                })}
              >
                {acceptedFiles.length > 0 ? (
                  acceptedFiles[0].name
                ) : (
                  <Button
                    size="sm"
                    color={isUploadRouteActive ? 'primary' : 'white-line'}
                  >
                    <DownloadIcon
                      height={24}
                      width={24}
                      style={{ transform: 'rotate(180deg)' }}
                      className="mr2"
                    />
                    Upload Route
                  </Button>
                )}
                <input
                  className="vx-route-builder-menu__file-upload"
                  {...getInputProps()}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center">
          <span className="vx-route-builder-menu__label">Season</span>
          <div className="vx-route-builder-menu__button-border">
            <Button
              size="sm"
              className="mr2"
              color={isWinterSportActive ? 'primary' : 'white-line'}
              onClick={() => setIsWinterSportActive(true)}
            >
              <WinterIcon className="mr2" height={24} width={24} />
              Winter
            </Button>
            <Button
              size="sm"
              color={isWinterSportActive ? 'white-line' : 'primary'}
              onClick={() => setIsWinterSportActive(false)}
            >
              <SummerIcon className="mr2" height={24} width={24} />
              Summer
            </Button>
          </div>
        </div>
        <div className="flex items-center">
          <span className="vx-route-builder-menu__label">Type: </span>
          <FormSelect
            selectClassName="vx-route-builder-menu__select"
            isClearable={false}
            isSearchable={false}
            options={
              isWinterSportActive ? WinterActivityTypes : SummerActivityTypes
            }
            name="activityType"
            // @ts-ignore
            onChange={e => setActivityType(e.value)}
            value={
              isWinterSportActive
                ? find(WinterActivityTypes, { value: activityType })
                : find(SummerActivityTypes, { value: activityType })
            }
          />
        </div>
      </div>

      <div className="vx-route-builder-menu__middle">
        <div className="vx-route-builder-menu__row">
          <div className="vx-route-builder-menu__row vx-route-builder-menu__stat-container">
            <div className="vx-route-builder-menu__stat">
              <span>Est. Distance</span>
              <div className="vx-route-builder-menu__stat-text">
                <DistanceIcon />
                {routeDistance} km
              </div>
            </div>
            <div className="vx-route-builder-menu__stat">
              <span>Est. Moving Time</span>
              <div className="vx-route-builder-menu__stat-text">
                <TimeIcon height="40px" width="40px" />
                {calculateMovingTime()}
              </div>
            </div>
            <div className="vx-route-builder-menu__stat">
              <span>Elevation Gain</span>
              <div className="vx-route-builder-menu__stat-text">
                <ElevationIcon />
                {routeElevation} m
              </div>
            </div>
            <div className="vx-route-builder-menu__stat">
              <span>Challenge Level</span>
              <div className="vx-route-builder-menu__stat-text">
                <ChallengeIcon height="40px" width="40px" />
                {calculateDifficultyScore()}x
              </div>
            </div>
          </div>
          <div>{/* Insert Graph Here */}</div>
          <Button color="white" size="md" onClick={onSave}>
            <SaveIcon height="24px" width="24px" />
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RouteBuilderBottomMenu;
