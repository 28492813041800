import React, { useContext } from 'react';

import { NAV_LINKS, ROUTES, userLinks } from '../../../constants/routes';
import auth from '../../../utils/auth';
import { AuthContext } from '../../../providers/AuthProvider';
import Divider from '../../elements/Divider';
import NavLink from '../NavLink';

import CloseIcon from '../../../assets/icons/icon-close.svg';
import NavLogo from '../NavLogo';
import SocialMediaLinks from '../SocialMediaLinks';
import './NavMobileMenu.scss';

interface INavMobileMenuProps {
  isVisible: boolean;
  onClose: () => void;
}

const NavMobileMenu = (props: INavMobileMenuProps) => {
  const { onClose, isVisible } = props;
  const authContext = useContext(AuthContext);

  const logOut = () => {
    auth.removeAuthTokens();
    authContext.removeUser();

    onClose();
    window.location.pathname = ROUTES.HOME;
  };

  const navLinks = [NAV_LINKS.explore, NAV_LINKS.activities];

  const links = userLinks(authContext);

  if (!isVisible) {
    return null;
  }

  return (
    <nav className="vx-nav-mobile-menu">
      <div className="vx-nav-mobile-menu__logo-wrapper">
        <NavLogo />
        <CloseIcon
          className="vx-nav__menu vx-nav__close"
          height="20px"
          width="20px"
          onClick={onClose}
        />
      </div>
      <ul className="vx-nav-mobile-menu__links-container with-corners">
        {navLinks.map(link => (
          <NavLink link={link} key={link.href} onLinkClick={onClose} />
        ))}
        <Divider className="vx-nav-mobile-menu__divider" />
        {links.length > 0 &&
          links.map(link => <NavLink link={link} key={link.href} />)}
      </ul>
      <SocialMediaLinks className="vx-nav-mobile-menu__social-links" />
      <div className="vx-nav-mobile-menu__login with-corners">
        {authContext.isLoggedIn() && [
          <NavLink
            link={{
              href: '/profile',
              title: 'My Profile',
            }}
            key="profile"
            onLinkClick={onClose}
          />,
          <NavLink
            link={{ href: '', title: 'Log out' }}
            onClick={logOut}
            onLinkClick={logOut}
            key="logout"
          />,
        ]}
        {!authContext.isLoggedIn() && (
          <>
            <NavLink
              link={{ href: '/login', title: 'Login' }}
              onLinkClick={onClose}
            />
            <NavLink
              link={{ href: '/register', title: 'Register' }}
              onLinkClick={onClose}
            />
          </>
        )}
      </div>
    </nav>
  );
};

export default NavMobileMenu;
