import classNames from 'classnames';
import * as React from 'react';

import ImageLabel from './ImageLabel';

// Styles
import './ImageHeader.scss';

interface IProps {
  alt?: string;
  className?: string;
  disableHover?: boolean;
  imgLabel?: string;
  imgSrc: string;
  isTitleVisible?: boolean;
  onClick?: any;
  onDeleteClick?: any;
  roundedCorners?: boolean;
  style?: any;
  subTitle?: string;
  title?: string;
}

const ImageHeader = ({
  alt,
  className,
  imgLabel,
  imgSrc,
  onClick,
  roundedCorners,
  style,
  title,
  subTitle,
}: IProps) => {
  const classes = classNames(
    roundedCorners
      ? 'vx-card__image-container--rounded'
      : 'vx-card__image-container--rectangle',
    className,
  );

  return (
    <div
      className={classes}
      style={style}
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyPress={onClick}
    >
      <img src={imgSrc} className="vx-card__logo" alt={alt} />
      {imgLabel ? <ImageLabel text={imgLabel} /> : null}
      {title && <h1 className="vx-card__title">{title}</h1>}
      {subTitle && <h1 className="vx-card__subTitle">{subTitle}</h1>}
    </div>
  );
};

export default ImageHeader;
