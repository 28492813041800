import React from 'react';

import { IProfileModalProps } from './types';

import Profile from '../../../../pages/profile';
import Modal from '../../../elements/Modal';

import './ProfileModal.scss';

const ProfileModal = (props: IProfileModalProps) => {
  const { isOpen, onClose } = props;

  return (
    <Modal isOpen={isOpen} onClose={onClose} className="vx-modal-profile">
      <Profile isModal onClose={onClose} />
    </Modal>
  );
};

export default ProfileModal;
