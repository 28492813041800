import React, { useState } from 'react';
import MenuIcon from '../../assets/icons/icon-menu.svg';
import { useDevice } from '../../hooks/use-device';
import NavLaptopMenu from './NavLaptopMenu';
import NavMobileMenu from './NavMobileMenu';

import './bundle.scss';

interface INavbarProps {
  onAvatarClick?: () => void;
}

const Navbar = (props: INavbarProps) => {
  const { onAvatarClick } = props;

  const device = useDevice();
  const [isVisible, setIsVisible] = useState(device.isLaptop);

  return (
    <header>
      {device.isLaptop && isVisible ? (
        <NavLaptopMenu
          onClose={() => setIsVisible(false)}
          onAvatarClick={onAvatarClick}
          isVisible={isVisible}
        />
      ) : (
        <NavMobileMenu
          onClose={() => setIsVisible(false)}
          isVisible={isVisible}
        />
      )}
      {!isVisible && (
        <MenuIcon className="vx-nav__menu" onClick={() => setIsVisible(true)} />
      )}
    </header>
  );
};

export default Navbar;
