import { css } from 'emotion';

const modalOverrides = css`
  &.vx-react-modal {
    padding: 0;
  }
`;

export const baseStyles = {
  modalOverrides,
};
