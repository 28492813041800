import { useEffect, useState } from 'react';
import { IDevice } from '../interfaces/devices';

export function useDevice(): IDevice {
  const [width, setWidth] = useState(window.innerWidth);
  const device: IDevice = {
    isDesktop: false,
    isLaptop: false,
    isPhone: false,
    isTablet: false,
  };

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  if (width < 480) {
    device.isPhone = true;
  } else if (width <= 1024) {
    device.isTablet = true;
  } else {
    device.isLaptop = true;
    device.isDesktop = true;
  }

  return device;
}
