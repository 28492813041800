import * as React from 'react';

import { cx } from 'emotion';
import { IBackgroundLabelProps } from './types';

import { label } from './styles';

const BackgroundLabel = ({
  title,
  size = 'md',
  color = 'purple',
  className,
  onClick,
}: IBackgroundLabelProps) => {
  const classes = cx(label(color, size), className);

  return (
    <span className={classes} onClick={onClick}>
      {title}
    </span>
  );
};

export default BackgroundLabel;
