import React, { ReactElement, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import CaretRight from '../../../../assets/icons/icon-caret-right.svg';

interface ISettingsDropdownProps {
  children: any;
  title: string;
}

const SettingsDropdown = ({
  title,
  children,
}: ISettingsDropdownProps): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => setIsOpen(!isOpen);
  const animatedStyle = useSpring({
    config: {
      clamp: true,
      duration: 50,
    },
    transform: isOpen ? 'scale3d(1, 1, 1)' : 'scale3d(1, 0, 1)',
  });

  return (
    <div className="vx-settings-dropdown">
      <div
        className="vx-row"
        style={{ marginBottom: '1rem' }}
        onClick={handleClick}
      >
        <CaretRight
          style={{
            fill: 'red',
            transform: isOpen ? 'rotate(90deg)' : 'rotate(0)',
          }}
        />
        <span>{title}</span>
      </div>
      {isOpen && (
        <animated.div
          className="vx-settings-dropdown__options"
          style={animatedStyle}
        >
          {children}
        </animated.div>
      )}
      <hr className="horizontal-line" />
    </div>
  );
};

export default SettingsDropdown;
