export default {
  type: "Feature",
  properties: {},
  geometry: {
    type: "LineString",
    coordinates: [
      [8.247355, 61.548545, 1395, 1417.1000000000004],
      [8.245565, 61.545371, 1395, 1416.6000000000004],
      [8.245695, 61.543231, 1414.800000000001, 1414.800000000001],
      [8.247672, 61.540898, 1421, 1404.1000000000004],
      [8.244776, 61.538165, 1404.1000000000004, 1431.2000000000007],
      [8.240447, 61.535992, 1416.6000000000004, 1421],
      [8.237367, 61.53343, 1417.1000000000004, 1395],
      [8.236105, 61.531409, 1431.2000000000007, 1395],
      [8.231992, 61.531259, null, 1395],
      [8.209205, 61.52715, null, 1395],
      [8.202441, 61.527302, null, 1413.6000000000004],
      [8.194732, 61.528475, 1395, 1412.300000000001],
      [8.18842, 61.530437, 1395, 1395],
      [8.187636, 61.534061, 1413.6000000000004, 1395],
      [8.184564, 61.536153, 1412.300000000001, null],
      [8.179499, 61.536573, 1395, null],
      [8.175834, 61.538307, 1395, null],
      [8.174031, 61.539059, 1387, 1387],
      [8.170371, 61.539303, 1472.300000000001, 1472.300000000001],
      [8.167173, 61.538256, 1628.300000000001, 1532],
      [8.166417, 61.536457, 1627.800000000001, 1652],
      [8.166753, 61.534065, 1532, 1623],
      [8.166535, 61.531713, 1652, 1620.2000000000007],
      [8.167603, 61.530404, 1610.9000000000015, 1627.800000000001],
      [8.166654, 61.531738, 1623, 1628.300000000001],
      [8.166695, 61.534044, 1620.2000000000007, 1610.9000000000015],
      [8.166333, 61.536526, 1673, 1673],
      [8.167186, 61.538331, 1675.5, 1675.5],
      [8.17037, 61.539262, 1735.9000000000015, 1739.1000000000004],
      [8.174058, 61.539125, 1739.1000000000004, 1735.9000000000015],
      [8.175722, 61.538311, 1865.300000000001, 1797.6000000000004],
      [8.179353, 61.536641, 1867.6000000000004, 1800.300000000001],
      [8.184519, 61.536218, 1797.6000000000004, 1867.6000000000004],
      [8.187632, 61.534183, 1800.300000000001, 1865.300000000001],
      [8.189114, 61.532906, 1535.5, 1994.2000000000007],
      [8.196575, 61.529286, 1653.7000000000007, 1931.300000000001],
      [8.202329, 61.527436, 1504.9000000000015, 1608],
      [8.209157, 61.527256, 1994.2000000000007, 1931.300000000001],
      [8.232105, 61.53134, 1931.300000000001, 1653.7000000000007],
      [8.235999, 61.531507, 1608, 1535.5],
      [8.237359, 61.533481, 1419.300000000001, null],
      [8.240441, 61.536064, 1432.6000000000004, null],
      [8.244791, 61.538271, null, 1419.300000000001],
      [8.247614, 61.540957, 1414.300000000001, 1504.9000000000015],
      [8.245653, 61.543276, 1931.300000000001, 1432.6000000000004],
      [8.245592, 61.545465, null, 1404.300000000001],
      [8.247262, 61.54857, 1404.300000000001, 1414.300000000001]
    ]
  }
};
