import classNames from 'classnames';
import * as React from 'react';

import './Button.scss';
import { IButtonProps } from './types';

const Button = ({
  active,
  children,
  className,
  color = 'primary',
  disabled,
  hover,
  name,
  onClick,
  size = 'md',
  style,
  type,
  value,
}: IButtonProps) => {
  const styles = {
    ...style,
  };

  /* eslint-disable max-len */
  const classes = classNames(
    'vx-btn',
    `vx-btn-${color}`,
    size ? `vx-btn-${size}` : 'vx-btn-md',
    className,
    {
      ['vx-btn--active']: active,
      ['vx-btn--disabled']: disabled,
      ['vx-btn--hover']: hover,
    },
  );

  return (
    /* eslint-disable react/button-has-type */
    <button
      onMouseEnter={() => (hover = true)}
      onMouseLeave={() => (hover = false)}
      className={classes}
      style={styles}
      name={name}
      onClick={onClick}
      type={type}
      value={value}
    >
      {children && <span>{children}</span>}
    </button>
  );
};

export default Button;
