export default {
  type: "Feature",
  properties: {},
  geometry: {
    type: "LineString",
    coordinates: [
      [8.247512, 61.548471, 1403.9000000000015],
      [8.248603, 61.548337, 1403.800000000001],
      [8.250743, 61.547334, 1466.800000000001],
      [8.266136, 61.543943, 1466.7000000000007],
      [8.273823, 61.543276, 1402.5],
      [8.27623, 61.542651, 1496],
      [8.282437, 61.538971, 1401],
      [8.284043, 61.537419, 1401],
      [8.285452, 61.536976, 1401],
      [8.290359, 61.536796, 1630.7000000000007],
      [8.290906, 61.538025, 1630.7000000000007],
      [8.290759, 61.538566, 1689.300000000001],
      [8.291635, 61.539715, 1688.7000000000007],
      [8.291467, 61.540335, 1753.1000000000004],
      [8.291998, 61.540974, 1753.1000000000004],
      [8.291927, 61.541299, 1659.800000000001],
      [8.292002, 61.541537, 1806.9000000000015],
      [8.291912, 61.541312, 1806.9000000000015],
      [8.292006, 61.540979, 1847],
      [8.291457, 61.540344, 1847],
      [8.291625, 61.539717, 1907.6000000000004],
      [8.290735, 61.538562, 2019.4000000000015],
      [8.290911, 61.538031, 2019.9000000000015],
      [8.290361, 61.536807, 1952.6000000000004],
      [8.28544, 61.536982, 1951.6000000000004],
      [8.284026, 61.537426, 1658.7000000000007],
      [8.282442, 61.538972, 1402.7000000000007],
      [8.276286, 61.542674, 2027.7000000000007],
      [8.273853, 61.543311, 1987.5],
      [8.266256, 61.543955, 1987.5],
      [8.250813, 61.547343, 1910.4000000000015],
      [8.248549, 61.548401, 1496.4000000000015],
      [8.247487, 61.548489, 1401]
    ]
  }
};
