import { css } from 'emotion';
import { formInlineContainer, labelStyles } from '../styles';

const container = css`
  ${formInlineContainer}
  justify-content: space-between;
`;

const label = css`
  ${labelStyles}
  padding-left: 16px;
`;

export const baseStyles = {
  container,
  labelStyles: label,
};
