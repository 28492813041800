import * as React from 'react';
import { useRef } from 'react';
import { animated, config, useChain, useTransition } from 'react-spring';
import ExploreCard from '../../../../Cards/ExploreCard';

import { arrayShiftElement } from '../../../../../../utils/array-helpers';

import './ExploreSidebar.scss';
import { IExploreSidebarProps } from './types';

export default ({
  show,
  activities,
  selectedActivity,
}: IExploreSidebarProps) => {
  let orderedActivities =
    activities && activities.length > 0 ? activities : undefined;
  const sidebarRef = useRef();
  const transition = useTransition(show, null, {
    config: config.stiff,
    enter: {
      transform: 'translateX(0)',
    },
    from: {
      transform: 'translateX(-100%)',
    },
    leave: {
      transform: 'translateY(-100%)',
    },
    ref: sidebarRef,
    unique: true,
  });

  useChain(show ? [sidebarRef] : [sidebarRef], show ? [0] : [0]);

  if (orderedActivities && selectedActivity) {
    const index = orderedActivities.indexOf(selectedActivity);

    orderedActivities = arrayShiftElement(orderedActivities, index, 0);

    const activitiesDiv = document.querySelector(
      '.vx-explore-sidebar__activities',
    );

    if (activitiesDiv) {
      activitiesDiv.scrollTop = 0;
    }
  }

  return (
    <>
      {transition.map(({ item, key, props }) =>
        item ? (
          <animated.div key={key} style={props} className="vx-explore-sidebar">
            <div style={{ height: '25rem' }} />
            <div className="vx-explore-sidebar__activities">
              {orderedActivities ? (
                orderedActivities.map(activity => (
                  <div
                    key={activity.id}
                    className="vx-explore-sidebar__activity"
                  >
                    <ExploreCard
                      isSelected={activity.title === selectedActivity?.title}
                      isExpandable
                      route={activity}
                    />
                  </div>
                ))
              ) : (
                <div className="center tc">
                  <h3>No trips in view</h3>
                  <p>Move around map, or search</p>
                </div>
              )}
            </div>
          </animated.div>
        ) : null,
      )}
    </>
  );
};
