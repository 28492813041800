import { MAPBOX_TOKEN } from '../../../constants';
import { IStory } from './types';

export const Jonndalen: IStory = {
  accessToken: MAPBOX_TOKEN,
  alignment: 'left',
  byline: 'By Vertrax',
  chapters: [
    {
      description: `
Just beyong the Uvdal Skisenter, head deep into the valley to begin your adventures.

Jønndalen offers many great skiing, hiking, and cross-country opportinities.
      `,
      id: 'other-identifier',
      image: '/assets/img/leirvassbu.jpeg',
      location: {
        bearing: -40.0,
        center: [8.24806, 61.54856],
        pitch: 45.0,
        zoom: 16.0,
      },
      onChapterEnter: [],
      onChapterExit: [],
      title: 'Jønndalen',
    },
    {
      description:
        'From Leirvassbu you can climb at least 40 peaks over 2000 metres in the immediate area.',
      id: 'slug-style-id',
      image: '/assets/img/leirvassbu.jpeg',
      location: {
        bearing: -40.0,
        center: [8.24806, 61.54856],
        pitch: 45.0,
        zoom: 12.3,
      },
      onChapterEnter: [
        {
          layer: 'kyrkja',
          opacity: 1,
        },
        {
          layer: 'semelholstiden',
          opacity: 1,
        },
        {
          layer: 'midtreHogvalgtiden',
          opacity: 1,
        },
        {
          layer: 'stetind',
          opacity: 1,
        },
        {
          layer: 'surtningstiden',
          opacity: 1,
        },
        {
          layer: 'storeBjorn',
          opacity: 1,
        },
      ],
      onChapterExit: [
        {
          layer: 'kyrkja',
          opacity: 0,
        },
        {
          layer: 'semelholstiden',
          opacity: 0,
        },
        {
          layer: 'midtreHogvalgtiden',
          opacity: 0,
        },
        {
          layer: 'stetind',
          opacity: 0,
        },
        {
          layer: 'surtningstiden',
          opacity: 0,
        },
        {
          layer: 'storeBjorn',
          opacity: 0,
        },
      ],
      title: 'Ski touring',
    },
  ],
  footer: 'Source: source citations, etc.',
  showMarkers: false,
  style: 'mapbox://styles/vertrax/ck433h4vb1hfq1do08ybntxza',
  subtitle: 'Exploring the unknown, in our own backyard',
  theme: 'dark',
  title: 'Jønndalen',
};
