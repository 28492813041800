import React from 'react';
import ReactModal from 'react-modal';
import { css, cx } from 'emotion';

import CloseIcon from '../../../assets/icons/icon-close.svg';
import { IModalProps } from './types';
import { ZIndex } from '../../../styles/theme/v2/variables';
import { EColors } from '../../../styles/theme/v2/colors';

const Modal = (props: IModalProps) => {
  const {
    children,
    isOpen,
    isFullScreen,
    onClose,
    className,
    onAfterOpen,
    shouldCloseOnOverlayClick = true,
    overlayClassName,
  } = props;

  const classes = cx(
    css`
      position: relative; // This is for the close icon to be properly placed
      margin: 12rem auto;
      background-color: ${EColors.offBlack};
      padding: 2rem;
      width: 40rem;
      height: 70rem;
      overflow-y: scroll;
      border-radius: 8px;

      .Modal__Body--open {
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
      }
    `,
    className,
    {
      [css`
        margin: 0;
        width: 100%;
      `]: isFullScreen,
    },
  );

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={onClose}
      className={classes}
      onAfterOpen={onAfterOpen}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      overlayClassName={cx(
        css`
          background-color: rgba(255, 255, 255, 0.75);
          position: fixed;
          height: 100vh;
          top: 0;
          left: 0;
          right: 0;
          bottom: auto;
          z-index: ${ZIndex.modal};
        `,
        {
          [css`
            z-index: ${ZIndex.modal} + 5;
          `]: overlayClassName === 'stacked-1',
          [css`
            z-index: ${ZIndex.modal} + 10;
          `]: overlayClassName === 'stacked-2',
          [css`
            background-color: none;

            background-image: linear-gradient(
                to bottom,
                rgba(217, 217, 217, 0.5),
                rgba(0, 0, 0, 0.5)
              ),
              linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));

            -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
          `]: overlayClassName === 'frost',
        },
      )}
    >
      <CloseIcon
        className={css`
          color: white;
          height: 2.4rem;
          width: 2.4rem;
          margin: 1.6rem;
          cursor: pointer;
          position: absolute;
          right: 0;
          background-color: rgba(0, 0, 0, 0.3);
          z-index: 5;
        `}
        onClick={onClose}
      />
      {children}
    </ReactModal>
  );
};

export default Modal;
