function validateEmail(email) {
  /* eslint-disable no-useless-escape */
  /* eslint-disable max-len */
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(email);
}

function validatePassword(password, confimationPassword) {
  if (password.length < 6) {
    throw new Error('Password length too short');
  }

  if (confimationPassword && password !== confimationPassword) {
    throw new Error('Passwords do not match');
  }

  return true;
}

export default {
  validateEmail,
  validatePassword,
};
