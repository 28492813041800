import * as React from 'react';

import Profile from '../../../assets/images/profile.png';
import Divider from '../../elements/Divider';

interface IProps {
  imgSrc?: string;
}

const NavAvatar = ({ imgSrc }: IProps) => (
  <div className="vx-nav__profile-container">
    <Divider />
    <img
      className="vx-nav__profile-img"
      src={imgSrc ? imgSrc : Profile}
      alt="avatar"
    />
    <Divider />
  </div>
);

export default NavAvatar;
