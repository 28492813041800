export default {
  type: "Feature",
  properties: {},
  geometry: {
    type: "LineString",
    coordinates: [
      [8.247387, 61.54856, 1401.7000000000007],
      [8.246469, 61.549654, 1320.300000000001],
      [8.24204, 61.551146, 1320.300000000001],
      [8.238864, 61.552218, 1404.2000000000007],
      [8.218892, 61.556965, null],
      [8.210701, 61.559624, null],
      [8.205195, 61.561962, null],
      [8.199971, 61.563515, 1408.1000000000004],
      [8.191712, 61.564544, 1408],
      [8.182759, 61.564843, null],
      [8.177969, 61.56413, null],
      [8.175233, 61.563806, 1390.6000000000004],
      [8.173151, 61.562643, 1390.9000000000015],
      [8.169283, 61.560827, 1310.4000000000015],
      [8.162903, 61.559137, 1310.4000000000015],
      [8.154649, 61.558359, 1442.5],
      [8.149465, 61.558552, 1468],
      [8.143802, 61.557978, 1466.300000000001],
      [8.139355, 61.55718, 1403.9000000000015],
      [8.134809, 61.554616, 1404],
      [8.134669, 61.552083, 1493.6000000000004],
      [8.135969, 61.551295, 1493.1000000000004],
      [8.136583, 61.550502, 1545.1000000000004],
      [8.136496, 61.550142, 1546.6000000000004],
      [8.137651, 61.54854, 1630.9000000000015],
      [8.137243, 61.548091, 1631.2000000000007],
      [8.137577, 61.548543, 1698.300000000001],
      [8.136469, 61.550178, 1793.6000000000004],
      [8.13668, 61.550489, 1794],
      [8.136004, 61.551328, 1855.4000000000015],
      [8.134735, 61.552177, 1855],
      [8.13471, 61.554649, 1737],
      [8.1393, 61.557158, 1737],
      [8.143749, 61.557987, 2039.7000000000007],
      [8.149487, 61.558548, 2196.300000000001],
      [8.154563, 61.558374, 2194.9000000000015],
      [8.162863, 61.55914, 1961.300000000001],
      [8.169228, 61.560823, 1961.800000000001],
      [8.173187, 61.562684, 2213.300000000001],
      [8.175333, 61.563838, 2115.300000000001],
      [8.177902, 61.564145, 2115.6000000000004],
      [8.182806, 61.564876, null],
      [8.191711, 61.564549, 2033.4000000000015],
      [8.200013, 61.563529, 1404],
      [8.205257, 61.561945, 2134.9000000000015],
      [8.21071, 61.559651, 2135.9000000000015],
      [8.21887, 61.557025, 1402],
      [8.238804, 61.552231, 1443.5],
      [8.242031, 61.551173, 2076.6000000000004],
      [8.24657, 61.549633, 2075.2000000000007],
      [8.247417, 61.548512, 1700.1000000000004]
    ]
  }
};
