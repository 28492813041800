import { css } from 'emotion';
import { formInlineContainer, labelStyles } from '../styles';

const selectStyles = css`
  color: white;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 95%;
  outline: none;
  border: none !important;
  background: transparent !important;
  font-family: 'Zilla Slab', serif;
  font-size: 1.6rem;
  height: fit-content;
`;

export default {
  container: formInlineContainer,
  labelStyles,
  selectStyles,
};
