export default {
  type: "Feature",
  properties: {},
  geometry: {
    type: "LineString",
    coordinates: [
      [8.247386, 61.548476, 1403.800000000001],
      [8.251405, 61.542147, 1403.9000000000015],
      [8.242969, 61.537428, 1395],
      [8.237608, 61.533866, 1415.9000000000015],
      [8.236315, 61.531441, 1395],
      [8.230584, 61.531187, 1414.6000000000004],
      [8.224002, 61.53397, 1413.300000000001],
      [8.223401, 61.534946, 1487.9000000000015],
      [8.22071, 61.535561, 1480.5],
      [8.214658, 61.536161, 1474.4000000000015],
      [8.212659, 61.53668, null],
      [8.210005, 61.540961, null],
      [8.207384, 61.542774, null],
      [8.204476, 61.542985, null],
      [8.197664, 61.542859, null],
      [8.196886, 61.543207, null],
      [8.196853, 61.543681, null],
      [8.196652, 61.544404, null],
      [8.196432, 61.545212, null],
      [8.196717, 61.545918, null],
      [8.196638, 61.546607, null],
      [8.195853, 61.547176, null],
      [8.195377, 61.547684, null],
      [8.195898, 61.547196, null],
      [8.196744, 61.546583, null],
      [8.196688, 61.545891, null],
      [8.196382, 61.545251, null],
      [8.196651, 61.544377, null],
      [8.196867, 61.543723, null],
      [8.196821, 61.543267, null],
      [8.196032, 61.542428, null],
      [8.194657, 61.5419, 1905.9000000000015],
      [8.193152, 61.541661, 1884.9000000000015],
      [8.191465, 61.541428, 1862.9000000000015],
      [8.188851, 61.545493, 2015.2000000000007],
      [8.186035, 61.548839, 1725],
      [8.187654, 61.553787, 1545.300000000001],
      [8.191426, 61.555995, 1500.2000000000007],
      [8.199209, 61.556454, null],
      [8.212054, 61.555812, null],
      [8.227726, 61.554522, 1517.7000000000007],
      [8.239797, 61.551929, 1407.800000000001],
      [8.246186, 61.549755, 1356.1000000000004],
      [8.247357, 61.548494, 1396.7000000000007]
    ]
  }
};
