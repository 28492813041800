import { css } from 'emotion';

export const formInlineContainer = css`
  display: inline-flex;
  align-items: center;
  padding-right: 16px;
  background-color: rgba(255, 255, 255, 0.02);
  border-radius: 4px;
  border: solid 0.6px rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.08);
  font-size: 1.6rem;
  font-weight: 200;
  height: 56px;
  width: 100%;
`;

export const labelStyles = css`
  display: inline;
  white-space: nowrap;
  font-family: 'Zilla Slab', serif;
  font-size: 1.2rem;
  color: white;
  opacity: 0.4;
`;

export const baseStyles = {
  formInlineContainer,
  labelStyles,
};
