/* eslint-disable @typescript-eslint/camelcase */
// COLORS REPOSITORY
// ====================================================

import { css } from 'emotion';

export const EColors = {
  black: '#000000',
  blue_100: '#c3d0de',
  blue_300: '#8cc1ff',
  blue_500: '#1739c6',
  blue_700: '#38358f',
  colorcyan: '#00ffce',
  darkGrey_A01: 'rgba(0, 0, 0, 0.1)',
  darkGrey_A02: 'rgba(0, 0, 0, 0.2)',
  darkGrey_A03: 'rgba(0, 0, 0, 0.3)',
  darkGrey_A04: 'rgba(0, 0, 0, 0.4)',
  darkGrey_A05: 'rgba(0, 0, 0, 0.5)',
  darkGrey_A06: 'rgba(0, 0, 0, 0.6)',
  darkGrey_A07: 'rgba(0, 0, 0, 0.7)',
  darkGrey_A08: 'rgba(0, 0, 0, 0.8)',
  darkGrey_A09: 'rgba(0, 0, 0, 0.9)',
  darkPurple: '#25243a',
  green_success: '#8ed493',
  grey_100: '#f7f7f7',
  grey_200: '#ececec',
  grey_300: '#d8d8d8',
  grey_500: '#9b9b9b',
  grey_900: '#4a4a4a',
  grey_A001: 'rgba(255, 255, 255, 0.01)',
  grey_A002: 'rgba(255, 255, 255, 0.02)',
  grey_A003: 'rgba(255, 255, 255, 0.03)',
  grey_A004: 'rgba(255, 255, 255, 0.04)',
  grey_A005: 'rgba(255, 255, 255, 0.05)',
  grey_A006: 'rgba(255, 255, 255, 0.06)',
  grey_A007: 'rgba(255, 255, 255, 0.07)',
  grey_A008: 'rgba(255, 255, 255, 0.08)',
  grey_A009: 'rgba(255, 255, 255, 0.09)',
  grey_A01: 'rgba(255, 255, 255, 0.1)',
  grey_A02: 'rgba(255, 255, 255, 0.2)',
  grey_A03: 'rgba(255, 255, 255, 0.3)',
  grey_A04: 'rgba(255, 255, 255, 0.4)',
  grey_A05: 'rgba(255, 255, 255, 0.5)',
  grey_A06: 'rgba(255, 255, 255, 0.6)',
  grey_A07: 'rgba(255, 255, 255, 0.7)',
  grey_A08: 'rgba(255, 255, 255, 0.8)',
  grey_A09: 'rgba(255, 255, 255, 0.9)',
  offBlack: '#25243a',
  orange: '#ffbe88',
  primaryGradient: 'linearGradient(to top right, "#38dfb8, #33afc4)"',
  primaryGradient_50: `linearGradient
    to top right,
    'rgba('#38dfb8, 0.5),'',
    'rgba('#33afc4, 0.5)''

  );`,
  purpleGradient: 'linearGradient(to top, "#3a293d, #232337)"',
  red_danger: '#ff6461',
  red_warning: '#ffb858',
  white: '#ffffff',
};

export const textMuted = css`
  color: ${EColors.grey_500};
`;

export type EColorKey = keyof typeof EColors;
