import React, { Fragment } from 'react';
import StatCard from '../../../../components/modules/Cards/StatCard';
import { IStatsProps } from './types';

const Stats = (props: IStatsProps) => {
  const { stats } = props;

  return (
    <Fragment>
      <div className="vx-profile__title">All Time Stats</div>
      <div className="vx-profile__stats">
        <div className="vx-row">
          <StatCard
            title="Total Ascent"
            statValue={stats.allTimeStats.ascent}
            statUnits="m"
            className="vx-column"
          />
          <StatCard
            title="Total Descent"
            statValue={stats.allTimeStats.descent}
            statUnits="m"
            className="vx-column"
          />
        </div>
        <div className="vx-row">
          <StatCard
            title="Total Distance"
            statValue={stats.allTimeStats.distance}
            statUnits="m"
            className="vx-column"
          />
          <StatCard
            title="Number of Trips"
            statValue={stats.allTimeStats.numberOfTrips}
            className="vx-column"
          />
        </div>
      </div>

      <div className="vx-profile__title">Trip Stats</div>
      <div className="vx-profile__stats">
        <div className="vx-row">
          <StatCard
            title="Most Ascent"
            statValue={stats.tripStats.mostAscent}
            statUnits="m"
            className="vx-column"
          />
          <StatCard
            title="Most Descent"
            statValue={stats.tripStats.mostDescent}
            statUnits="m"
            className="vx-column"
          />
        </div>
        <StatCard
          title="Longest Distance"
          statValue={stats.tripStats.longestDistance}
          statUnits="km"
          className="vx-column"
        />
        <StatCard
          title="Longest Duration"
          statValue={'?'}
          statUnits="hrs"
          className="vx-column"
        />
      </div>
    </Fragment>
  );
};

export default Stats;
