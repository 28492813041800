import React, { useState } from 'react';
import { css, cx } from 'emotion';

import { IParagraphProps } from './types';

import { FontSizes } from '../../../styles/theme/v2/typography';
import { Shadows } from '../../../styles/theme/v2/variables';

export const Paragraph = (props: IParagraphProps) => {
  const { className, content, contentLength } = props;
  const [showMore, setShowMore] = useState(false);

  const handleClick = e => {
    e.preventDefault();
    setShowMore(!showMore);
  };

  const defaultClasses = cx(
    css`
      white-space: pre-wrap;
    `,
    className,
  );

  const showMoreLess = css`
    display: block;
    font-size: ${FontSizes.pt16};
    text-decoration: underline;
    margin-top: 1rem;
    cursor: pointer;
    &:hover {
      text-shadow: ${Shadows.boldHover};
    }
  `;
  if (content.length > contentLength) {
    return (
      <div className={defaultClasses}>
        <p
          className={css`
            display: inline;
          `}
        >
          {content.slice(0, showMore ? undefined : contentLength)}
        </p>
        <span
          className={showMoreLess}
          tabIndex={0}
          onClick={handleClick}
          onKeyDown={handleClick}
          role="button"
        >
          {showMore ? 'Show less' : 'Show More'}
        </span>
      </div>
    );
  }

  return (
    <div className={defaultClasses}>
      <p>{content}</p>
    </div>
  );
};
