import React from 'react';
import { NAV_LINKS } from '../../../constants/routes';
import NavLink from '../NavLink';

const NavAuthButtons = () => {
  return (
    <div className="flex">
      <NavLink link={NAV_LINKS.signin} />
      <NavLink link={NAV_LINKS.register} />
    </div>
  );
};

export default NavAuthButtons;
