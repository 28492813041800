import classNames from 'classnames';
import * as React from 'react';
import { Fragment } from 'react';
import { IFormInputProps } from './types';
import './FormInput.scss';

const FormInput = ({
  disabled = false,
  errors = [],
  inputClassName = '',
  labelClassName,
  labelText,
  name,
  onChange,
  placeholder,
  required,
  type = 'text',
  value = '',
  autoComplete = 'true',
}: IFormInputProps) => {
  const labelClasses = classNames('vx-form__label', labelClassName);
  const inputClasses = classNames('vx-form__input', inputClassName, {
    'vx-form__input--no-label': !labelText,
  });

  return (
    <Fragment>
      {labelText && (
        <label htmlFor={name} className={labelClasses}>
          {labelText}
        </label>
      )}
      <input
        id={name}
        disabled={disabled}
        className={inputClasses}
        required={required}
        name={name}
        placeholder={placeholder}
        type={type}
        value={value}
        onChange={onChange}
        autoComplete={autoComplete}
      />
      {errors && errors[name] && (
        <span className="vx-form__label--error">{errors[name]}</span>
      )}
    </Fragment>
  );
};

export default FormInput;
