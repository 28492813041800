import { css } from 'emotion';

const container = css`
  display: inline-flex;
  align-items: center;
  padding-right: 16px;
  background-color: rgba(255, 255, 255, 0.02);
  border-radius: 4px;
  border: solid 0.6px rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.08);
  font-size: 1.6rem;
  font-weight: 200;
  height: 56px;
  width: 100%;
`;

const inputStyles = css`
  color: white;
  width: 95%;
  outline: none;
  border: none !important;
  background: transparent !important;
  line-height: 1.2rem;
  font-family: 'Zilla Slab', serif;
  font-size: 1.6rem;
  height: 3.4rem;
`;

const labelStyles = css`
  display: inline;
  white-space: nowrap;
  font-family: 'Zilla Slab', serif;
  font-size: 1.2rem;
  color: white;
  opacity: 0.4;
`;

export default {
  container,
  inputStyles,
  labelStyles,
};
