import React, { Fragment } from 'react';

import AuthForm from '../../../components/modules/Forms/AuthForm';
import { ROUTES } from '../../../constants/routes';
import { SignupComponent } from '../../../generated/models';
import auth from '../../../utils/auth';
import { ISignupProps } from './types';

import stylesheet from './signup.scss';

const SignUp = (props: ISignupProps) => {
  const { history } = props;

  return (
    <Fragment>
      <style dangerouslySetInnerHTML={{ __html: stylesheet }} />
      <SignupComponent
        onCompleted={data => {
          auth.saveAuthTokens(
            data.signup.accessToken,
            data.signup.refreshToken,
          );
          window.location.reload();

          return history.push(ROUTES.PROFILE);
        }}
      >
        {signup => (
          <div className="vx-signup">
            <AuthForm
              isRegister
              onSave={async authData => {
                await signup({
                  variables: {
                    email: authData.email,
                    name: authData.name,
                    password: authData.password,
                  },
                });
              }}
            />
          </div>
        )}
      </SignupComponent>
    </Fragment>
  );
};

export default SignUp;
