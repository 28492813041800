import { Direction } from '../../generated/models';

export const ApproachOptions = [
  { label: '', value: '' },
  { label: 'North', value: Direction.North },
  { label: 'North East', value: Direction.NorthEast },
  { label: 'East', value: Direction.East },
  { label: 'South East', value: Direction.SouthEast },
  { label: 'South', value: Direction.South },
  { label: 'South West', value: Direction.SouthWest },
  { label: 'West', value: Direction.West },
  { label: 'North West', value: Direction.NorthWest },
];
