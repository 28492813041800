import '@babel/polyfill';
import 'dotenv/config';

import * as Sentry from '@sentry/browser';
import * as React from 'react';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from 'react-apollo-hooks';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import client from './lib/apolloClient';
import * as serviceWorker from './serviceWorker';

import App from './app';

import './styles/bundle.scss';
import { AuthProvider } from './providers/AuthProvider';

Sentry.init({
  dsn: 'https://741316c194bf441a8e4b3389661209d7@sentry.io/195700',
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <ApolloHooksProvider client={client}>
      <AuthProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AuthProvider>
    </ApolloHooksProvider>
  </ApolloProvider>,
  document.getElementById('app'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
