/* eslint-disable react/display-name */
import React from 'react';
import { baseStyles } from './styles';

export default ({
  name,
  labelText,
  required,
  className,
}: {
  className?: string;
  labelText: string;
  name: string;
  required?: boolean;
}) => {
  const { labelStyles } = baseStyles;

  return (
    <label className={`${labelStyles} ${className}`} htmlFor={name}>
      {required ? `${labelText} *` : labelText}
    </label>
  );
};
