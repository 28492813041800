import { find } from 'lodash';
import * as React from 'react';
import { useState } from 'react';

import ActivityCard from '../../../components/modules/Cards/ActivityCard';
import ErrorMessage from '../../../components/elements/ErrorMessage';
import FormSelect from '../../../components/elements/Form/FormSelect';
import LoadingIcon from '../../../components/elements/LoadingIcon';
import {
  ActivitiesIndexViewQueryVariables,
  ActivityType,
  useActivitiesIndexViewQuery,
} from '../../../generated/models';

import './index.scss';

const Activities = () => {
  const [typeFilter, setTypeFilter] = useState();
  const [isRefetch, setIsRefetch] = useState(false);

  const activityQuery = useActivitiesIndexViewQuery({
    variables: { first: 20 },
  });

  const filterOptions = Object.keys(ActivityType).map(key => ({
    label: key.replace(/([a-z])([A-Z])/g, '$1 $2'),
    value: key,
  }));

  const { data, loading, error, networkStatus, refetch } = activityQuery;

  if (loading && networkStatus !== 3) {
    return <LoadingIcon />;
  }

  if (isRefetch) {
    const variables: ActivitiesIndexViewQueryVariables = {
      first: 20,
    };

    if (typeFilter) {
      variables.filter = { activityType: [typeFilter] };
    } else {
      variables.filter = {};
    }

    refetch(variables).catch();

    setIsRefetch(false);
  }

  // Show Error View
  if (error) {
    return (
      <ErrorMessage message={`Error loading Activities: ${error.message}`} />
    );
  }

  const { activities } = data;

  return (
    <div className="vx-layout">
      <div className="vx-filter-item">
        <FormSelect
          name="activityTypeFilter"
          placeholder="Filter by type"
          options={filterOptions}
          value={
            typeFilter ? find(filterOptions, { value: typeFilter }) : undefined
          }
          onChange={e => {
            // @ts-ignore
            setTypeFilter(e && e.value);
            setIsRefetch(true);
          }}
          isClearable
        />
      </div>
      <div className="vx-row">
        {activities &&
          activities.map(activity => (
            <div className="vx-activities__card" key={activity.id}>
              <ActivityCard activity={activity} />
            </div>
          ))}

        {activities.length === 0 && (
          <h3 className="text-muted">No activities</h3>
        )}
      </div>
    </div>
  );
};

export default Activities;
